////////////////////////////////////////

import axios from "axios";

import base_module from '@/vmm-store/modules/base';
import vmm_store from '../vmm-store';

const base_service = vmm_store.service;

const options = {
    model_name    : 'invoice_consistency',
    default_order : 'id DESC',
};

const state = {
    original_filters : {},
    tot_imponibile   : 0,
    tot_imposta      : 0,
    tot_importo      : 0,
    cont_corretta    : 0,
    bot_status       : null,
    // export csv
    report_loader    : true,
    report_message   : { action: 'Esportazione in corso', variant: 'primary' },
};

const actions = {
    export_csv( { commit, state, getters } ) {
        console.log( 'store', 'export_csv' );
        let params   = JSON.parse( JSON.stringify( state.params ) );
        params.order = getters.order;
        delete params.paginate;
        let url = base_service.get_all( getters.backend_url, getters.model_name + '/export', params );
        console.log( url );
        axios.post( url, {} )
            .then( res => {
                let payload = res.data;
                payload.url = getters.backend_url + '/' + payload.url;
                commit( 'set_report_message', payload );
                commit( 'set_report_loader', false );
            })
            .catch( error => {
                commit( 'set_report_message', error.response.data );
                commit( 'set_report_loader', false );
            });
    },
    export_reset( { commit } ) {
        commit( 'set_report_message', { action: 'Esportazione in corso', variant: 'primary' } );
        commit( 'set_report_loader', true );
    },
    get_all( { commit, state, getters }, data = {} ) { // TODO new
        commit('start_request');
        var params   = JSON.parse( JSON.stringify( state.params ) );
        params.order = getters.order;
        if ( data.params ) {
            Object.keys( data.params ).map( key => {
                if ( ![ '', null, undefined ].includes( data.params[ key ] ) ) {
                    params[ key ] = data.params[ key ];
                } else {
                    delete params[ key ];
                }
            });
        }
        var new_params_string = JSON.stringify( params, Object.keys(params).sort() );
        var old_params_string = JSON.stringify( state.params, Object.keys(state.params).sort() );
        var force_reload = new_params_string !== old_params_string;
        if ( force_reload ) { commit( 'set_params', { params } ); }
        const url = base_service.get_all( getters.backend_url, getters.model_name, params );
        if ( state.map === null || force_reload || state.force_reload === true || data.force_reload === true ) {
            axios.get( url )
                .then( res => {
                    let response = res.data;
                    if ( params.paginate === true ) { // gestione paginator
                        commit('success_list', response.data);
                        commit('set_total', response.tot);
                        commit('set_page', response.page);
                        commit('set_rows_per_page', response.rows_per_page);
                        commit('set_tot_imponibile', response.tot_imponibile);
                        commit('set_tot_imposta', response.tot_imposta);
                        commit('set_tot_importo', response.tot_importo);
                        commit('set_cont_corretta', response.cont_corretta);
                        commit('set_bot_status', response.bot_status);
                    } else {
                        commit('success_list', response)
                    }
                })
                .catch( error => {
                    commit('error', error)
                });
        } else {
            commit('stop_request');
        }
    },
};

const mutations = {
    setfilters( state, filters ) { state.params = filters; },
    clear( state ) {
        state.obj              = {};
        state.items            = null;
        state.loading          = false;
        state.loading_by_id    = false;
        state.error            = false;
        state.executed         = false;
        state.force_reload     = false;
        state.selected         = false;
        state.filters          = {};
        state.params           = {};
        state.original_filters = {};
    },
    set_tot_imponibile( state, value ) { state.tot_imponibile = value },
    set_tot_imposta( state, value ) { state.tot_imposta = value },
    set_tot_importo( state, value ) { state.tot_importo = value },
    set_cont_corretta( state, value ) { state.cont_corretta = value },
    set_bot_status( state, value ) {
        if ( value === null ) {
            state.bot_status = null;
            return;
        }
        state.bot_status = {
            update_status   : value.config.update_status,
            last_bot_update : value.config.last_bot_update,
            denominazione   : value.config.denominazione,
        };
    },
    set_report_message( state, payload ) {
        state.report_message = payload;
    },
    set_report_loader( state, value ) {
        state.report_loader = value;
    },
};

const getters = {};

class new_module extends base_module {

    constructor ( new_options = {} ) {
        Object.keys( new_options ).map( x => {
            options[ x ] = new_options[ x ];
        });
        super( options );
    }

    static get_module( new_options = {} ) {
        var obj = new new_module( new_options );
        return {
            namespaced: true,
            state: {
                ...obj.state,
                ...state,
            },
            getters: {
                ...obj.getters,
                ...getters,
            },
            actions: {
                ...obj.actions,
                ...actions,
            },
            mutations: {
                ...obj.mutations,
                ...mutations,
            },
        }
    }

}

export default new_module;

