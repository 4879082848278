<script>

const moment = require('moment-timezone');

import listpage from '../listpage';
import { mapActions } from 'vuex';

export default {
    name: 'user-listpage',
    extends: listpage,
    components: {},
    props: [],
    computed: {
        params() { return this.store.params; },
    },
    watch: {
    },
    methods: {
        ...mapActions( 'user', { set_params_order: 'set_params', add_filter: 'add_filter', remove_filter: 'remove_filter', force_reload: 'force_reload' } ),
        toggle_modal(modal_id = 'modal-filter') {
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        clear_search_form( modal_event ) {
            if ( modal_event.trigger === 'clear' ) {
                Object.keys( this.filters ).map( x => {
                    this.remove_filter( x );
                    delete this.filters[ x ];
                });
                this.force_reload();
                this.list_key += 1;
                this.toggle_modal();
            }
        },
        operation_success( data ) {
            Object.keys( data ).map( x => {
                if ( data[ x ] === null || data[ x ] === '' ) {
                    this.remove_filter( x );
                    delete this.filters[ x ];
                } else {
                    if ( x === 'created' ) {
                        let from = moment( data[ x ][0] );
                        let to   = moment( data[ x ][1] );
                        this.add_filter( { [ x ]: `${ from.format('YYYY-MM-DDT00:00:00') }|${ to.format('YYYY-MM-DDT23:59:59') }` } );
                    } else {
                        this.add_filter( { [ x ]: data[ x ] } );
                    }
                    this.filters[ x ] = data[ x ];
                }
            });
            this.force_reload();
            this.list_key += 1;
            this.toggle_modal();
        },
        submit_search_form() {
            this.$refs.search_form.submit_form();
        },
        value_changed( /*payload*/ ) {
        },
    },
    created() {
        if ( this.params.hasOwnProperty('created__gte') ) { this.filters.created__gte = this.params.created__gte; }
        if ( this.params.hasOwnProperty('created__lte') ) { this.filters.created__lte = this.params.created__lte; }
        if ( this.params.hasOwnProperty('controller') ) { this.filters.controller = this.params.controller.replace(/\|/g, ''); }
        if ( this.params.hasOwnProperty('action') ) { this.filters.action = this.params.action.replace(/\|/g, ''); }
        if ( this.params.hasOwnProperty('user_id') ) { this.filters.user_id = this.params.user_id; }
        if ( this.params.hasOwnProperty('customer_id') ) { this.filters.customer_id = this.params.customer_id; }
        if ( this.params.hasOwnProperty('tags') ) { this.filters.tags = this.params.tags.replace( '[', '' ).replace( ']', '' ); }
    },
    data() {
        return {
            filters          : {},
            list_key         : 0,
            modal_filter_key : 0,
            search_schema    : '_search_user',
        };
    }
}

</script>

<template>

    <div style="width: 80%; margin: auto">
        <b-row>
            <b-col>
                <b-button variant="outline-success" @click="toggle_modal()" size="sm"><icon name="filter" style="color: #DC3545;" color="#DC3545" id="filter-icon"/></b-button>
            </b-col>
            <b-col>
                <h2 v-if="options.title_list" style="text-align: center; margin-bottom: 30px">{{ options.title_list.toUpperCase() }}</h2>
            </b-col>
            <b-col class="text-right">
            </b-col>
        </b-row>
        <vmm-list
            ref              = "listbnv"
            :model           = "model"
            :options         = "override_options"
            :key             = "list_key"

            :skip            = "false"
            :destroy         = "destroy"

            @row_selected    = "row_selected"
            @right-click     = "right_click"
        />

        <!-- modal filtri -->
        <b-modal
            id="modal-filter"
            title="Filtri"
            @hide="clear_search_form"
            button-size="sm"
            >
            <vmm-form
                v-if       = "search_schema"
                :key       = "modal_filter_key"
                :name      = "search_schema"
                :schema    = "$models._search_user"
                :options   = "{}"
                :record    = "filters"
                :submit    = "false"
                :inline    = "true"
                ref        = "search_form"
                @submitted = "operation_success"
                @change    = "value_changed"
            ></vmm-form>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_search_form">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="warning" @click="hide('clear')">
                      Reset
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>
    </div>

</template>

