//////////////////////////////

module.exports = function( type ) {

    var schema = {
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        ddt_company_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'ddt_companies',
                key         : 'id',
                constraints : true,
                store_name  : 'ddt_company',
            }
        },
        numero: {
            type: type.STRING(32),
            allowNull: false,
        },
        documento_normalizzato: {
            type: type.STRING(32),
            allowNull: false,
        },
        data: {
            type: type.DATEONLY,
            allowNull: false,
        },
        destinazione: {
            type: type.STRING(64),
            allowNull: false,
        },
        completed: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: false
        },
        manual: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: false
        },
        navision: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: false
        },
        date_managed: {
            type: type.DATE,
            allowNull: true,
            defaultValue: null
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue : type.NOW,
        },
    };

    return schema;
}

