//////////////////////////////

module.exports = (type) => {

    var schema = {
        user_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'users',
                key         : 'id',
                constraints : true,
                store_name  : 'users',
            }
        },
        automation_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'automations',
                key         : 'id',
                constraints : true,
                store_name  : 'automation',
            }
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue : type.NOW,
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

