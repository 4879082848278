<script>

import { mapActions, mapState, mapGetters } from 'vuex';
import { Chart } from 'highcharts-vue';
import ECharts from 'vue-echarts/components/ECharts';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import moment from 'moment';

import BlockWidget from '../../components/Layout/BlockWidget';

export default {
    name: 'graph-result',
    props: [ '' ],
    components: {
        highcharts : Chart,
        echart     : ECharts,
        BlockWidget,
    },
    computed: {
        ...mapState('authentication', {
            userobj : state => state.user,
        }),
        ...mapState('_search_automation_automation_id_select', {
            loading_select : state => state.loading,
            items_select   : state => state.items,
        }),
        ...mapState('graph', {
            loading_graph      : state => state.loading,
            primary_goal       : state => state.primary_goal,
            correct_execution  : state => state.correct_execution,
            errors             : state => state.errors,
            total              : state => state.total,
            total_bots_managed : state => state.total_bots_managed,
            trend              : state => state.trend,
            trend_subtitle     : state => state.trend_subtitle,
            params             : state => state.params,
            items_graph        : state => state.items,
            force_reload       : state => state.force_reload,
        }),
        ...mapGetters( 'authentication', [ 'customer_id' ] ),
        relation_success_min() {
            if ( this.params && this.params.automation_id ) {
                let obj = this.items_select.filter( x => x.id === this.params.automation_id );
                return obj[0].config.relation_success_min || 42;
            }
            return 0;
        },
        graph_config() {
            if ( this.params && this.params.automation_id ) {
                let obj = this.items_select.filter( x => x.id === this.params.automation_id );
                return obj[0].config.graphs || {};
            }
            return {};
        },
        boxes_config() {
            if ( this.params && this.params.automation_id ) {
                let obj = this.items_select.filter( x => x.id === this.params.automation_id );
                return obj[0].config.boxes || {};
            }
            return {};
        },
        saved_time_number() {
            if ( this.relation_success_min === 0 ) { return '-'; }
            let tot_minutes = this.total_bots_managed * this.relation_success_min;
            let hours = Math.floor(tot_minutes / 60);
            let minutes = tot_minutes % 60;
            if ( minutes > 30 ) { hours += 1; }
            return hours;
        },
        saved_time() {
            if ( !this.relation_success_min ) { return '-'; }
            let value = `${ this.saved_time_number.toLocaleString('it-IT') } ore`;
            return value;
        },
        saved_money() {
            if ( this.saved_time === '-' ) { return '-'; }
            let money = this.saved_time_number * this.hourly_rate;
            return ( money ).toLocaleString('it-IT', {
                style: 'currency',
                currency: 'Eur',
            });
        },
        show_trend() {
            if ( !this.graph_config.hasOwnProperty('show_trend') ) { return true; }
            return this.graph_config.show_trend;
        },
        show_business_goal_achieved() {
            if ( !this.graph_config.hasOwnProperty('show_business_goal_achieved') ) { return true; }
            return this.graph_config.show_business_goal_achieved;
        },
        show_performance_statistics() {
            if ( !this.graph_config.hasOwnProperty('show_performance_statistics') ) { return true; }
            return this.graph_config.show_performance_statistics;
        },
        show_missed_objective_detail() {
            if ( !this.graph_config.hasOwnProperty('show_missed_objective_detail') ) { return true; }
            return this.graph_config.show_missed_objective_detail;
        },
        label_trend() {
            if ( !this.graph_config.hasOwnProperty('label_trend') ) { return 'Trend'; }
            return this.graph_config.label_trend;
        },
        label_business_goal_achieved() {
            if ( !this.graph_config.hasOwnProperty('label_business_goal_achieved') ) { return 'Macchina vs umano'; }
            return this.graph_config.label_business_goal_achieved;
        },
        label_performance_statistics() {
            if ( !this.graph_config.hasOwnProperty('label_performance_statistics') ) { return 'Statistica di funzionamento'; }
            return this.graph_config.label_performance_statistics;
        },
        label_missed_objective_detail() {
            if ( !this.graph_config.hasOwnProperty('label_missed_objective_detail') ) { return 'Supporto umano'; }
            return this.graph_config.label_missed_objective_detail;
        },
        label_total() {
            if ( !this.boxes_config.hasOwnProperty('label_total') ) { return 'Item totali'; }
            return this.boxes_config.label_total;
        },
        label_total_bots() {
            if ( !this.boxes_config.hasOwnProperty('label_total_bots') ) { return 'Item gestiti dai bot'; }
            return this.boxes_config.label_total_bots;
        },
        label_saved_hours() {
            if ( !this.boxes_config.hasOwnProperty('label_saved_hours') ) { return 'Ore risparmiate'; }
            return this.boxes_config.label_saved_hours;
        },
        label_savings() {
            if ( !this.boxes_config.hasOwnProperty('label_savings') ) { return 'Risparmio'; }
            return this.boxes_config.label_savings;
        },
        hourly_rate() {
            if ( this.params && this.params.automation_id ) {
                let obj = this.items_select.filter( x => x.id === this.params.automation_id );
                return obj[0].config.hourly_rate || 42;
            }
            return 42;
        },
    },
    watch: {
        loading_select( new_value, old_value ) {
            if ( new_value === false && old_value === true ) {
                this.add_param( { automation_id: this.items_select[0].id } );
                let start_date = moment().subtract( 31, 'days' ).format('YYYY-MM-DD');
                let stop_date  = moment().format('YYYY-MM-DD');
                this.add_param( { range: [ start_date, stop_date ].join('|') } );
                this.form_key += 1;
                this.get_all( { params: {}, paginate: false, include: false } );
            }
        },
        loading_graph( new_value ) {
            if ( new_value === false ) {
                this.loaded = true;
            }
        },
    },
    methods: {
        ...mapActions( 'graph', {
            get_all   : 'get_all',
            add_param : 'add_param',
        }),
        filter( data ) {
            if ( data.hasOwnProperty('automation_id') && data.automation_id === '' ) {
                this.add_param( { automation_id: this.items_select[0].id } );
                this.form_key += 1;
                return;
            }
            this.loaded = false;
            this.add_param( data );
            this.get_all( { params: {}, paginate: false, include: false } );
        },
        get_date( created ) {
            return moment( created ).format( 'DD/MM/YYYY - HH:mm:ss' );
        },
    },
    created() {
        if ( this.force_reload === true && this.params.automation_id && this.params.range ) {
            this.get_all( { params: {}, paginate: false, include: false } );
        } else if ( this.params.automation_id && this.params.range && this.primary_goal !== null ) {
            this.loaded = true;
        }
    },
    data() {
        return {
            form_key : 0,
            loaded   : false,
            initOptions: {
              renderer: 'canvas'
            }, 

        }
    },
}

</script>

<template>
    <div>

        <h2 style="text-align: center; margin-bottom: 30px;">STATISTICHE AUTOMAZIONI</h2>

        <vmm-form
            :key       = "form_key"
            name       = "_search_automation"
            :options   = "{}"
            :record    = "params"
            :submit    = "false"
            :inline    = "true"
            ref        = "search_form"
            @change    = "filter"
        />

        <b-row class="justify-content-md-center" style="margin-bottom: 20px;" v-if="loaded">

            <b-col lg="2" md="3" sm="12">
                <block-widget
                    :title        = "label_total"
                    format        = "number"
                    :loading      = "loading_graph"
                    :value        = "total"
                >
                </block-widget>
            </b-col>

            <b-col lg="2" md="3" sm="12">
                <block-widget
                    :title        = "label_total_bots"
                    format        = "number"
                    :loading      = "loading_graph"
                    :value        = "total_bots_managed"
                >
                </block-widget>
            </b-col>

            <b-col lg="2" md="3" sm="12">
                <block-widget
                    :title        = "label_saved_hours"
                    format        = "string"
                    :loading      = "loading_graph"
                    :value        = "saved_time"
                >
                </block-widget>
            </b-col>

            <b-col lg="2" md="3" sm="12">
                <block-widget
                    :title        = "label_savings"
                    format        = "string"
                    :loading      = "loading_graph"
                    :value        = "saved_money"
                >
                </block-widget>
            </b-col>

        </b-row>
        <div v-if="loaded">
            <b-row class="justify-content-md-center" style="margin-bottom: 20px;">
                <b-col xs="12" lg="3" md="4" v-if="show_performance_statistics">

                    <b-card
                       
                        border-variant="light"
                        class = "shadow"
                        >
                        <b-card-title class="graph-title">{{ label_performance_statistics }}</b-card-title>
                        <highcharts :options="correct_execution"></highcharts>
                    </b-card>

                </b-col>

                <b-col xs="12" lg="3" md="4" v-if="show_business_goal_achieved">

                    <b-card
                       
                        border-variant="light"
                        class = "shadow"
                        >
                        <b-card-title class="graph-title">{{ label_business_goal_achieved }}</b-card-title>
                        <highcharts :options="primary_goal"></highcharts>
                    </b-card>
                </b-col>

                <b-col xs="12" lg="3" md="4" v-if="show_missed_objective_detail">

                    <b-card
                       
                        border-variant="light"
                        class = "shadow"
                        >
                        <b-card-title class="graph-title">{{ label_missed_objective_detail }}</b-card-title>
                        <highcharts :options="errors"></highcharts>
                    </b-card>

                </b-col>
            </b-row>
            <b-row class="justify-content-md-center">
                <b-col xs="12" lg="9" md="12">

                    <b-card
                        v-if="show_trend"
                        border-variant="light"
                        class = "shadow"
                        >
                        <b-card-title class="graph-title">{{ label_trend }}</b-card-title>
                        <b-card-sub-title class="graph-title">{{ trend_subtitle }}</b-card-sub-title>
                        <echart style="width: 100%" ref="trend" :options="trend" :init-options="initOptions"></echart>
                    </b-card>

                </b-col>
            </b-row>
        </div>
        <div v-if="loaded" style="margin-top: 50px; margin-bottom: 30px;">
            <b-row class="justify-content-md-center">
                <b-col xs="12" lg="9" md="12">
                    <div v-if="items_graph.length === 0" style="width: 100%;">
                        <b-alert variant="success" show>Non ci sono stati errori non gestiti nel periodo selezionato.</b-alert>
                    </div>
                    <div v-else-if="items_graph.length > 0" style="width: 100%;">
                        <h3 style="text-align: center;">Ultimi errori non gestiti</h3>
                        <div v-for="item in items_graph" style="margin-bottom: 10px;" :key="'not_managed_rows_' + item.id">
                            
                            <b-card v-if="item.config.log_row.image_path" img-src="item.config.log_row.image_path" img-top>
                                <b-card-title>{{ get_date( item.config.log_row.created ) }}</b-card-title>
                                <b-card-text>{{ item.error }}</b-card-text>
                            </b-card>
                            
                            <b-card v-else-if="!item.config.log_row.image_path">
                                <b-card-title>{{ get_date( item.config.log_row.created ) }}</b-card-title>
                                <b-card-text>{{ item.error }}</b-card-text>
                            </b-card>
        
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<style>
    .graph-title {
        color: #787878;
        font-weight: 100;
    }
</style>
