<script>

import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    name: 'vmm-select',
    computed: {
        options () {
            return this.$store.state[ this.store_name ];
        },
        store_name() {
            //if ( this.config._references.hasOwnProperty('store_name') ) { return this.config._references.store_name; }
            //console.log(`${ this.schema }_${ this.name }_select`);
            return `${ this.schema }_${ this.name }_select`
        },
        key_label() {
            return ( this.config._references.hasOwnProperty('key_label') && typeof this.config._references.key_label === 'string' ) ? this.config._references.key_label : 'id';
        },
    },
    created () {
        //console.log( this.store_name );
        this.$store.dispatch( this.store_name + '/get_all', { params: { paginate: false }/*, force_reload: true*/ } );
    },
};

</script>

<template>

    <div>
        <div class="form-group" :class="field_class" :style="field_style" v-if="options.loading === false">
            <label v-if="displayLabel" :for="'bnv-form-' + name" :class="label_class">{{ get_label() }}</label>
            <div :class="element_container_class">
                <b-select
                    ref               = "input"
                    class             = "form-control"
                    :size             = "input_class"
                    v-bind:class      = "{ 'is-invalid': error_component }"
                    :aria-label       = "name"
                    :aria-describedby = "name"
                    :id               = "'bnv-form-' + name"
                    v-model           = "model"
                    :disabled         = "disabled || _readonly || options.loading"
                    @change           = "value_changed"
                    >
                    <option value="">- - - - - - -</option>
                    <option v-for="option in options.items" v-bind:key="'bnv-form-' + name + '-' + option[ key_label ]" :value="option[ key_label ]">{{ option.label }}</option>
                </b-select>
                <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted" v-html="_helper"></small>
                <input v-if="_readonly" type="hidden" v-model="model" />
                <div class="invalid-feedback">
                    {{ get_error_message() }}
                </div>
                <div v-if="confirm_message" class="valid-feedback">
                    {{ confirm_message }}
                </div>
            </div>
        </div>
        <div class="form-group" :class="field_class" :style="field_style" v-if="options.loading === true">
            <label :for="'bnv-form-loader-' + name" :class="label_class">{{ get_label() }}</label>
            <div :class="element_container_class">
                <input
                    type        = "text"
                    class       = "form-control spinner"
                    placeholder = "caricamento opzioni..."
                    :id         = "'bnv-form-loader-' + name"
                    :disabled   = "true"
                />
                <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted" v-html="_helper"></small>
            </div>
        </div>
    </div>

</template>



