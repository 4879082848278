<script>

export default {
    name: 'ddt-details-manager',
    props: [ 'item' ],
    components : {},
    computed : {
        items() {
            return this.item.ddt_items;
        },
    },
    watch: {
    },
    methods : {
    },
    created() {},
    data() {
        return {
        }
    },
}

</script>

<template>
    <b-card style="">
        <table style="width: 100%; border: 1px solid lightgrey;" id="table-manager">
            <tr>
                <th>Codice</th><th>Descrizione</th><th>Ordine</th><th style="text-align: center">Udm</th><th style="text-align: center">Q.tà</th>
            </tr>
            <tbody v-if="items.length > 0">
                <tr v-for="row in items" :key="'row_detail_' + item.id + '_' + row.id" style="background-color: white" >
                    <td>
                        {{ row.codiceProdotto }}
                    </td>
                    <td>
                        {{ row.descrizione }}
                    </td>
                    <td :style="{ 'background-color': row.ordine_id == $route.params.id ? 'white' : 'lightgrey' }">
                        {{ row.order.ordine_numero }}
                    </td>
                    <td style="text-align: center">
                        {{ row.unita }}
                    </td>
                    <td style="text-align: center">
                        <span v-if="row.config.quantita_original" style="text-decoration: line-through; color: white; font-weight: 600; background-color: #EC927F; padding: 0 3px;">{{ row.config.quantita_original }}</span>
                        {{ parseFloat( row.quantita ) }}
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="items.length === 0">
                <tr style="background-color: white"><td colspan="3" class="text-center" style="font-weight: 700">Non ci sono record</td></tr>
            </tbody>
        </table>
    </b-card>
</template>

<style>

    table#table-manager td,th {
        font-size: 0.8rem;
        padding: 4px;
    }

</style>
