
<script>

import listpage from '../listpage';

export default {
    name: 'ddt_listpage',
    extends: listpage,
    components: {},
    props: [],
    computed: {
        override_options() {
            var options = {
                initialFilter : { /*'ddtorder.locked' : 'false', manual: 'false'*/ },
            };
            if ( this.check_locked === false ) {
                //options.initialFilter['ddtorder.locked'] = 'false';
            } else {
                //this.$store.dispatch( 'ddt/remove_filter', 'ddtorder.locked' );
            }
            if ( this.check_completed === false ) {
                options.initialFilter['completed'] = 'false';
                //options.initialFilter['manual'] = 'false';
                this.$store.dispatch( 'ddt/add_filter', { manual: false } );
                this.$store.dispatch( 'ddt/add_filter', { 'ddtorder.locked': false } );
            } else {
                options.initialFilter['completed'] = 'true';
                this.$store.dispatch( 'ddt/remove_filter', 'manual' );
                this.$store.dispatch( 'ddt/remove_filter', 'ddtorder.locked' );
                //this.$store.dispatch( 'ddt/remove_filter', 'completed' );
            }
            return options;
        },
        expand_collapse() {
            return this.$store.state.ddt.expand_collapse;
        },
    },
    watch: {
        expand_collapse() {
            this.$store.dispatch( 'ddt/expand_collapse' );
            this.list_key += 1;
        },
        check_locked() {
            this.$store.dispatch( 'ddt/set_params', { force_reload: true } );
            this.list_key += 1;
        },
        check_completed() {
            console.log( 'check_completed' );
            this.$store.dispatch( 'ddt/add_filter', { manual: true } );
            this.$store.dispatch( 'ddt/set_params', { force_reload: true } );
            this.list_key += 1;
        },
        search( new_value ) {
            //console.log(`valore cambiato: "${ new_value }"`);
            if ( new_value === '' ) {
                this.$store.dispatch( 'ddt/remove_filter', 'numero' );
            } else if ( new_value.length < 3 ) {
                return;
            } else {
                this.$store.dispatch( 'ddt/add_filter', { numero: `|${ new_value }|` } );
            }
            this.$store.dispatch( 'ddt/force_reload' );
            this.list_key += 1;
        },
    },
    methods: {
        reload( options ) {
            setTimeout( () => {
                this.$store.dispatch( 'ddt/get_all', { force_reload: true } );
                if ( options === 'manuale' ) {
                    this.make_toast( 'Ordine bloccato', `L'ordine è stato contrassegnato come "bloccato"` )
                } else {
                    this.make_toast( 'DDT Gestito', `Il  DDT è stato contrassegnato come "${ options }"` )
                }
            }, 250 );
        },
        make_toast(title, body) {
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
        clear_search() {
            this.search = '';
        },
        expand_all() {
            this.expandall = !this.expandall;
            this.$store.dispatch( 'ddt/set_expand_collapse', this.expandall );
        }
    },
    created() {
    },
    data() {
        return {
            list_key        : 0,
            check_locked    : false,
            check_completed : false,
            search          : '',
            expandall       : false,
        };
    }
}

</script>

<template>

    <div style="width: 80%; margin: auto">

        <b-row class="text-left">
            <b-col>
                <b-form-checkbox v-model="check_completed" name="check-completed" switch>
                    mostra ddt gestiti
                </b-form-checkbox>
                <b-button :pressed="expandall" size="sm" @click="expand_all()" variant="outline-info" style="margin-top: 10px;">
                    espandi tutti
                </b-button>
                <!-- <b-form-checkbox v-model="expand_all" name="expand-all" switch>
                </b-form-checkbox> -->
            </b-col>
            <b-col>
                <h2 style="text-align: center; margin-bottom: 30px">DDT</h2>
            </b-col>
            <b-col class="text-right">
                <!-- <b-form-checkbox v-model="check_locked" name="check-locked" switch>
                    mostra ordini bloccati
                </b-form-checkbox> -->
                <b-input-group class="" size="sm">
                    <b-form-input
                        v-model     = "search"
                        placeholder = "numero DDT, cod.prod., descrizione"
                        debounce    = "600"
                    />
                    <b-input-group-append>
                        <b-button variant="outline-secondary" @click="clear_search()" :disabled="search === ''"><icon name="times"/></b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>

        <div>
            <vmm-list
                ref              = "listbnv"
                :key             = "list_key"
                :model           = "model"
                :options         = "override_options"
    
                :skip            = "false"
                :destroy         = "destroy"
                @reload          = "reload"
            />
        </div>

    </div>

</template>

