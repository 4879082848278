///////////////////////////////////////////

import Vue from 'vue'
import VueRouter from 'vue-router'

import Home                 from '../views/Home.vue'
import listpage             from '../views/listpage.vue'
import formpage             from '../views/formpage.vue'
import user_listpage        from '@/views/user/listpage';
import user_formpage        from '../views/user/formpage.vue'
import login                from '../views/login.vue'
import ddt_listpage         from '@/views/magazziniere/ddt_listpage';
import order_listpage       from '@/views/manager/order_listpage';
import orderddt_listpage    from '@/views/manager/orderddt_listpage';
import ddt_manager_listpage from '@/views/manager/ddt_listpage';
import log_listpage         from '@/views/log/listpage';
import invoice_consistency  from '@/views/invoice_consistency/list';
import inv_app_operator     from '@/views/invoice_approval/operator_listpage';
import inv_app_ceo          from '@/views/invoice_approval/ceo_listpage';
import graph_result         from '@/views/stats/result.vue';
import reset_password       from '@/views/user/reset_password';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/list/log',
        name: 'log_listpage',
        component: log_listpage,
        props: { model_from_route: 'log' }
    },
    {
        path: '/list/user',
        name: 'user_listpage',
        component: user_listpage,
        props: { model_from_route: 'user' }
    },
    {
        path: '/user/resetpassword',
        name: 'reset_password',
        component: reset_password,
        props: { /*model_from_route: 'user'*/ }
    },
    {
        path: '/list/ddt',
        name: 'list_ddt',
        component: ddt_listpage,
        props: { model_from_route: 'ddt' }
    },
    {
        path: '/list/invapp/operator',
        name: 'inv_app_operator',
        component: inv_app_operator,
        props: { model_from_route: 'invoice_approval' }
    },
    {
        path: '/list/invapp/ceo',
        name: 'inv_app_ceo',
        component: inv_app_ceo,
        props: { model_from_route: 'invoice_approval_ceo' }
    },
    {
        path: '/listmn/ddt',
        name: 'listmn_ddt',
        component: ddt_manager_listpage,
        props: { model_from_route: '_ddt_manager' }
    },
    {
        path: '/list/ddt/:id',
        name: 'list_orderddt',
        component: orderddt_listpage,
        props: { model_from_route: 'ddt' }
    },
    {
        path: '/list/order',
        name: 'list_order',
        component: order_listpage,
        props: { model_from_route: 'order' }
    },
    {
        path: '/list/invoice_consistency',
        name: 'list_invoice_consistency',
        component: invoice_consistency,
        props: { model_from_route: 'invoice_consistency' }
    },
    {
        path: '/list/_user_create',
        redirect: '/list/user'
    },
    {
        path: '/list/:model',
        name: 'list',
        component: listpage,
    },
    {
        path: '/login',
        name: 'login',
        component: login,
    },
    {
        path: '/form/user/:id?',
        name: 'user-form',
        component: user_formpage,
        props: { model_from_route: '_user_create' }
    },
    {
        path: '/form/:model/:id?',
        name: 'form',
        component: formpage,
    },
    {
        path: '/graph/result',
        name: 'result',
        component: graph_result,
    },
//  {
//      path: '/about',
//      name: 'About',
//      // route level code-splitting
//      // this generates a separate chunk (about.[hash].js) for this route
//      // which is lazy-loaded when the route is visited.
//      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//  },
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    const publicPages = [ '/login', '/user/resetpassword' ];
    const authRequired = !publicPages.includes(to.path);
    const user = localStorage.getItem('user');

    if (authRequired && !user) {
        return next('/login');
    } else if ( user ) {
        // se sto ricaricando la pagina e l'utente non è settato in statemanager
        router.app.$store.dispatch('authentication/set_user', JSON.parse( user ));
    }

    next();
})

export default router

