
export default {
    cols: [
          { 
              row1: {
                field1: 'piva_cliente,cf_cliente',
                field2: 'piva_emittente,cf_emittente',
                field3: null,
              },
              row2: {
                field1: 'piva_cliente',
                field2: 'piva_emittente',
                field3: 'tipo_documento',
              },
              row3: {
                field1: 'cf_cliente',
                field2: 'cf_emittente',
                field3: 'presente_in_TS',
              },
              row4: {
                field1: 'id_paese_cessionario',
                field2: 'id_paese_cedente',
                field3: 'cont_corretta',
              },
              row5: {
                field1: 'data_fattura_da',
                field2: 'data_fattura_a',
                field3: 'nota_di_credito',
              },
              row6: {
                field1: 'data_consegna_da',
                field2: 'data_consegna_a',
                field3: 'idsdi_mancante',
              },
          },
    ]
};

