<script>

//import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    name: 'attribute-value',
    props: [ 'item' ],
    components : {},
    computed : {
    },
    watch: {},
    methods : {
    },
    created() {},
    data() {
        return {
        }
    },
}

</script>

<template>
    <div>
        <span v-if="item.value">{{ item.value }}</span>
        <span v-else-if="!item.value"><pre>null</pre></span>
    </div>
</template>

<style>
</style>

