<script>

//import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    name: 'user-roles',
    props: [ 'item' ],
    components : {},
    computed : {
    },
    watch: {},
    methods : {
    },
    created() {},
    data() {
        return {
        }
    },
}

</script>

<template>
    <div>
        <template v-for="role in item.user_roles">
            <b-badge :key="'role_' + role.id">{{ role.label }}</b-badge>
        </template>
    </div>
</template>

<style>
</style>

