// //////////////////////////////////////

import axios from 'axios';

import vmm_store from '@/vmm-store';
const baseModule = vmm_store.module;

var options = {
    model_name: 'user',
    default_order: 'id ASC',
}

const state = {
    password_changed : false,
    user             : null,
    is_authenticated : false,
    roles            : [],
    jwt_expired      : false,
    saving_password  : false,
    generic_error    : false,
    psw_settings     : null,
    psw_changed      : false,
    request_sent     : false,
}

const actions = {
    load_settings( { commit, state }, data ) {
        const url = `${state.options.config.backend_url}/model/user/psw/settings`;
        let headers = {
            'Authorization': 'Bearer ' + data.jwt,
            'Accept'       : 'application/json',
        };
        axios.get( url, { headers } )
            .then( res => {
                commit( 'psw_settings', res.data );
            })
            .catch( error => {
                let message;
                try {
                    message = error.response.data.message;
                } catch ( error ) {
                    console.log( error.response );
                }
                if ( message === 'jwt expired' ) {
                    commit('jwt_expired');
                }
            });
    },
    login_user ({ dispatch, commit, state, getters }, { email, password }) {
        commit('set_params', { loading: true })
        const url = `${state.options.config.backend_url}/model/user/login`
        axios.post(url, { email, password })
            .then(user => {
                dispatch( 'set_user', user.data )
                //commit('set_user', user.data);
                //dispatch( 'ss_company/success_list', user.data.user_companies, { root: true } );
                //dispatch( 'ss_store/set_params', { stores: user.data.user_stores }, { root: true } );
                commit('set_params', { loading: false })
                dispatch('alert/clear', null, { root: true })
                axios.defaults.headers.common.Authorization = 'Bearer ' + user.data.jwt
                var redirect = null;
                if ( user.data.hasOwnProperty('user_roles') && user.data.user_roles.length > 0 ) {
                    user.data.user_roles.map( x => {
                        if ( x.config.hasOwnProperty('home') ) {
                            redirect = x.config.home; // TODO il redirect viene preso dall'ultimo ruolo che ha l'attributo config.home
                        }
                    });
                }
                getters.router.push( redirect || state.options.config.gui.login_succeeded_url );
            })
            .catch(error => {
                commit('set_params', { loading: false })
                dispatch('alert/error', error.response.data.message, { root: true })
            })
    },
    logout_user ({ commit, rootState, dispatch }/*{ getters, dispatch }*/ ) {
        localStorage.removeItem('jwt')
        localStorage.removeItem('user')
        document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        axios.defaults.headers.common.Authorization = '';
        commit( 'set_user', null );
        Object.keys( rootState ).map( name => {
            try {
                dispatch( `${ name }/clear`, null, { root: true } );
                dispatch( `${ name }/set_params`, { force_reload: true }, { root: true } );
            } catch ( error ) {
                console.log( 'clear fallito per', name );
            }
        });
        //var modules = [ 'user', 'company', 'store', 'collection', 'product', 'ss_company', 'ss_store', 'role', 'log' ];
        //modules.map( module => dispatch( `${ module }/force_reload`, true, { root: true } ) )
        //getters.router.push('/login')
    },
//  reload( { state, dispatch } ) {
//      const url = `${state.options.config.backend_url}/model/user/${ state.user.id }/reload`
//      axios.get( url )
//          .then(user => {
//              dispatch( 'set_user', user.data )
//              dispatch( 'ss_company/success_list', user.data.user_companies, { root: true } );
//              dispatch( 'ss_store/success_list', user.data.user_stores, { root: true } );
//          })
//          .catch( error => { console.log( error ); });
//  },
    set_user( { commit/*, dispatch*/ }, user ) {
        commit( 'set_user', user );
//      dispatch( 'ss_company/success_list', user.user_companies, { root: true } );
//      dispatch( 'ss_store/set_params', { stores: user.user_stores }, { root: true } );
    },
    reset_password( { state, dispatch, commit }, email ) {
        const url = `${state.options.config.backend_url}/model/user/psw/reset`;
        axios.post(url, email )
            .then(user => {
                var res = user.data;
                commit('request_sent');
                dispatch('alert/success', res.message, { root: true });
            })
            .catch(error => {
                let error_message;
                try {
                    error_message = error.response.data.message;
                } catch ( eror ) {
                    error_message = error.toString();
                }
                dispatch('alert/error', error_message, { root: true });
            })
    },
    save_password( { state, dispatch, commit }, { jwt, new_password, confirm_password } ) {
        const url = `${state.options.config.backend_url}/model/user/psw/save`;
        const local_axios = axios.create();
        local_axios.defaults.headers['Authorization'] = 'Bearer ' + jwt;
        local_axios.post(url, { new_password, confirm_password })
            .then(user => {
                var res = user.data;
                dispatch('alert/success', res.message, { root: true });
                commit('password_changed');
            })
            .catch(error => {
                var msg = '';
                try {
                    msg = error.response.data.message;
                } catch ( error ) {
                    msg = 'cambio password fallito';
                }
                dispatch( 'alert/error', msg, { root: true } );
            })
    },
}

const mutations = {
    set_user (state, obj) {
        if ( obj === null ) {
            state.is_authenticated = false;
            state.obj              = null;
            state.roles            = [];
            return;
        }
        state.is_authenticated = true;
        state.user = obj;
        state.roles = obj.user_roles.map( x => x.label );
        localStorage.setItem('jwt', obj.jwt)
        localStorage.setItem('user', JSON.stringify(obj))
    },
    password_changed( state, value = true ) {
        state.psw_changed = value;
    },
    request_sent( state, value = true ) {
        state.request_sent = value;
        setTimeout( () => { state.request_sent = false; }, 500)
    },
    jwt_expired( state ) {
        state.jwt_expired = true;
    },
    generic_error( state ) {
        state.generic_error = true;
    },
    psw_settings( state, data ) {
        state.psw_settings = data;
    },
}

const getters = {
    is_authenticated: () => {
        return localStorage.getItem('jwt') === null ? false : true
    },
    router: (state) => {
        return state.options.router
    },
    jwt: () => {
        return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).jwt : null;
    },
    can_do: ( state ) => ( policy ) => {
        try {
            var can = state.user.policies.includes( policy );
        } catch ( error ) { return false; }
        return can;
    },
    customer_id: ( state ) => {
        return state.user.customer_id;
    },
}

class AuthenticationModule extends baseModule {
    constructor (new_options = {}) {
        Object.keys(new_options).map(x => {
            options[x] = new_options[x]
        })
        super(options)
    }

    static get_module (new_options = {}) {
        var obj = new AuthenticationModule(new_options)
        return {
            namespaced: true,
            state: {
                ...obj.state,
                ...state,
            },
            getters: {
                ...obj.getters,
                ...getters,
            },
            actions: {
                ...obj.actions,
                ...actions,
            },
            mutations: {
                ...obj.mutations,
                ...mutations,
            },
        }
    }
}

export default AuthenticationModule

