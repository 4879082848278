/////////////////////////////////////////////////

// TODO: aggiungere i moduli custom (escludendoli poi dai models)

import modules_selector   from './helpers/modules_selector'; // TODO non so bene cosa faccia
import base_module        from './modules/base';
import alert_module       from './modules/alert';
import nullboolean_module from './modules/nullboolean';
import base_service       from './service';

class vmm_store {

    constructor( options, modules ) {
        this.options        = options;
        this.modules        = modules;
        this.loaded_modules = {};
    }

    // static public methods ////////////////////

    // carica lo store di Vuex per l'applicativo 
    static get_store( options, ...modules ) {
        var obj = new vmm_store( options, modules );
        obj._get_store();
        return obj.vuex_obj;
    }

    // restituisce i moduli per la gestione delle select
    // (chiamato da vmm-form se installato )
    static load_select_modules( modules, config ) {
        var select_modules = {}
        Object.keys( modules ).map( name => {
            select_modules[ name ] = vmm_store.build_module( modules[ name ].model, config );
        });
        return select_modules;
    }

    static build_module( name, config ) {
        var options = {
            model_name    : name,
            default_order : 'id ASC',
            config 
        };
        // TODO possibilità di passare un config per ogni modulo ( letto da formconfig )
        //if ( this.settings.hasOwnProperty( name ) === true && this.settings[ name ].hasOwnProperty( 'default_order' ) ) {
        //    options.default_order = this.settings[ name ].default_order;
        //}
        var module = base_module.get_module( options );
        return { namespaced: true, ...module };
    }

    // private methods //////////////////////////

    _get_store() {
        this._include_custom(); 
        this._include_from_models();
        this._include_vmm_store_modules();
    }

    _include_vmm_store_modules() {
        this.loaded_modules['alert'] = {
            namespaced : true,
            ...alert_module,
        };
        this.loaded_modules['nullboolean'] = {
            namespaced : true,
            ...alert_module,
            ...nullboolean_module,
        };
    }

    _include_custom() {
        this.storesobj.keys().forEach(file_path => {
            var model_name = file_path.replace(/^\.\//, '')
                                      .replace(/\.module\.js$/, '');
            var module = this.storesobj( file_path );
            this.loaded_modules[ model_name ] = module.default.get_module( { config: this.config } );
        });
    }

    _include_from_models() { // TODO deve includere i moduli custom
        var selector = modules_selector.build( this.options.include );
        var models = this.modelsobj.keys();
        for ( var i = 0; i < models.length; i++ ) {
            var model_name = models[ i ].replace(/^\.\//, '')
                                        .replace(/\.schema\.js$/, '');
            //console.log( model_name, selector.check( model_name ) );
            if ( this.loaded_modules.hasOwnProperty( model_name ) === false && selector.check( model_name ) ) {
                var options = {
                    model_name : model_name,
                    config     : this.config,
                };
                if ( this.settings.hasOwnProperty( model_name ) === true && this.settings[ model_name ].hasOwnProperty( 'model_name' ) ) {
                    options.model_name = this.settings[ model_name ].model_name;
                }
                if ( this.settings.hasOwnProperty( model_name ) === true && this.settings[ model_name ].hasOwnProperty( 'default_order' ) ) {
                    options.default_order = this.settings[ model_name ].default_order;
                }
                var module = base_module.get_module( options );
                this.loaded_modules[ model_name ] = {
                    namespaced : true,
                    ...module,
                };
            }
        }
    }

    // getters & setters ////////////////////////

    get vuex_obj()    { return { modules: this.loaded_modules }; }
    get models_path() { return this.options.models_path; }
    get settings()    { return this.options.settings; }
    get config()      { return this.options.config; }
    get modelsobj()   { return this.options.modelsobj; }
    get storesobj()   { return this.options.storesobj; }

    // static getters ///////////////////////////

    static get module()  { return base_module; }
    static get service() { return base_service; }

}

export default vmm_store;

