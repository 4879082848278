//////////////////////////////

module.exports = (type) => {
    var schema = {
        'date_managed__gte': {
            type: type.DATEONLY,
            allowNull: true,
        },
        'date_managed__lte': {
            type: type.DATEONLY,
            allowNull: true,
        },
        completed: {
            type: type.BOOLEAN,
            allowNull: true,
        },
        ddt_company_id: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'ddt_companies',
                key         : 'id',
                constraints : true,
                store_name  : 'ddt_company',
            }
        }
    };

    return schema;
}

