/////////////////////////////////////////////////

import defaults from './defaults';

class Options {

    constructor( options, model, listconfig ) {
        this.component  = options;
        this.model      = model;
        this.listconfig = listconfig;
    }

    // getters & setters ////////////////////////

    get storeName() { return this.component.storeName || this.model; }

    get options() { return this.listconfig[ this.storeName ]; }

    get primaryKey () {   // default: 'id'
        if ( this.options.hasOwnProperty('primaryKey') && this.component.primaryKey ) {
            return this.component.primaryKey;
        } else if ( this.options.hasOwnProperty('primaryKey') || this.component.primaryKey ) {
            return this.component.primaryKey || this.options['primaryKey'];
        }
        return defaults.PRIMARY_KEY;
    }

    get initialFilter() {
        var filters = {};
        if ( this.options.initialFilter && typeof this.options.initialFilter === 'object' ) {
            Object.keys( this.options.initialFilter ).map( x => {
                filters[ x ] = this.options.initialFilter[ x ];
            });
        }
        if ( this.component.initialFilter && typeof this.component.initialFilter === 'object' ) {
            Object.keys( this.component.initialFilter ).map( x => {
                filters[ x ] = this.component.initialFilter[ x ];
            });
        }
        return Object.keys( filters ).length > 0 ? filters : defaults.INITIAL_FILTER;
    }

    get qryOptions () {
        var options = {};
        if ( this.options.hasOwnProperty('include') ) { options.include = this.options.include; }
        Object.keys( this.initialFilter ).map( x => { options[ x ] = this.initialFilter[ x ]; } );
        if ( this.component.qryOptions && typeof this.qryObject === 'object' ) {
            Object.keys( this.component.qryOptions ).map( key => { options[ key ] = this.component.qryOptions[ key ]; } )
        }
        return options;
    }

    get paginate() { // required
        if ( typeof this.options.paginate === 'boolean' && typeof this.component.paginate === 'boolean' ) {
            return this.component.paginate;
        } else if ( typeof this.options.paginate === 'boolean' || typeof this.component.paginate === 'boolean' ) { 
            return typeof this.component.paginate === 'boolean' ? this.component.paginate : this.options.paginate;
        }
        throw 'paginate must be set in $listconfig or in parent component';
    }

    get size() {
        if ( this.options.size && this.component.size ) { 
            return this.component.size;
        } else if ( this.options.size || this.component.size ) {
            return this.component.size || this.options.size;
        }
        return defaults.SIZE;
    }

    get detailsClass() { // default '' TODO non tiene conto del component
        return this.options.details_class || '';
    }

    get clickable() { // default false TODO non tiene conto del component
        return this.options.clickable === true ? true : false;
    }

    get detailsShow () {       // default: true
        if ( this.component.detailsShow && typeof this.component.detailsShow === 'object' && this.component.detailsShow.hasOwnProperty('props') ) {
            this.component_details = this.component.detailsShow;
            return true;
        }
        if ( this.options.detailsShow && typeof this.options.detailsShow === 'object' && this.options.detailsShow.hasOwnProperty('props') ) {
            this.component_details = this.options.detailsShow;
            return true;
        }
        if ( typeof this.options.detailsShow === 'boolean' && typeof this.component.detailsShow === 'boolean' ) {
            return this.component.detailsShow;
        } else if ( typeof this.options.detailsShow === 'boolean' || typeof this.component.detailsShow === 'boolean' ) { 
            return typeof this.component.detailsShow === 'boolean' ? this.component.detailsShow : this.options.detailsShow;
        }
        return defaults.DETAILS_SHOW;
    }

    get detailsAction() {
        if ( this.options.hasOwnProperty('detailsAction') && this.component.detailsAction ) {
            return this.component.detailsAction;
        } else if ( this.options.hasOwnProperty('detailsAction') || this.component.detailsAction ) {
            return this.component.detailsAction || this.options['detailsAction'];
        }
        return defaults.DETAILS_ACTION;
    }

    get edit() {
        if ( typeof this.options.edit === 'boolean' && typeof this.component.edit === 'boolean' ) {
            return this.component.edit;
        } else if ( typeof this.options.edit === 'boolean' || typeof this.component.edit === 'boolean' ) { 
            return typeof this.component.edit === 'boolean' ? this.component.edit : this.options.edit;
        }
        return defaults.EDIT;
    }

    get editAction() {
        if ( this.options.hasOwnProperty('editAction') && this.component.editAction ) {
            return this.component.editAction;
        } else if ( this.options.hasOwnProperty('editAction') || this.component.editAction ) {
            return this.component.editAction || this.options['editAction'];
        }
        return defaults.EDIT_ACTION;
    }

    get rowClass() {
        if ( this.options.hasOwnProperty('rowClass') && this.component.rowClass ) {
            return this.component.rowClass;
        } else if ( this.options.hasOwnProperty('rowClass') || this.component.rowClass ) {
            return this.component.rowClass || this.options['rowClass'];
        }
        return defaults.ROW_CLASS;
    }

    get link() {
        if ( this.component.link ) { return this.component.link; }
        if ( this.options.link ) { return this.options.link; }
        return null;
    }

    get linkFunction() {
        if ( this.options.hasOwnProperty('linkFunction') && this.component.linkFunction ) {
            return this.component.linkFunction;
        } else if ( this.options.hasOwnProperty('linkFunction') || this.component.linkFunction ) {
            return this.component.linkFunction || this.options['linkFunction'];
        }
        return defaults.LINK_FUNCTION;
    }

    get formPath() {
        if ( this.options.formPath && this.component.formPath ) { 
            return this.component.formPath;
        } else if ( this.options.formPath || this.component.formPath ) {
            return this.component.formPath || this.options.formPath;
        }
        return defaults.FORM_PATH;
    }

    get tableAttributes() {
        var tableAttributes = this.options.tableAttributes || {};
        var attributes = {
            'striped'          : true,
            'no-local-sorting' : true,
            'no-sort-reset'    : true,
            'responsive'       : true,
            'selectable'       : false,
            'select-mode'      : 'single',
        };
        Object.keys( tableAttributes ).map( x => { attributes[ x ] = tableAttributes[ x ]; } );
        return attributes;
    }

    get fields () {
        if ( this.options.hasOwnProperty('fields') ) {
            return this.options.fields.map( column_field => {
                if ( column_field.hasOwnProperty('class') === false ) {
                    column_field['class'] = [];
                } else if ( typeof column_field['class'] === 'string' ) {
                    column_field['class'] = [ column_field['class'] ];
                }
                if ( column_field.hasOwnProperty( 'screen' ) ) {
                    var classes = [];
                    var screen_classes = this._get_column_class( column_field.screen );
                    for ( var i = 0; i < screen_classes.length; i++ ) {
                        var class_string = typeof column_field['class'][ i ] === 'string' ? column_field['class'][ i ] : '';
                        classes.push( class_string + ' ' + screen_classes[ i ]  );
                    }
                    column_field['class'] = classes;
                    delete column_field.screen;
                }
                return column_field;
            });
        }
        return undefined;
    }

    get destroy() {
        if ( typeof this.options.destroy === 'boolean' && typeof this.component.destroy === 'boolean' ) {
            return this.component.destroy;
        } else if ( typeof this.options.destroy === 'boolean' || typeof this.component.destroy === 'boolean' ) { 
            return typeof this.component.destroy === 'boolean' ? this.component.destroy : this.options.destroy;
        }
        return defaults.DESTROY;
    }

    get add() {
        return typeof this.options.add === 'boolean' ? this.options.add : false;
    }

    // static getters ///////////////////////////

}

export default Options;

