/////////////////////////////////////////////////

class filters_obj {

    constructor( store_name, default_values = {} ) {
        this.store_name     = store_name;
        this.default_values = default_values;
        this._populate_defaults();
    }

    // static public methods ////////////////////

    update( payload, store ) {
        let field = typeof payload === 'object' ? Object.keys( payload )[0] : payload;
        if ( this[ field ] !== payload[ field ] ) {
            if ( payload[ field ] ) {
                this[ field ] = payload[ field ];
                this._add_filter( field, store );
            } else {
                this[ field ] = null;
                this._remove_filter( field, store );
            }
            return true;
        }
    }

    // static private methods ///////////////////

    // public methods ///////////////////////////

    // private methods //////////////////////////

    async _populate_defaults() {
        for ( var i = 0; i < this.fields.length; i++ ) {
            let field = this.fields[ i ];
            if ( this.default_values.hasOwnProperty( field ) && this.default_values !== null ) {
                this[ field ] = this.default_values[ field ];
                //this._add_filter( field );
            } else {
                this[ field ] = null;
                //this._remove_filter( field );
            }
        }
    }

    _add_filter( field, store ) {
        store.dispatch( `${ this.store_name }/add_param`, { [ field ]: this[ field ] } );
    }

    _remove_filter( field, store ) {
        store.dispatch( `${ this.store_name }/remove_param`, field );
    }

    // getters & setters ////////////////////////

    get filters() {
        let res = {};
        this.fields.map( field => {
            if ( this[ field ] ) {
                res[ field ] = this[ field ];
            }
        });
        return res;
    }

    get fields() {
        return [
            'invoice_approval_state_id', 'data', 'cliente_piva', 'fornitore_piva', 'cliente_cf', 'fornitore_cf',
            'modalita_pagamento', 'modalita_pagamento__not', 'data_scadenza_pagamento',
        ];
    }

}

// static getters ///////////////////////////

export default filters_obj;

