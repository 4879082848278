
<script>

export default {
    name: 'Formpage',
    components: {},
    props: [ 'model_from_route' ],
    computed: {
        model()    { return this.$route.params.model || this.model_from_route; },
        obj()      { return this.$store.state[ this.model ].obj; },
        executed() { return this.$store.state[ this.model ].executed; },
        created()  { return this.$store.state[ this.model ].created; },
        updated()  { return this.$store.state[ this.model ].updated; },
    },
    watch: {
        executed( new_value ) { if ( new_value ) { this.object_loaded(); } },
        created( new_value ) { if ( new_value ) { this.show_notice( 'success', 'Avviso', 'record creato con successo' ); } },
        updated( new_value ) { if ( new_value ) { this.show_notice( 'success', 'Avviso', 'record salvato con successo' ); } },
    },
    methods: {
        saved( /*data*/ ) {
            //console.log( data );
        },
        object_loaded() {
            this.record = this.obj;
            this.loaded = true;
        },
        show_notice( variant, title, text, append = false ) {
            this.$bvToast.toast(text, {
                title         : title,
                autoHideDelay : 5000,
                appendToast   : append,
                variant       : variant,
                solid         : true,
                toaster       : 'b-toaster-top-right',
            })
        },
    },
    created() {
        if ( this.$route.params.id ) {
            this.$store.dispatch( `${ this.model }/get_by_id`, { id: this.$route.params.id } );
        } else {
            this.$store.dispatch( `${ this.model }/set_params`, { obj: {} } );
            this.object_loaded();
        }
    },
    data() {
        return {
            loaded : false,
            record : {},
        };
    }
}

</script>

<template>
    <vmm-form
        v-if       = "loaded"
        :name      = "model"
        :options   = "{}"
        :record    = "record"
        :submit    = "true"
        :inline    = "false"
        ref        = "form"
        @saved     = "saved"
    />
</template>

