<script>

const moment = require('moment');
import DatePicker from 'vue2-datepicker';
import bnvbase from './bnvbase.vue';

// https://github.com/mengxiong10/vue2-datepicker

export default {
    extends: bnvbase,
    components : { DatePicker },
    computed: {
        shortcuts() {
            return [];
        },
    },
    methods: {
        clear_value() {
            //if ( this.initial_value ) { this.model = this.initial_value; }
            //else { this.model = null; }
            //this.value_changed();
        },
        value_changed() {
            if ( Array.isArray( this.model ) && this.model[0] === null && this.model[1] === null ) {
                if ( this.initial_value ) { this.model = this.initial_value; }
            }
            let payload = { [ this.name ] : null };
            if ( this.model !== null && Array.isArray( this.model ) ) {
                let from; let to;
                try {
                    from = moment( this.model[0] ).format('YYYY-MM-DD');
                    if ( from === 'Invalid date' ) {
                        from = null;
                    }
                } catch ( error ) {
                    from = null;
                }
                try {
                    to = moment( this.model[1] ).format('YYYY-MM-DD');
                    if ( to === 'Invalid date' ) {
                        to = null;
                    }
                } catch ( error ) {
                    to = null;
                }
                if ( !from || !to ) {
                    this.model = null;
                    payload[ this.name ] = null;
                } else {
                    payload[ this.name ] = [ from, to ].join('|');
                }
            } else {
                this.model = null;
            }
            this.$emit('change', payload );
        },
    },
    created() {
        if (  typeof this.value === 'undefined' || this.value === null  ) { this.model = ''; this.initial_value = this.model; }
        else {
            this.initial_value = this.value;
            let [ from, to ] = this.value.split('|').map( x => moment( x, 'YYYY-MM-DD' ) );
            let from_date = new Date( from.year(), from.month(), from.date() );
            let to_date   = new Date( to.year(), to.month(), to.date() );
            this.model = [ from_date, to_date ];
            this.initial_value = this.model;
        }
    },
    data() {
        return {
            formatter: {
                // Date to String
                stringify: (date) => {
                    return date ? moment(date).format('DD/MM/YYYY') : null
                },
                // String to Date
                parse: (value) => {
                    return value ? moment(value, 'DD/MM/YYYY').toDate() : null
                }
            },
            initial_value: null,
        }
    },
};

</script>

<template>

    <div class="form-group" :class="field_class" :style="field_style">
        <label :for="'bnv-form-' + name" :class="label_class">{{ get_label() }}</label>
        <div :class="element_container_class" style="width: 100%">
            <DatePicker
                range
                :id             = "'bnv-form-' + name"
                lang            = "it"
                placeholder     = "imposta un intervallo"
                input-class     = "form-control"
                :append-to-body = "false"
                :shortcuts      = "shortcuts"
                :formatter      = "formatter"
                v-model         = "model"
                :disabled       = "disabled"
                :readonly       = "_readonly"
                :clearable      = "true"
                @change         = "value_changed"
                @clear          = "clear_value"
                >
                <i class="glyphicon glyphicon-th" slot="calendar-icon" />
            </DatePicker>
            <div class="invalid-feedback">
                {{ get_error_message() }}
            </div>
        </div>
    </div>

</template>

<style>
    .mx-datepicker {  width: inherit; height: calc(1.5em + 0.5rem + 2px); }
    .mx-icon-calendar { display: none }
</style>

