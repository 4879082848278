<script>

import { mapState } from 'vuex';

export default {
    name: 'ddt-numero',
    props: [ 'item' ],
    components : {},
    computed : {
        ...mapState('ddt', {
            params : state => state.params,
        }),
        filter() {
            if ( typeof this.params === 'object' && this.params.hasOwnProperty('numero') ) {
                return this.params.numero.replace(/\|/g, '')
            }
            return null;
        }
    },
    watch: {},
    methods : {
        enlight_string( str ) {
             if ( !this.filter ) { return str; }
             let reg_exp = new RegExp( this.filter, 'i' )
             let match = str.match( reg_exp );
             if ( !match ) { return str; }
             return `<span>${ str.replace( match[0], `<span style="background-color: #B1422B; color: white; font-weight: 800; padding: 1px; margin-left: 1px; margin-right: 1px;">${ match[0] }</span>` ) }</span>`;
        },
    },
    created() {},
    data() {
        return {
        }
    },
}

</script>

<template>
    <span v-html="enlight_string( item.numero )"/>
</template>

<style>
</style>

