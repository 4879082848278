<script>

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    name: 'log-details',
    props: [ 'item' ],
    components: {
        VueJsonPretty,
    },
    computed : {
    },
    watch: {
    },
    methods : {
    },
    created() {},
    data() {
        return {
        }
    },
}

</script>

<template>
    <b-card>
        <vue-json-pretty :data="item.data" />
    </b-card>
</template>

<style>
</style>

