////////////////////////////////////////

export default {
    cols: [
          { 
              row1: {
                field1: 'customer_id',
                field2: 'denominazione_emittente',
              },
              row2: {
                field1: 'denominazione_cliente',
                field2: 'piva_cliente',
              },
              row3: {
                field1: 'cf_cliente',
                field2: 'tipo_invio',
              },
              row4: {
                field1: 'stato_file',
                field2: 'id_invio',
              },
              row5: {
                field1: 'cliente_fornitore',
                field2: 'numero_fattura',
              },
              row6: {
                field1: 'data_fattura',
                field2: 'imponibile',
              },
              row7: {
                field1: 'imposta',
                field2: 'data_consegna',
              },
              row8: {
                field1: 'tipo_documento',
                field2: 'piva_emittente',
              },
              row9: {
                field1: 'cf_emittente',
                field2: 'id_paese_cedente',
              },
              row10: {
                field1: 'id_paese_cessionario',
                field2: 'presente_in_TS',
              },
              row11: {
                field1: 'cont_corretta',
                field2: 'nota_di_credito',
              },
              row12: {
                field1: 'idsdi_mancante',
                field2: null,
              },
          },
    ]
};

