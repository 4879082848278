//////////////////////////////

module.exports = (type) => {
    var schema = {
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
        key: {
            type: type.STRING(1024),
            allowNull: false,
        },
        value: {
            type: type.STRING(1024),
            allowNull: true,
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue: type.NOW,
        },
        object_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'objects',
                key         : 'id',
                constraints : true,
                store_name  : 'object',
            }
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
    };

    return schema;
}

