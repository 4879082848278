//////////////////////////////

module.exports = (type) => {
    var schema = {
        order_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'orders',
                key         : 'id',
                constraints : true,
                store_name  : 'order',
            }
        },
        ddt_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'ddts',
                key         : 'id',
                constraints : true,
                store_name  : 'ddt',
            }
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        navision: {
            type: type.BOOLEAN,
            defaultValue: false,
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

