////////////////////////////////

import vmm_form   from '@/vmm-form/main';
import vmm_store  from '@/vmm-store/main';
import config     from "@/config";
import models     from './models'
import formconfig from './formconfig.js';

export default function(store) {

    // nomi degli store già caricati dal modulo vmm-store
    let existing_modules = Object.keys( store._modules.root.state );

    // caricamento component vmm-form
    vmm_form.load( formconfig );

    // generazione dei moduli di vuex per le select delle form
    var select_names = vmm_form.load_modules( formconfig, models );
    var select_modules = vmm_store.load_select_modules( select_names, config );
    Object.keys( select_modules ).map( name => {
        if ( !existing_modules.includes( name ) ) {
            store.registerModule( name, select_modules[ name ] );
        }
    });

}

