////////////////////////////////

import module from '@/vmm-auth/module.js'
import config from "@/config";
import router from '@/router';


export default function( store ) {

    store.registerModule( 'authentication', module.get_module( { config, router } ) );

}

