/////////////////////////////////////////////////

import Vue from 'vue';
import form from './form'

//import type_manager  from './types';
import model_manager from './model_manager';
import vmm_form_base from './components/bnvbase.vue';

class vmm_form {

    constructor() {}

    // static public methods ////////////////////

    // aggiunge a Vue il component vmm-form e $formconfig
    static async load( formconfig ) {
        Vue.prototype.$formconfig = formconfig
        Vue.component('vmm-form', form);
    }

    // analizza i models e restituisce l'elenco
    // del moduli di vuex per servire le select (TODO: altri campi delle form)
    static load_modules( formconfig, models ) {
        var res = {};
        Object.keys( models ).map( name => {
            var fields = model_manager( models[ name ], formconfig[ name ], null, {} );
            Object.keys( fields ).map( field_name => {
                if ( fields[ field_name ].element_type === 'select' ) {
                    var model_name = `${ name }_${ field_name }_select`;
                    res[ model_name ] = { model: fields[ field_name ].schema.store_name };
                }
            });
        });
        return res;
    }

    static get vmm_form_base() { return vmm_form_base; }

}

export default vmm_form;

