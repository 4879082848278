<script>

const moment = require('moment-timezone');

export default {
    name: 'ddt-rowsnumber',
    props: [ 'item' ],
    computed: {
        date() {
            if ( !this.item.date_managed ) { return null; }
            var date = moment( this.item.date_managed );
            return [
                date.format('DD/MM/YYYY'),
                date.format('HH:mm:ss'),
            ];
        },
    },
}

</script>

<template>
    <div v-if="date">
        <p style="line-height: 16px; margin-bottom: 2px;"><small style="font-weight: 600;">{{ date[0] }}</small><br/>
        <small>{{ date[1] }}</small></p>
    </div>
    <div v-else-if="!date">
        - - - -
    </div>
</template>

<style>
</style>

