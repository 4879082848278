/////////////////////////////////////////////////

class field_manager {

    constructor( conf ) {
        this._size         = null;
        this._label        = null;
        this._allowNull    = null;
        this._references   = null;
        this._defaultValue = null;
        this._component    = null;
        this._name         = null;
        this._readonly     = null;
        this._placeholder  = null;
        this._maxLength    = null;
        this._minLength    = null;
        this._maxValue     = null;
        this._minValue     = null;
        this._displayLabel = null;
        this._skip         = null;
        this._range        = null;
        this.override( conf );
    }

    // public methods ///////////////////////////

    override( new_conf ) {
        if ( !new_conf ) { return; }
        var names = Object.keys( new_conf );
        for ( var i = 0; i < names.length; i++ ) {
            this[ names[ i ] ] = new_conf[ names[ i ] ];
        }
    }

    // setters //////////////////////////////////

    set settings( value )     { this._settings = value; }
    set label( value )        { this._label = value; }
    set references( value )   { this._references = value; }
    set defaultValue( value ) { this._defaultValue = value; }
    set allowNull( value )    { this._allowNull = value; }
    set size( value )         { this._size = value; }
    set component( value )    { this._component = value; }
    set name( value )         { this._name = value; }
    set readonly( value )     { this._readonly = value; }
    set placeholder( value )  { this._placeholder = value; }
    set edit( value )         { this._edit = value; }
    set maxLength( value )    { this._maxLength = value; }
    set minLength( value )    { this._minLength = value; }
    set maxValue( value )     { this._maxValue = value; }
    set minValue( value )     { this._minValue = value; }
    set displayLabel( value ) { this._displayLabel = value; }
    set skip( value )         { this._skip = value; }
    set range( value )        { this._range = value; }

    // getters //////////////////////////////////

    get size()         { return this._size; }
    get label()        { return this._label || this.name; }
    get allowNull()    { return typeof this._allowNull === 'boolean' ? this._allowNull : false; }
    get model()        { return this._references.model; }
    get key()          { return this._references.key; }
    get type()         { return this._references.type; }
    get param()        { return this._references.param; }       // autocomplete
    get field_value()  { return this._references.field_value; } // autocomplete
    get store_name()   { return this._references.store_name; }
    get defaultValue() { return this._defaultValue; }
    get component()    { return this._component; }
    get name()         { return this._name; }
    //get readonly()     { return typeof this._readonly === 'boolean' ? this._readonly : false; }
    get readonly()     { return this._readonly; }
    get placeholder()  { return this._placeholder; }
    get edit()         { return typeof this._edit === 'boolean' ? this._edit : false; }
    get maxLength()    { return this._maxLength; }
    get minLength()    { return this._minLength; }
    get maxValue()     { return this._maxValue; }
    get minValue()     { return this._minValue; }
    get displayLabel() { return this._displayLabel; }
    //get skip()         { return typeof this._skip === 'boolean' ? this._skip : false; }
    get skip()         { return this._skip; }
    get range()        { return this._range; }

    get has_references() { return this._references ? true : false; }

}

export default field_manager;

