<script>

export default {
    name: 'invoice-approval-modalita-pagamento',
    props: [ 'item' ],
    components : {},
    computed : {
        legend() {
            return {
                MP01: 'Contanti',
                MP02: 'Assegno',
                MP03: 'Assegno circolare',
                MP04: 'Contanti presso Tesoreria',
                MP05: 'Bonifico',
                MP06: 'Vaglia cambiario',
                MP07: 'Bollettino bancario',
                MP08: 'Carta di pagamento',
                MP09: 'RID',
                MP10: 'RID utenze',
                MP11: 'RID veloce',
                MP12: 'RIBA',
                MP13: 'MAV',
                MP14: 'Quietanza erario',
                MP15: 'Giroconto su conti di contabilità speciale',
                MP16: 'Domiciliazione bancaria',
                MP17: 'Domiciliazione postale',
                MP18: 'Bollettino di c/c postale',
                MP19: 'SEPA Direct Debit',
                MP20: 'SEPA Direct Debit CORE',
                MP21: 'SEPA Direct Debit B2B',
                MP22: 'Trattenuta su somme già riscosse',
                MP23: 'PagoPA',
            };
        }
    },
    watch: {},
    methods: {
        get_label( code ) {
            if ( !code || !code.match(/^MP\d\d$/) || !this.legend.hasOwnProperty( code ) ) { return 'unknown'; }
            return this.legend[ code ];
        }
    },
    created() {},
    data() {
        return {
        }
    },
}

</script>

<template>
    <b-badge variant="secondary">{{ get_label( item.modalita_pagamento ) }}</b-badge>
</template>

<style>
</style>

