//////////////////////////////

module.exports = (type) => {

    var schema = {
        created__gte: {
            type: type.DATEONLY,
            allowNull: true,
        },
        created__lte: {
            type: type.DATEONLY,
            allowNull: true,
        },
        action: {
            type: type.STRING(64),
            allowNull: true,
        },
        controller: {
            type: type.STRING(64),
            allowNull: true,
        },
        user_id: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'users',
                key         : 'id',
                store_name  : 'user',
            }
        },
        customer_id: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'customers',
                key         : 'id',
                store_name  : 'customer',
            }
        },
        tags: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'logtags',
                key         : 'label',
                store_name  : 'logtag',
                key_label   : 'label',
            }
        },
    };

    return schema;
}

