<script>

import bnvbase from './bnvbase.vue';
//import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

export default {
    extends: bnvbase,
    props: [ 'store', ],
    components: {
        //VueBootstrapTypeahead
    },
    computed: {
        auto_select() {
            return ( this.config.hasOwnProperty('_references') && this.config._references.hasOwnProperty('auto_select') && this.config._references.auto_select === true ) ? true : false;
        },
        options () {
            return this.$store.state[ this.config.store_name ];
        },
        executed() { return this.options.executed; },
        executed_obj() { return this.options.executed_obj; },
        label_name() {
            if ( !this.creator ) { return null; }
            return this.creator.schema.references.label || null;
        },
        input_class () {
            if ( this.size === 'sm' ) { return 'sm'; }
            if ( this.size === 'lg' ) { return 'lg'; }
            return null;
        },
        min_matching_chars() {
            // verifico se c'è un valore settato nello schema
            return 3;
        },
        prov() {
            if ( this.options && this.options.obj ) { return this.options.obj.provincia; }
            return '-';
        },
        field_value() {
            return this.config.field_value ? this.config.field_value : 'id';
        },
    },
    mounted() {
        if ( this.model ) {
            //this.$store.dispatch( this.config.store_name + '/get_by_id', { id: this.model } );
            //console.log( this.options.obj );
            if ( this.model === this.options.obj[ this.field_value ] ) {
                this.skip = true;
                this.search_text = this.options.obj[ this.field_value ];
                this.provincia = this.prov;
            } else {
                console.log('bnvautocomplete: TODO TODO TODO');
                //this.$store.dispatch( this.config.store_name + '/get_by_id', { id: this.model } );
            }
        }
            //console.log( this.field_value );
    },
    watch: {
        executed( new_value ) {
            if ( new_value === true ) {
                if ( this.options.items && this.options.items.length > 0 ) {
                    this.items = JSON.parse( JSON.stringify( this.options.items ) );
                    if ( this.auto_select === true && this.options.items.length === 1 ) {
                        //console.log( 'item selezionato forzatamente' );
                        this.is_valid = true;
                        this.item_selected( this.options.items[0] )
                        setTimeout( () => { this.is_valid = null; }, 1500 );
                    } else {
                        this.show = true;
                    }
                } else {
                    this.show = false;
                    this.items = [];
                }
            }
        },
        executed_obj( new_value ) {
            if ( new_value === false ) { return; }
            this.skip = true;
            this.search_text = this.options.obj[ this.field_value ];
            this.provincia = this.prov;
            this.model = this.options.obj.id;
        },
        search_text( new_value ) {
            if ( new_value.length === 0 ) { this.model = null; this.$emit('change', { [ this.name ]: null } ); }
            if ( this.skip ) { this.skip = false; this.items = []; this.show = false; return; }
            this.model = null;
            if ( new_value.length < this.min_matching_chars ) { this.items = []; this.show = false; return; }
            if ( this.timeout ) {
                clearTimeout( this.timeout );
            }
            this.timeout = setTimeout( () => {
                this.load_items();
            }, 500);
        },
        //prov( new_value ) { if ( new_value !== '-' ) { this.provincia = new_value; } }
    },
    created () {
        //console.log( this.config );
    },
    methods: {
        item_selected( item ) {
            this.skip        = true;
            this.search_text = item[ this.field_value ];
            this.skip        = false;
            this.model       = item[ this.field_value ];
            this.show        = false;
            this.provincia   = item.provincia;
            this.$store.dispatch(this.config.store_name + '/set_obj', item);
            this.is_valid = true;
            setTimeout( () => { this.is_valid = null; }, 1500 );
            this.value_changed();
        },
        load_items() {
            this.$store.dispatch(this.config.store_name + '/get_all', { params: { label: `|${ this.search_text.trim() }`, type: this.config.param }, force_reload: true })
        },
        serializer( item ) {
            return item[ this.field_value ];
        },
        close_list() {
            setTimeout( () => {
                this.show = false;
            }, 200 );
        },
        set_value: function (value, force = false) {
            if ( !this.model || force === true ) {
                //this.model = value;
                this.$store.dispatch( 'comune_residenza/get_by_name', value );
                if ( !this.model ) {
                    this.search_text = '';
                    this.provincia = '-';
                }
            }
        },
    },
    data() {
        return {
            search_text : '',
            timeout     : null,
            show        : false,
            items       : [],
            skip        : false, // serve ad evitare che parta una richiesta dopo aver selezionato un item
            provincia   : '-',
            is_valid    : null,
        };
    },
};

</script>

<template>

    <div class="form-group autocomplete" :class="field_class" :style="field_style">
        <label :for="'bnv-form-' + name" :class="label_class">{{ get_label() }}</label>
        <div :class="element_container_class">
            <b-input-group>
                <b-input
                    autocomplete      = "off"
                    v-on:keyup.enter  = "enter_key"
                    ref               = "input"
                    type              = "text"
                    v-bind:class      = "{ 'is-invalid': error_component, 'is-valid': is_valid }"
                    :placeholder      = "get_placeholder()"
                    :aria-label       = "name"
                    :aria-describedby = "name"
                    :id               = "'bnv-form-' + name"
                    v-model           = "search_text"
                    :disabled         = "disabled"
                    :readonly         = "_readonly"
                    @blur             = "close_list"
                />
            </b-input-group>
            <div v-if="show" class="autocomplete-results shadow">
                <b-list-group>
                    <b-list-group-item
                        href   = "#"
                        v-for  = "item in items"
                        :key   = "name + '_' + item.id"
                        style  = "padding: 6px 10px; border: 0"
                        @click = "item_selected( item )"
                        >
                        {{ item[ field_value ] }}
                    </b-list-group-item>
                </b-list-group>
            </div>
            <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted" v-html="_helper"></small>
            <input v-if="_readonly" type="hidden" v-model="model" />
            <div class="invalid-feedback">
                {{ get_error_message() }}
            </div>
            <div v-if="confirm_message" class="valid-feedback">
                {{ confirm_message }}
            </div>
        </div>
    </div>

</template>

<style>
    .autocomplete {
        position: relative;
    }
    .autocomplete-results {
      position: absolute;
      width: 100%;
      padding: 0;
      margin: 0;
      border: 1px solid #eeeeee;
      max-height: 22em;    
      overflow: auto;
      z-index: 50;
    }
</style>
