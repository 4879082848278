<script>

//import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    name: 'component-name',
    props: [ 'value' ],
    components : {},
    computed : {
//      ...mapState('store', {
//          localattribute : state => state.attribute,
//      }),
//      ...mapGetters( 'store', [ 'attribute' ] ),
    },
    watch: {},
    methods : {
//      ...mapActions( 'store', { local_action: 'action' } ),
        update_types( type ) {
            this.types[ type ] = !this.types[ type ];
            this.emit_update();
        },
        emit_update() {
            let filters = [];
            Object.keys( this.types ).map( x => { if ( this.types[ x ] === true ) { filters.push( x ) } } );
            if ( filters.length === Object.keys( this.types ).length ) {
                this.bkup = JSON.parse( JSON.stringify( this.types ) );
                this.$emit( 'update', null );
            } else if ( filters.length === 0 ) {
                this.types = this.bkup;
                this.bkup = JSON.parse( JSON.stringify( this.types ) );
            } else {
                this.bkup = JSON.parse( JSON.stringify( this.types ) );
                this.$emit( 'update', filters.join(',') );
            }

        },
        right_click( type ) {
            Object.keys( this.types ).map( x => {
                if ( x === type ) { this.types[ x ] = true; }
                else { this.types[ x ] = false; }
            });
            this.emit_update();
        },
    },
    created() {
        if ( this.value ) {
            Object.keys( this.types ).map( x => { this.types[ x ] = false; } );
            this.value.split(',')
                .map( x => { this.types[ x ] = true; } );
        }
    },
    data() {
        return {
            bkup : {},
            types: {
                info    : true,
                warning : true,
                error   : true,
            }
        }
    },
}

</script>

<template>
    <div>
        <b-button-group size="sm">
            <b-button :variant="types.info ? 'info' : 'secondary'" @click="update_types('info')" @contextmenu.prevent="right_click('info')">info</b-button>
            <b-button :variant="types.warning ? 'warning' : 'secondary'" @click="update_types('warning')" @contextmenu.prevent="right_click('warning')">warning</b-button>
            <b-button :variant="types.error ? 'danger' : 'secondary'" @click="update_types('error')" @contextmenu.prevent="right_click('error')">error</b-button>
        </b-button-group>
    </div>
</template>

<style>
</style>
