<script>

const moment = require('moment');
import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    computed: {
        _model() {
            if ( this.model ) {
                return moment( this.model ).format('YYYY-MM-DD');
            }
            return this.model;
        },
    },
    methods: {
        _return_model() {
            return this._model ? this._model : null;
        },
        value_changed() {
            console.log( this.model );
            let regex = new RegExp('^20\\d\\d\\-\\d\\d\\-\\d\\d$');
            if ( !regex.test( this.model ) ) { return; }
            var payload = {};
            payload[ this.name ] = this.model;
            this.$emit('change', payload );
        },
    },
};

</script>

<template>

    <div class="form-group" :class="field_class" :style="field_style">
        <label :for="'bnv-form-' + name" :class="label_class">{{ get_label() }}</label>
        <div :class="element_container_class">
            <input
                ref="input"
                type="date"
                class="form-control"
                :size="input_class"
                v-bind:class="{ 'is-invalid': error_component }"
                :aria-label="name"
                :aria-describedby="name"
                :id="'bnv-form-' + name"
                v-model="model"
                :disabled="disabled"
                :readonly="_readonly"
                @change="value_changed"
            />
            <div class="invalid-feedback">
                {{ get_error_message() }}
            </div>
        </div>
    </div>

</template>



