<script>

import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    methods: {
        _validate: function() {
            try {
                this._validate_allow_null();
                this._validate_max_length();
                this._validate_min_length();
            } catch ( error ) {
                return this._set_error( error );
            }
            return true;
        },
        _validate_max_length() {
            if ( !this.maxlength ) { return; }
            if ( this.model.length > this.maxlength ) {
                throw `lunghezza massima ${this.maxlength} caratteri`;
            }
        },
        _validate_min_length() {
            if ( !this.minlength ) { return; }
            if ( this.model.length < this.minlength ) {
                throw `lunghezza minima ${this.minlength} caratteri`;
            }
        }
    },
    computed: {
        _model: {
            get : function() {
                if ( this.search ) {
                    if ( this.search === 'contains_before_after' ) {
                        var res = this.model.replace(/^\|/, '')
                                            .replace(/\|$/, '');
                        return res;
                    }
                    return res;
                }
                return this.model;
            },
            set : function (value) {
                this.model = value;
            }
        },
    }
};

</script>

<template>

    <div class="form-group" :class="field_class" :style="field_style">
        <label v-if="displayLabel" :for="'bnv-form-' + name" :class="label_class">{{ get_label() }}</label>
        <div :class="element_container_class">
            <b-input
                v-on:keyup.enter="enter_key"
                ref="input"
                type="text"
                :size="input_class"
                v-bind:class="{ 'is-invalid': error_component }"
                :placeholder="get_placeholder()"
                :aria-label="name"
                :aria-describedby="name"
                :id="'bnv-form-' + name"
                v-model="_model"
                :disabled="disabled"
                :readonly="_readonly"
            />
            <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted" v-html="_helper"></small>
            <div class="invalid-feedback">
                {{ get_error_message() }}
            </div>
        </div>
    </div>

</template>



