//////////////////////////////

module.exports = (type) => {

    var schema = {
        type: {
            type: type.STRING(32),
            allowNull: false,
        },
        tags: {
            type: type.JSONB,
            defaultValue: [],
        },
        controller: {
            type: type.STRING(64),
            defaultValue: null,
        },
        action: {
            type: type.STRING(64),
            defaultValue: null,
        },
        customer_id: { // application_customer
            type: type.INTEGER,
            defaultValue: null,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        user_id: {
            type: type.INTEGER,
            defaultValue: null,
            references: {
                model       : 'users',
                key         : 'id',
                constraints : true,
                store_name  : 'user',
            }
        },
        message: {
            type: type.TEXT,
            allowNull: false,
        },
        data: {
            type: type.JSONB,
            defaultValue: {},
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue : type.NOW,
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

