
<script>

import listpage from '../listpage';
import { mapActions } from 'vuex';

export default {
    name: 'order_listpage',
    extends: listpage,
    components: {},
    props: [],
    computed: {
        override_options() {
            var options = {
                initialFilter : {
                    completed : this.check_completed,
                    locked : this.check_locked,
                },
            };
            return options;
        },
        params() { return this.store.params; },
    },
    watch: {
        search( new_value ) {
            //console.log(`valore cambiato: "${ new_value }"`);
            if ( new_value === '' ) {
                this.$store.dispatch( 'order/remove_filter', 'ordine_numero' );
            } else if ( new_value.length < 3 ) {
                return;
            } else {
                this.$store.dispatch( 'order/add_filter', { ordine_numero: `|${ new_value }|` } );
            }
            this.$store.dispatch( 'order/force_reload' );
            this.list_key += 1;
        },
        check_completed( new_value ) {
            if ( new_value === true ) {
                this.check_locked = false;
                this.add_filter( { completed : true } );
                this.remove_filter('locked');
            } else {
                this.remove_filter('completed');
            }
            this.$store.dispatch( 'order/force_reload' );
            this.list_key += 1;
        },
        check_locked( new_value ) {
            if ( new_value === true ) {
                this.check_completed = false;
                this.add_filter( { locked : true } );
                this.remove_filter('completed');
            } else {
                this.add_filter( { locked : 'false' } );
            }
            this.$store.dispatch( 'order/force_reload' );
            this.list_key += 1;
        },
    },
    methods: {
        ...mapActions( 'ddt', { set_params_ddt: 'set_params' } ),
        ...mapActions( 'order', { set_params_order: 'set_params', add_filter: 'add_filter', remove_filter: 'remove_filter' } ),
        left_click( item ) {
            this.set_params_ddt( { force_reload: true } );
            this.$router.push( '/list/ddt/' + item.id );
        },
        clear_search() {
            this.search = '';
        },
    },
    created() {
        if ( this.params.hasOwnProperty('completed') ) { this.check_completed = this.params.completed; }
        if ( this.params.hasOwnProperty('locked') ) { this.check_locked = this.params.locked; }
        if ( this.params.hasOwnProperty('ordine_numero') ) { this.search = this.params.ordine_numero.replace(/\|/g, ''); }
    },
    data() {
        return {
            list_key         : 0,
            search           : '',
            check_completed  : false,
            check_locked     : false,
        };
    }
}

</script>

<template>

    <div style="width: 80%; margin: auto">

        <b-row align-v="center" style="margin-bottom: 30px">
            <b-col class="text-left">
                <b-col>
                    <b-form-checkbox v-model="check_completed" name="check-completed" switch>
                        mostra completi
                    </b-form-checkbox>
                    <b-form-checkbox v-model="check_locked" name="check-completed" switch>
                        mostra bloccati
                    </b-form-checkbox>
                </b-col>
            </b-col>
            <b-col>
                <h2 style="text-align: center;">ORDINI</h2>
            </b-col>
            <b-col>
                <b-input-group class="" size="sm">
                    <b-form-input
                        v-model     = "search"
                        placeholder = "numero di ordine"
                        debounce    = "600"
                    />
                    <b-input-group-append>
                        <b-button variant="outline-secondary" @click="clear_search()" :disabled="search === ''"><icon name="times"/></b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>

        <div style="">
            <vmm-list
                :key             = "list_key"
                ref              = "listbnv"
                :model           = "model"
                :options         = "override_options"
    
                :skip            = "false"
                :destroy         = "destroy"
                @left-click      = "left_click"
            />
        </div>

    </div>

</template>

<style>
    #filter-icon path {
        color: #DC3545;
    }
</style>
