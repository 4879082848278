//////////////////////////////

module.exports = function( type ) {

    var schema = {
        ['piva_cliente,cf_cliente']: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'invoice_consistency_piva',
                key         : 'id',
                constraints : true,
                store_name  : 'invoice_consistency_pivacf_cliente_autocomplete',
                type        : 'autocomplete',
                auto_select : true,
                param       : 'cliente',
                field_value : 'label', // il campo della tabella che stabilisce il valore assunto dal campo autocomplete (def: id)
            },
        },
        ['piva_emittente,cf_emittente']: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'invoice_consistency_piva',
                key         : 'id',
                constraints : true,
                store_name  : 'invoice_consistency_pivacf_emittente_autocomplete',
                type        : 'autocomplete',
                auto_select : true,
                param       : 'emittente',
                field_value : 'label', // il campo della tabella che stabilisce il valore assunto dal campo autocomplete (def: id)
            },
        },
        piva_cliente: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'invoice_consistency_piva',
                key         : 'id',
                constraints : true,
                store_name  : 'invoice_consistency_piva_cliente_autocomplete',
                type        : 'autocomplete',
                auto_select : true,
                param       : 'cliente',
                field_value : 'label', // il campo della tabella che stabilisce il valore assunto dal campo autocomplete (def: id)
            },
        },
        piva_emittente: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'invoice_consistency_piva',
                key         : 'id',
                constraints : true,
                store_name  : 'invoice_consistency_piva_emittente_autocomplete',
                type        : 'autocomplete',
                auto_select : true,
                param       : 'emittente',
                field_value : 'label', // il campo della tabella che stabilisce il valore assunto dal campo autocomplete (def: id)
            },
        },
        cf_cliente: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'invoice_consistency_cf',
                key         : 'id',
                constraints : true,
                store_name  : 'invoice_consistency_cf_cliente_autocomplete',
                type        : 'autocomplete',
                auto_select : true,
                param       : 'cliente',
                field_value : 'label', // il campo della tabella che stabilisce il valore assunto dal campo autocomplete (def: id)
            },
        },
        cf_emittente: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'invoice_consistency_cf',
                key         : 'id',
                constraints : true,
                store_name  : 'invoice_consistency_cf_emittente_autocomplete',
                type        : 'autocomplete',
                auto_select : true,
                param       : 'emittente',
                field_value : 'label', // il campo della tabella che stabilisce il valore assunto dal campo autocomplete (def: id)
            },
        },
        tipo_documento: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'invoice_consistency_doc_types',
                key         : 'id',
                constraints : true,
                store_name  : 'invoice_consistency_doc_type',
            }
        },
        id_paese_cedente: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'invoice_consistency_countries',
                key         : 'id',
                constraints : true,
                store_name  : 'invoice_consistency_country',
            }
        },
        id_paese_cessionario: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'invoice_consistency_countries',
                key         : 'id',
                constraints : true,
                store_name  : 'invoice_consistency_country',
            }
        },
        data_fattura_da: {
            type: type.DATEONLY,
            allowNull: true,
        },
        data_fattura_a: {
            type: type.DATEONLY,
            allowNull: true,
        },
        data_consegna_da: {
            type: type.DATEONLY,
            allowNull: true,
        },
        data_consegna_a: {
            type: type.DATEONLY,
            allowNull: true,
        },
        presente_in_TS: {
            type: type.BOOLEAN,
            allowNull: true,
        },
        cont_corretta: {
            type: type.BOOLEAN,
            allowNull: true,
        },
        nota_di_credito: {
            type: type.BOOLEAN,
            allowNull: true,
        },
        idsdi_mancante: {
            type: type.BOOLEAN,
            allowNull: true,
        },
    };

    return schema;
}

