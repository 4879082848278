//////////////////////////////

module.exports = function( type ) {

    var schema = {

        date: {
            type: type.DATEONLY,
            allowNull: true,
        },
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        automation_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'automations',
                key         : 'id',
                constraints : true,
                store_name  : 'automation',
            }
        },
        has_error: {
            type: type.BOOLEAN,
            defaultValue: false,
        },
        error: {
            type: type.STRING(256),
            allowNull: true,
        },
        error_managed: {
            type: type.BOOLEAN,
            defaultValue: true,
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue : type.NOW,
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

