//////////////////////////////

module.exports = (type) => {

    /* **
     * X Silvio:
     * ho mantenuto il nome italiano dei campi poiché l'ho ereditato dall'automazione originale di Tommaso e Marco
     * ed ho immaginato che nel tempo, ci avrebbe dato dei grattacapi il non avere corrispondenza immediata tra i campi passati e quelli ineriti
     */

    var schema = {
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        ordine_numero: {
            type: type.STRING(32),
            allowNull: false,
        },
        ordine_data: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null
        },
        ordine_relativo_numero: {
            type: type.STRING(32),
            allowNull: true,
        },
        ordine_relativo_data: {
            type: type.DATEONLY,
            allowNull: true,
            defaultValue: null
        },
        completed: {
            type: type.BOOLEAN,
            defaultValue: false
        },
        locked: {
            type: type.BOOLEAN,
            defaultValue: false
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue : type.NOW,
        },
    };

    return schema;
}

