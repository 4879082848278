//////////////////////////////

module.exports = function( type ) {

    var schema = {

        stat_label: {
            type: type.STRING(64),
            allowNull: false,
        },
        last_log_id: { 
            type: type.INTEGER,
            allowNull: false,
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue : type.NOW,
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

