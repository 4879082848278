<script>

import { mapState } from 'vuex';

export default {
    name: 'invoice-approval-checkbox',
    props: [ 'item' ],
    components : {},
    computed : {
        ...mapState('invoice_approval', {
            check_all : state => state.check_all,
        }),
    },
    watch: {
        checked( value ) {
            let action;
            if ( value ) { action = 'add'; }
            else { action = 'remove'; }
            this.$emit( 'genericevent', { event_name: 'checkbox', action, id: this.item.id } );
        }
    },
    methods: {
        is_selected( id ) {
            return this.$store.getters['invoice_approval/is_selected']( id );
        }
    },
    created() {
        if ( this.is_selected( this.item.id ) ) { this.checked = true; }
    },
    data() {
        return {
            checked : false,
        }
    },
}

</script>

<template>
    <div>
        <b-form-checkbox
            :id       = "'checkbox-' + item.id"
            v-model   = "checked"
            :name     = "'checkbox-' + item.id"
            :value    = "true"
            :disabled = "check_all"
        />
    </div>
</template>

<style>
</style>

