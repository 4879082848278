////////////////////////////////////////

//import axios from "axios";

import base_module from '@/vmm-store/modules/base';
//import vmm_store from '../vmm-store';

//const base_service = vmm_store.service;

const options = {
    model_name    : 'order',
    default_order : 'id ASC',
};

const state = {
};

const actions = {
};

const mutations = {
};

const getters = {};

class new_module extends base_module {

    constructor ( new_options = {} ) {
        Object.keys( new_options ).map( x => {
            options[ x ] = new_options[ x ];
        });
        super( options );
    }

    static get_module( new_options = {} ) {
        var obj = new new_module( new_options );
        return {
            namespaced: true,
            state: {
                ...obj.state,
                ...state,
            },
            getters: {
                ...obj.getters,
                ...getters,
            },
            actions: {
                ...obj.actions,
                ...actions,
            },
            mutations: {
                ...obj.mutations,
                ...mutations,
            },
        }
    }

}

export default new_module;

