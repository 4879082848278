
export default {
    cols: [
          { 
              row1: {
                field1: 'range',
                field2: 'automation_id',
              },
          },
    ]
};

