<script>

import fab from 'vue-fab'

export default {
    name: 'fab-button',
    props: [ 'main_icon', 'bg_color', ],
    components: { fab },
    computed: {
        _main_icon() {
            return this.main_icon ? this.main_icon : this.default_main_icon;
        },
        _bg_color() {
            return this.bg_color ? this.bg_color : this.default_bg_color;
        },
    },
    watch: {},
    methods: {
        save() {
            this.$emit( 'save', null )
        },
        reset() {
            this.$emit( 'reset', null )
        },
        export_search() {
            this.$emit( 'export', null )
        },
        test( value = 'default' ) {
            console.log( value );
        }
    },
    created() {},
    data() {
        return {
            default_main_icon : 'save',
            default_bg_color  : '#218838',
            actions           : [
//              { name: 'export_search', icon: 'download', color: 'grey' },
//              { name: 'reset', icon: 'cancel', color: 'red' },
//              { name: 'save', icon: 'filter_list', },
            ],
        }
    },
}

</script>

<template>

    <div>
        <!-- https://github.com/PygmySlowLoris/vue-fab -->
        <div @click="save">
            <fab :actions="actions" :bg-color="_bg_color" main-icon="search" @save="save" @reset="reset" @export_search="export_search" icon-size="small" v-b-tooltip.hoveri.left title="Cerca"/>
        </div>
        <div @click="reset">
            <fab :actions="actions" bg-color="#DC3545" main-icon="cancel" @save="save" @reset="reset" @export_search="export_search" icon-size="small" style="bottom: 11vh;" v-b-tooltip.hoveri.left title="Cancella ricerca"/>
        </div>
        <div @click="export_search">
            <fab :actions="actions" bg-color="#6C757D" main-icon="download" @save="save" @reset="reset" @export_search="export_search" icon-size="small" style="bottom: 18vh;" v-b-tooltip.hoveri.left title="Scarica CSV"/>
        </div>
    </div>

</template>

<style>
</style>

