
<script>

import { mapActions, mapState } from 'vuex';

import listpage from '../listpage';
import filters from './filters.vue';

export default {
    name: 'inv_app_operator',
    extends: listpage,
    components: { filters },
    props: [],
    computed: {
        ...mapState('invoice_approval', {
            selected_ids : state => state.selected_ids,
        }),
        has_selected_ids() {
            return this.selected_ids.length > 0;
        },
        check_all() {
            return this.$store.state['invoice_approval'].check_all;
        },
        params() {
            return this.$store.state['invoice_approval'].params;
        },
        range() {
            return this.$store.state['invoice_approval'].range;
        },
    },
    watch: {},
    methods: {
        ...mapActions( 'invoice_approval', {
            set_param       : 'add_param',
            force_reload    : 'force_reload',
            add_selected    : 'add_selected',
            remove_selected : 'remove_selected',
            update          : 'update',
            update_all      : 'update_all',
        }),
        manage_checkbox( action, id ) {
            if ( action === 'add' ) { this.add_selected( id ); }
            else if ( action === 'remove' ) { this.remove_selected( id ); }
        },
        manage_detail( action, id ) {
            let state_label;
            if ( action === 'refuse' ) {
                state_label = 'rifiutata'
            } else if ( action === 'confirm' ) {
                state_label = 'pre-approvata'
            } else if ( action === 'paid' ) {
                state_label = 'pagata'
            } else if ( action === 'note' ) {
                state_label = 'note'
            }
            if ( state_label === 'rifiutata' )  {
                this.mr_ids.push( id );
                this.toggle_modal();
            } else if ( state_label === 'note' ) {
                this.mr_ids.push( id );
                this.toggle_modal('modal-note');
            } else {
                this.update({
                    id      : id,
                    payload : { state_label },
                    include : 'invoice_approval_state,invoice_approval_line',
                });
            }
        },
        refuse_all() {
            this.mr_ids = this.selected_ids;
            this.toggle_modal();
        },
        approve_all() {
            this.update_all({
                payload : { state_label: 'pre-approvata', ids: this.selected_ids },
                include : 'invoice_approval_state,invoice_approval_line',
            });
        },
        toggle_modal(modal_id = 'modal-refuse') {
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        reset_modal_refuse() {
            this.mr_comment = '';
            this.mr_ids     = [];
        },
        reset_modal_note() {
            this.generic_note = '';
            this.mr_ids       = [];
        },
        send_refuse() {
            //console.log( this.mr_comment );
            //console.log( this.mr_ids );
            this.update_all({
                payload : { state_label: 'rifiutata', ids: this.mr_ids, comment: this.mr_comment },
                include : 'invoice_approval_state,invoice_approval_line',
            });
            this.toggle_modal();
        },
        send_note() {
            //console.log( this.generic_note );
            //console.log( this.mr_ids );
            this.update_all({
                payload : { state_label: 'note', ids: this.mr_ids, comment: this.generic_note },
                include : 'invoice_approval_state,invoice_approval_line',
            });
            this.toggle_modal('modal-note');
        },
        reload() {
            this.force_reload();
            this.list_key += 1;
        },
    },
    created() {
        this.set_param( { invoice_approval_state_id: 1 } );
        this.set_param( { modalita_pagamento: 'MP12' } );
        this.start = true;
    },
    data() {
        return {
            start        : false,
            list_key     : 0,
            search       : '',
            expandall    : false,
            mr_comment   : '',
            mr_ids       : [],
            generic_note : '',
        };
    }
}

</script>

<template>

    <b-row v-if="start === true">

        <b-col cols="2" style="padding: 0; margin: 0;">

            <filters
                :store_name     = "model"
                @reload         = "reload"
                :default_values = "params"
            />
            <div class="text-center">
                <b-button variant="danger" size="sm" style="margin-right: 10px; margin-top: 10px" @click="refuse_all" :disabled="false" v-if="has_selected_ids">
                    Rifiuta
                </b-button>
                <b-button variant="info" size="sm" style="margin-top: 10px"  @click="approve_all" :disabled="false" v-if="has_selected_ids">
                    Pre-approva
                </b-button>
            </div>

        </b-col>

        <b-col cols="10" style="padding-left: 30px; padding-right: 50px; padding-bottom: 20px;">

            <h2 style="text-align: center; margin-bottom: 30px">FATTURE DA APPROVARE</h2>

            <div>
                <vmm-list
                    ref       = "listbnv"
                    :key      = "list_key"
                    :model    = "model"
                    :options  = "override_options"
        
                    :skip     = "false"
                    :destroy  = "destroy"
                    @checkbox = "manage_checkbox"
                    @details  = "manage_detail"
                />
            </div>
    
            <!-- modal nota per refuse -->
            <b-modal
                id          = "modal-refuse"
                title       = "Commenta il documento"
                button-size = "sm"
                @hide       = "reset_modal_refuse"
                >
                <p>Stai per mettere in stato <b-badge size="sm" variant="danger">rifiutata</b-badge> {{ mr_ids.length === 1 ? 'una' : mr_ids.length }} {{ mr_ids.length > 1 ? 'fatture' : 'fattura' }}.<br/>Inserisci un commento per spiegare la motivazione.</p>
                <b-form-textarea
                    id          = "textarea-small"
                    size        = "sm"
                    placeholder = "commenta il motivo del rifiuto"
                    rows        = "5"
                    v-model     = "mr_comment"
                ></b-form-textarea>
                <template v-slot:modal-footer="{ cancel }">
                    <b-row><b-col>
                        <b-button size="sm" variant="outline-secondary" @click="cancel()" style="margin-right: 10px">
                            chiudi
                        </b-button>
                        <b-button size="sm" variant="success" @click="send_refuse()" :disabled="mr_comment.length < 3">
                            ok
                        </b-button>
                    </b-col></b-row>
                </template>
            </b-modal>
    
            <!-- modal note -->
            <b-modal
                id          = "modal-note"
                title       = "Inserisci una nota"
                button-size = "sm"
                @hide       = "reset_modal_note"
                >
                <b-form-textarea
                    id          = "textarea-small"
                    size        = "sm"
                    placeholder = "inserisci una nota"
                    rows        = "5"
                    v-model     = "generic_note"
                ></b-form-textarea>
                <template v-slot:modal-footer="{ cancel }">
                    <b-row><b-col>
                        <b-button size="sm" variant="outline-secondary" @click="cancel()" style="margin-right: 10px">
                            chiudi
                        </b-button>
                        <b-button size="sm" variant="success" @click="send_note()" :disabled="generic_note.length < 3">
                            ok
                        </b-button>
                    </b-col></b-row>
                </template>
            </b-modal>

        </b-col>

    </b-row>

</template>

