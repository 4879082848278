////////////////////////////////////////

import axios from "axios";

import base_module from '@/vmm-store/modules/base';
//import vmm_store from '../vmm-store';

//const base_service = vmm_store.service;

const options = {
    model_name    : 'invoice_approval',
    default_order : 'data DESC',
};

const state = {
    params       : {},
    check_all    : false,
    selected_ids : [],
    range        : null,
};

const actions = {
    update({ commit, getters, state }, data) {
        commit('start_request');
        var params   = JSON.parse( JSON.stringify( state.params ) );
        params.order = getters.order;
        let url = `${ getters.backend_url }/model/${ getters.model_name }/${ data.id }?`;
        Object.keys( params ).map( key => { url += `&${ key }=${ params[ key ] }` });
        axios.patch( url, data.payload )
            .then( res => {
                let response = res.data;
                commit( 'reset_selected_ids' );
                if ( params.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    update_all({ commit, getters, state }, data) {
        commit('start_request');
        var params   = JSON.parse( JSON.stringify( state.params ) );
        params.order = getters.order;
        let url = `${ getters.backend_url }/model/${ getters.model_name }/updateall?`;
        Object.keys( params ).map( key => { url += `&${ key }=${ params[ key ] }` });
        axios.patch( url, data.payload )
            .then( res => {
                let response = res.data;
                commit( 'reset_selected_ids' );
                if ( params.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    set_check_all( { commit }, value ) {
        commit( 'set_check_all', value );
    },
    set_state( { commit }, value ) {
        commit( 'set_state', value );
    },
    set_range( { commit }, value ) {
        commit( 'set_range', value );
    },
    add_selected( { commit }, id ) {
        commit( 'add_selected', id );
    },
    remove_selected( { commit }, id ) {
        commit( 'remove_selected', id );
    },
    reset_selected_ids( { commit } ) {
        commit( 'reset_selected_ids' );
    },
};

const mutations = {
    set_check_all( state, value ) {
        state.check_all = value;
        state.selected_ids = [];
    },
    set_state( state, value ) {
        state.state = value;
        state.selected_ids = [];
    },
    set_range( state, value ) {
        state.range = value;
        state.selected_ids = [];
    },
    add_selected( state, id ) {
        if ( !state.selected_ids.includes( id ) ) {
            state.selected_ids.push( id );
        }
    },
    remove_selected( state, id ) {
        let index = state.selected_ids.indexOf( id );
        if ( index > -1 ) {
            state.selected_ids.splice(index, 1);
        }
        console.log( 'selected_ids', state.selected_ids );
    },
    reset_selected_ids( state ) {
        state.selected_ids = [];
    },
    clear( state ) {
        state.obj           = {};
        state.items         = null;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = false;
        state.force_reload  = false;
        state.selected      = false;
        state.filters       = {};
        state.params        = {};
        state.selected_ids  = [];
   },
};

const getters = {
    is_selected: ( state ) => ( id ) => {
        return state.selected_ids.includes( id );
    },
};

class new_module extends base_module {

    constructor ( new_options = {} ) {
        Object.keys( new_options ).map( x => {
            options[ x ] = new_options[ x ];
        });
        super( options );
    }

    static get_module( new_options = {} ) {
        var obj = new new_module( new_options );
        return {
            namespaced: true,
            state: {
                ...obj.state,
                ...state,
            },
            getters: {
                ...obj.getters,
                ...getters,
            },
            actions: {
                ...obj.actions,
                ...actions,
            },
            mutations: {
                ...obj.mutations,
                ...mutations,
            },
        }
    }

}

export default new_module;

