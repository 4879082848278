<script>

export default {
    name: 'invoice-approval-state',
    props: [ 'item' ],
    components : {},
    computed : {
        variant() {
            try {
                return this.item.invoice_approval_state.config.variant;
            } catch ( error ) {
                return 'secondary';
            }
        },
        label() {
            try {
                return this.item.invoice_approval_state.label;
            } catch ( error ) {
                return 'no-name';
            }
        },
    },
    watch: {},
    methods: {},
    created() {},
    data() {
        return {
        }
    },
}

</script>

<template>
    <b-badge :variant="variant">{{ label }}</b-badge>
</template>

<style>
</style>

