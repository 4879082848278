//////////////////////////////////////////////////////////

// non so perché ho copiato questa class dal backend, NON CREDO SERVA

class ModulesSelector {

    constructor ( input, models ) { this.input = input; this.models = models }

    check( model_name ) {
        if ( this.is_nothing ) { return false }
        if ( this.is_all ) { return true }
        if ( this.to_in.length === 0 ) { return !this.to_ex.includes( model_name ) }
        if ( this.to_ex.length === 0 ) { return this.to_in.includes( model_name ) }
        throw `il valore "${ this.input }" non è valido per il parametro include`;
    }

    static build ( input ) {
        var res = { modules_to_include: [], modules_to_exclude: [] };
        if ( input === 'true' ) { res.modules_to_include.push('*') }
        else if ( input === 'false' || input.length === 0 ) {
            // niente da fare
        } else {
            var models = input
            models.map( item => {
                var model = item.trim()
                if ( model.startsWith('!') ) { res.modules_to_exclude.push( model.replace(/^!/, '') ) }
                else { res.modules_to_include.push( model ) }
            });
        }
        return new ModulesSelector( input, res )
    }

    get to_in () { return this.models.modules_to_include }
    get to_ex () { return this.models.modules_to_exclude }
    get is_nothing () { return this.to_in.length === 0 && this.to_ex.length === 0 }
    get is_all () { return this.to_ex.length === 0 && this.to_in.length === 1 && this.to_in[0] === '*' }

}

export default ModulesSelector;

