<script>

import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    methods: {
        _validate: function() {
            try {
                this._validate_allow_null();
                if ( this.get_step() === 'any' ) {
                    this._validate_integer();
                } else {
                    this._validate_decimal();
                }
                this._validate_max_value();
                this._validate_min_value();
            } catch ( error ) {
                return this._set_error( error );
            }
            return true;
        },
        _return_model() { // sovrascrivo fa finz del parent per accettare i valori 0
            if ( this.model === null || this.model === '' || typeof this.model === 'undefined' ) { return null; }
            return this.model;
        },
        _validate_integer() {
            if ( this.model && String( this.model ) !== String( parseInt( this.model ) ) ) { // qui controllo ancora se il valore è presente, da rivedere
                throw 'il valore non sembra essere un intero valido';
            }
        },
        _validate_max_value() {
            if ( !this.maxvalue ) { return; }
            if ( this.model > this.maxvalue ) {
                throw `il valore massimo è ${ this.maxvalue }`;
            }
        },
        _validate_min_value() {
            if ( !this.minvalue ) { return; }
            if ( this.model < this.minvalue ) {
                this.error_component = true;
                throw `il valore minimo è ${ this.minvalue }`;
            }
        },
        _validate_decimal() {
            if ( isNaN( parseFloat( this.model ) ) === true ) { // qui controllo ancora se il valore è presente, da rivedere
                throw 'il valore non sembra essere un numero decimale valido';
            }
            this.model = parseFloat( this.model ).toFixed( 2 );
        },
        get_step() {
            if ( !this.decimal ) { return 'any'; }
            if ( this.decimal === 1 ) { return '.1'; }
            else if ( this.decimal === 2 ) { return '.01'; }
            return 'any';
        }
    },
};

</script>

<template>

    <div class="form-group" :class="field_class" :style="field_style">
        <label v-if="displayLabel" :for="'bnv-form-' + name" :class="label_class">{{ get_label() }}</label>
        <div :class="element_container_class">
            <b-input
                v-on:keyup.enter  = "enter_key"
                ref               = "input"
                type              = "number"
                class             = "form-control"
                :size             = "input_class"
                v-bind:class      = "{ 'is-invalid': error_component }"
                :placeholder      = "get_placeholder()"
                :aria-label       = "name"
                :aria-describedby = "name"
                :id               = "'bnv-form-' + name"
                v-model           = "model"
                :step             = "get_step()"
                :disabled         = "disabled"
                :readonly         = "_readonly"
            />
            <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted">{{ helper }}</small>
            <div class="invalid-feedback">
                {{ get_error_message() }}
            </div>
        </div>
    </div>

</template>




