<script>

import { mapActions, mapState } from 'vuex';
import moment from 'moment-timezone';

export default {
    name: 'ddt-details',
    props: [ 'item' ],
    components : {},
    computed : {
        ...mapState('ddt_item', {
            ddt_item_executed : state => state.executed,
            ddt_item_obj      : state => state.obj,
        }),
        ...mapState('ddt', {
            ddt_executed : state => state.executed,
            params       : state => state.params,
        }),
        items() {
            return this.item.ddt_items;
        },
        order_status() {
            var status = new Map();
            this.item.ddtorder.map( x => {
                status.set( x.id, {
                    locked : x.locked,
                    numero : x.ordine_numero,
                });
            });
            return status;
        },
        blocks() {
            var res = {};
            Array.from( this.order_status.keys() ).map( x => {
                var order = this.order_status.get( x );
                res[ x ] = { locked: order.locked, numero: order.numero, order_id: x, items: [] };
            });
            this.items.map( x => {
                if ( res[ x.ordine_id ] ) {
                    res[ x.ordine_id ].items.push( x );
                }
            });
            return Object.values( res );
        },
        filter() {
            if ( typeof this.params === 'object' && this.params.hasOwnProperty('numero') ) {
                return this.params.numero.replace(/\|/g, '')
            }
            return null;
        }
    },
    watch: {
        ddt_item_executed( new_value ) {
            if ( !new_value ) { return; }
            this.start_update( this.ddt_item_obj );
        },
        ddt_executed( new_value ) {
            if ( !new_value ) { return; }
        },
    },
    methods : {
        ...mapActions( 'ddt_item', { update_ddt_item: 'update' } ),
        ...mapActions( 'ddt', { update_ddt: 'update', load_ddt: 'get_all' } ),
        ...mapActions( 'order', { update_order: 'update' } ),
        close_ddt( pre ) {
            var confirm_options = {
                buttonSize: 'sm', autoFocusButton: 'ok', okVariant: 'info', okTitle: 'SÌ', cancelVariant: 'secondary',
                cancelTitle: 'NO', noCloseOnEsc: true, noCloseOnBackdrop: true,
            };
            this.$bvModal.msgBoxConfirm('Controlla bene i dati, una volta confermato non avrai più modo di correggere. Continuare?', confirm_options)
                .then(value => {
                    if ( value !== true ) { return; }
                    this.save_in_progress = true;
                    var ids = Object.keys( this.$refs ).filter( x => x.startsWith( pre ) );
                    var updates = this.validate_input( ids );
                    if ( updates === false ) {
                        this.save_in_progress = false;
                        return;
                    }
                    this.updates = updates;
                    this.start_update();
                })
                .catch(err => {
                    console.log( err );
                });
        },
        start_update( ddt_item = null ) {
            if ( ddt_item ) { // eliminazione del record da this.updates
                this.states[ this.updates[0].element_id ] = true;
                this.updates.splice( 0, 1 );
                if ( this.updates.length === 0 ) {
                    this.save_in_progress = false;
                    this.update_ddt( { id: ddt_item.ddt_id, payload: { completed: true, date_managed: moment().format() }, include: true } );
                    this.$emit( 'genericevent', { event_name: 'reload', type: 'completo' } );
                    return;
                }
            }
            var update = this.updates[0];
            this.update_ddt_item( { id: update.id, payload: update.payload } );
        },
        validate_input( ids ) {
            var updates = [];
            var is_valid = true;
            for ( var i = 0; i < ids.length; i++ ) {
                var quantita = parseFloat( this.$refs[ ids[ i ] ][0].vModelValue );
                var quantita_original = parseFloat( this.$refs[ ids[ i ] ][0].value );
                if ( isNaN( quantita ) ) {
                    is_valid = false;
                    this.states[ ids[ i ] ] = false;
                } else {
                    this.states[ ids[ i ] ] = null;
                }
                var payload = { quantita }
                if ( quantita !== quantita_original ) {
                    payload.config = { quantita_original, datetime: moment().format() };
                }
                updates.push({
                    id         : ids[ i ].split('_').slice(-1)[0],
                    payload,
                    element_id : ids[ i ],
                });
            }
            return is_valid === true ? updates : false;
        },
        manual_ddt( id, order_id ) {
            var confirm_options = {
                buttonSize: 'sm', autoFocusButton: 'ok', okVariant: 'info', okTitle: 'SÌ', cancelVariant: 'secondary',
                cancelTitle: 'NO', noCloseOnEsc: true, noCloseOnBackdrop: true,
            };
            this.$bvModal.msgBoxConfirm('Continuando il ddt in visione e tutti i ddt collegati all\'ordine potranno essere gestiti solo manualmente e non saranno più visibili. Confermi?', confirm_options)
                .then(value => {
                    if ( value !== true ) { return; }
                    this.update_order( { id: order_id, payload: { locked: true, ids: this.blocks.map( x => x.order_id ) } } );
                    this.$emit( 'genericevent', { event_name: 'reload', type: 'manuale' } );
                })
                .catch(err => {
                    console.log( err );
                });
        },
        enlight_string( str ) {
             if ( !this.filter ) { return str; }
             let reg_exp = new RegExp( this.filter, 'i' )
             let match = str.match( reg_exp );
             if ( !match ) { return str; }
             return `<span>${ str.replace( match[0], `<span style="background-color: #B1422B; color: white; font-weight: 800; padding: 1px; margin-left: 1px; margin-right: 1px;">${ match[0] }</span>` ) }</span>`;
        },
    },
    created() { /*console.log( this.blocks );*/ },
    data() {
        return {
            save_in_progress : false,
            states           : {},
            updates          : [],
        }
    },
}

</script>

<template>
    <div>
        <div class="accordion" role="tablist" v-for="block in blocks" :key="'card_' + block.numero">
            <b-card no-body style="margin-bottom: 10px">
                <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button style="text-align: left" block v-b-toggle="'collapse-' + block.order_id + '-' + item.id" :variant="block.locked === true ? 'warning' : 'info'">
                        Ordine {{ block.numero }} <icon :name="block.locked === true ? 'lock' : 'unlock'" style="opacity: 0.5" />
                    </b-button>
                </b-card-header>
                <b-collapse :visible="block.locked === false" :id="'collapse-' + block.order_id + '-' + item.id" role="tabpanel">

                    <b-card-body>
                        <table class="table-manager" style="width: 100%; border: 1px solid lightgrey">
                            <tr>
                                <th>Codice</th><th>Descrizione</th><th>Udm</th><th>Q.tà</th>
                            </tr>
                            <tbody v-if="block.items.length > 0">
                                <tr v-for="row in block.items" :key="'row_detail_' + item.id + '_' + row.id" style="background-color: white">
                                    <td v-html="enlight_string( row.codiceProdotto )" />
                                    <td v-html="enlight_string( row.descrizione )" />
                                    <td>
                                        {{ row.unita }}
                                    </td>
                                    <td>
                                        <b-form-input
                                            type      = "number"
                                            step      = ".01"
                                            :disabled = "save_in_progress || block.numero === 'Indecifrabile' || block.locked === true || item.completed === true || item.manual === true"
                                            :state    = "states[ 'input_' + item.id + '_' + block.numero + '_' + row.id ]"
                                            :value    = "parseFloat( row.quantita )"
                                            size      = "sm"
                                            style     = "width: 200px"
                                            :ref      = "'input_' + item.id + '_' + block.numero + '_' + row.id"
                                        >
                                        </b-form-input>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else-if="items.length === 0">
                                <tr style="background-color: white"><td colspan="3" class="text-center" style="font-weight: 700">Non ci sono record</td></tr>
                            </tbody>
                        </table>
                        <b-row style="margin-top: 10px">
                            <b-col style="opacity: 0.6">
                                <span v-if="block.locked === true"><i>la quantità dei prodotti non è editabile perché l'ordine al quale appartengono è in stato bloccato</i></span>
                                <span v-else-if="block.numero === 'Indecifrabile'"><i>la quantità dei prodotti non è editabile perché il numero d'ordine non è stato riconosciuto</i></span>
                                <span v-else-if="item.completed"><i>la quantità dei prodotti non è editabile perché il ddt è già stato gestito</i></span>
                                <span v-else-if="item.manual"><i>la quantità dei prodotti non è editabile perché il ddt è in stato bloccato</i></span>
                            </b-col>
                            <b-col class="text-right">
                                <b-button v-if="block.numero !== 'Indecifrabile' && block.locked === false && item.completed === false && item.manual === false" :disabled="save_in_progress" size="sm" variant="warning" @click="manual_ddt( item.id, block.order_id )" class="button-manual">
                                    Blocca ordine <b-icon icon="x-circle" aria-hidden="true"></b-icon>
                                </b-button>
                                <b-button v-if="block.numero !== 'Indecifrabile' && block.locked === false && item.completed === false && item.manual === false" :disabled="save_in_progress" size="sm" variant="success" @click="close_ddt( 'input_' + item.id + '_' + block.numero + '_' )">
                                    Conferma <b-icon icon="check-circle" aria-hidden="true"></b-icon>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-body>

                </b-collapse>
            </b-card>
        </div>
    </div>
</template>

<style>

    .table-manager td,th {
        font-size: 0.8rem;
        padding: 4px;
    }

    .button-manual {
        margin-right: 10px
    }

</style>

