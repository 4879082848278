<script>

import filters_obj from './filters_obj';

export default {
    name: 'invoice-approval-filters',
    props: [ 'store_name', 'default_values' ],
    components : {},
    computed : {
        selected_ids() {
            return this.$store.state[ this.store_name ].selected_ids;
        },
        has_selected_ids() {
            return this.selected_ids.length > 0;
        },
    },
    watch: {
        has_selected_ids( value ) {
            this.$refs.search_form.disabled = value;
        },
        show_riba() {
            if ( this.show_riba === true ) {
                this.fobj.update( { modalita_pagamento__not: null }, this.$store );
                this.fobj.update( { modalita_pagamento: 'MP12' }, this.$store );
            } else {
                this.fobj.update( { modalita_pagamento: null }, this.$store );
                this.fobj.update( { modalita_pagamento__not: 'MP12' }, this.$store );
            }
            this.$emit( 'reload', null );
        },
    },
    methods: {
        set_filter( data ) {
            let update = this.fobj.update( data, this.$store );
            if ( update ) {
                this.$emit( 'reload', null );
            }
        },
    },
    created() {},
    data() {
        return {
            form_key  : 0,
            fobj      : new filters_obj( this.store_name, JSON.parse( JSON.stringify( this.default_values ) ) ),
            show_riba : true
        }
    },
}

</script>

<template>

    <div>

        <div style="padding: 5px 15px;">
            <b-form-checkbox v-model="show_riba" name="check-button" :disabled="has_selected_ids" switch>
                Riba
            </b-form-checkbox>
        </div>
    
        <vmm-form
            :key       = "form_key"
            name       = "_invoice_approval_filter"
            layout     = "single"
            :options   = "{}"
            :record    = "JSON.parse( JSON.stringify( fobj.filters ) )"
            :submit    = "false"
            :inline    = "true"
            ref        = "search_form"
            @change    = "set_filter"
        />

    </div>

</template>

