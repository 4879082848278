////////////////////////////////////////

import axios from "axios"; // TODO da rimuovere

import base_module from '@/vmm-store/modules/base';
import vmm_store from '../vmm-store'; // TODO da rimuovere

const base_service = vmm_store.service; // TODO da rimouovere

const options = {
    model_name    : 'invoice_consistency_doc_type',
    default_order : 'id ASC',
};

const state = {
};

const actions = {
    get_all( { commit, state, getters }, data = {} ) { // TODO new
        console.log('ciccio pasticcio');
        commit('start_request');
        var params   = JSON.parse( JSON.stringify( state.params ) );
        params.order = getters.order;
        if ( data.params ) {
            Object.keys( data.params ).map( key => {
                if ( ![ '', null, undefined ].includes( data.params[ key ] ) ) {
                    params[ key ] = data.params[ key ];
                } else {
                    delete params[ key ];
                }
            });
        }
        var new_params_string = JSON.stringify( params, Object.keys(params).sort() );
        var old_params_string = JSON.stringify( state.params, Object.keys(state.params).sort() );
        var force_reload = new_params_string !== old_params_string;
        if ( force_reload ) { commit( 'set_params', { params } ); }
        const url = base_service.get_all( getters.backend_url, getters.model_name, params );
        if ( state.map === null || force_reload || state.force_reload === true || data.force_reload === true ) {
            axios.get( url )
                .then( res => {
                    let response = res.data;
                    if ( params.paginate === true ) { // gestione paginator
                        commit('success_list', response.data);
                        commit('set_total', response.tot);
                        commit('set_page', response.page);
                        commit('set_rows_per_page', response.rows_per_page);
                    } else {
                        commit('success_list', response)
                    }
                })
                .catch( error => {
                    commit('error', error)
                });
        } else {
            commit('stop_request');
        }
    },
};

const mutations = {
};

const getters = {};

class new_module extends base_module {

    constructor ( new_options = {} ) {
        Object.keys( new_options ).map( x => {
            options[ x ] = new_options[ x ];
        });
        super( options );
    }

    static get_module( new_options = {} ) {
        var obj = new new_module( new_options );
        return {
            namespaced: true,
            state: {
                ...obj.state,
                ...state,
            },
            getters: {
                ...obj.getters,
                ...getters,
            },
            actions: {
                ...obj.actions,
                ...actions,
            },
            mutations: {
                ...obj.mutations,
                ...mutations,
            },
        }
    }

}

export default new_module;

