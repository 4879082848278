<script>

//import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    name: 'log-type',
    props: [ 'item' ],
    components : {},
    computed : {
        variant() {
            if ( this.item.type === 'error' ) { return 'danger'; }
            if ( this.item.type === 'ddt' ) { return 'success'; }
            return this.item.type;
        }
    },
    watch: {},
    methods : {
    },
    created() {},
    data() {
        return {
        }
    },
}

</script>

<template>
    <b-badge :variant="variant">{{ item.type }}</b-badge>
</template>

<style>
</style>

