<script>

///////////////////////////////////////////
// TODO: - sistemare le opzioni segnalate
//       - spostare la maggior parte delle relazioni con il module qui
//       - documentazione eventi
//       - aggiungere a listpage le opzioni mancanti (tableAttributes, ecc)

import components from './components';
import filters    from './filters';
import Options    from './Options';

export default {
    name: 'vmm-list',
    props: [
        'model',    // nome del model
        'options',  // opzioni da sovrascrivere a listconfig
        'destroy',  // TODO da implemetare: icona trash nella cella delle operazioni (di default deve aprire un modal di conferma)

        'skip',     // TODO non utilizzato (?)
    ],
    components: { 'detail-card': components.detail_card },
    watch: {
        qry_executed(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch(`${this.cop.storeName}/executed`, false) // TODO da rimuovere, si deve arrangiare lo store
                if ( this.started === false ) {
                    this._set_filtered_fields();
                }
            }
        }
    },
    computed: {
        // store get
        store()         { return this.$store.state[ this.cop.storeName ]; },
        qry_executed()  { return this.store.executed; },
        total_rows()    { return this.store.tot; },
        rows_per_page() { return this.store.rows_per_page; },
        // store get/set
        order: {
            get: function() {
                return this.store.order;
            },
            set: function(value) {
                this.$store.dispatch(`${ this.cop.storeName }/set_order`, value);
            }
        },
        page: {
            get: function() {
                return this.store.page;
            },
            set: function(page) {
                this.load_rows( { params: this.get_qry_filters( { page } ) } );
            }
        },
        filter: {
            set: function(filters) {
                this.add_qry_filters( filters );
                this.load_rows( { params: this.get_qry_filters() } );
            },
            get: function() {
                return this.get_qry_filters();
            }
        },
        // altro
        initial_sort () {
            if ( this.order !== null ) {
                var field = this.order.split(' ')[0];
                var desc = false
                if ( this.order.endsWith('ASC') ) { desc = false; }
                else if ( this.order.endsWith('DESC') ) { desc = true; }
                return { field, desc };
            }
            return { field: undefined, desc: false };
        },
        table_class() {
            if ( this.cop.size === 'sm' ) { return 'pic-small-table text-xsmall'; }
            return '';
        },
        _edit_on_button() {
            if ( this.cop.edit === true && this.cop.editAction === 'button' ) { return true; }
            return false;
        },
    },
    created () {
        this.started = true;
        this.filters = filters;
        if ( this.store.force_reload === true || this.store.items === null ) {
            this.started = false;
            this.load_rows( { params: this.get_qry_filters() } );
        } else {
            this._set_filtered_fields();
        }
    },
    methods: {
        convert_boolean      : val => filters.convert_boolean(val),
        convert_money        : val => filters.convert_money(val),
        count_items          : val => filters.count_items(val),
        format_date          : val => filters.format_date(val),
        format_datetime      : val => filters.format_datetime(val),
        format_date_extended : val => filters.format_date_extended(val),
        array_to_rows        : val => filters.array_to_rows(val),
        load_rows : function (options) {
            this.$store.dispatch(this.model + '/get_all', options);
        },
        get_qry_filters : function( params = {} ) {                                                        // public
            var conf = this.cop.qryOptions;
            Object.keys( params ).map( x => { conf[ x ] = params[ x ] } );
            if ( this.cop.paginate === true ) {
                conf.paginate = true;
            }
            return conf;
        },
        get_detail_fields: function() {
            if ( this.detail_fields.length === 0 ) {
                if ( typeof this.cop.fields === 'undefined' ) {
                    this.detail_fields = Object.keys( this.store.items[0] );
                } else {
                    //this.detail_fields = this.cop.fields;
                }
            }
            return this.detail_fields;
        },
        go_to_form: function( row ) {
            var id = this.primaryKey ? row.item[ this.primaryKey ] : row.item.id;
            this.$router.push( `/${ this.cop.formPath }/${this.model}/${id}` );
        },
        go_to_link: function( row ) {
            if ( this.cop.linkFunction ) {
                this.cop.linkFunction( row.item[ row.field.url ], row.item )
                    .then( () => { this.load_rows( { params: this.get_qry_filters() } ); } );
            }
            this.$router.push( row.item[ this.link ] );
        },
        sort_changed(data) {
            var field = data.sortBy;
            var direction = data.sortDesc ? 'DESC' : 'ASC';
            this.order = [ field, direction ].join(' ');
            //this.load_rows( { params: this.get_qry_filters() } );
            this.load_rows();
        },
        _get_field: function ( key ) {
            var obj = null;
            this.cop.fields.map( x => {
                if ( x.key === key ) { obj = x; }
            });
            return obj === null ? key : obj;
        },
        _set_filtered_fields() { // si occupa di distribuire i campi tra colonne e details
            this.filtered_fields = [];
            this.get_detail_fields();
            if ( typeof this.cop.fields === 'undefined' ) {
                var rows = [
                    '\n----------',
                    'nella entry "' + this.model + '" di listconfig.js non è presente l\'attributo "fields"',
                    '----------',
                ];
                console.error( rows.join('\n\n') );
            }
            this.filtered_fields = this.cop.fields.map( x => {
                if ( x.hasOwnProperty('display') && x.display === false ) {
                    this.detail_fields.push( x );
                } else {
                    var column_field = this._get_column_field(x);
                    if ( column_field.hasOwnProperty('htmlformatter') ) {
                        this.html_formatted.push( column_field );
                    } else if ( column_field.hasOwnProperty('component') ) {
                        this.component_formatted.push( column_field );
                    }
                    return column_field;
                }
            });
            if ( this.filtered_fields.indexOf('show_details') === -1 ) {
                if (
                    this.cop.detailsShow === true ||
                    this.edit === true ||
                    this.link !== null
                   ) {
                    var field = { key: 'operative_column', label: '' };
                    this.filtered_fields.push( field );
                }
            }
            this.started = true;
        },
        _get_column_field( field ) {
            var column = Object.assign( {}, field );
            if ( field.hasOwnProperty( 'class' ) && Array.isArray(field.class) ) {
                column.class = [ field.class[0], field.class[1] ].join(' ');
            }
            return column;
        },
        row_selected( rows ) {
            this.$emit('row-selected', rows );
        },
        row_dbl_click(row/*, index, event */) {
            if ( this.cop.editAction === 'double-click' ) {
                this.go_to_form( { item: row } );
            }
        },
        right_click( row, index, event ) {
            event.preventDefault();
            this.$emit('right-click', { row, event } );
            return false;
        },
        show_details( row/*, index, event*/ ) {
            if ( this.cop.detailsAction === 'click' ) {
                this.$set(row, '_detailsShow', !row._detailsShow)
            }
        },
        left_click( row, index, event ) {
            this.numClicks++;
            if (this.numClicks === 1) {          // the first click in .2s
                var self = this;
                setTimeout(function() {
                    switch(self.numClicks) {     // check the event type
                          case 1:
                             if ( self.cop.clickable ) {
                                 self.$emit( 'left-click', row );
                                 break;
                             }
                             self.show_details( row, index, event ); // simple click
                             break;
                          default:
                             self.row_dbl_click( row, index, event ); // double click
                    }
                    self.numClicks = 0;               // reset the first click
                }, 200);                              // wait 0.2s
            }
        },
        get_html_formatted_key( column ) {
            return `cell(${ column.key })`;
        },
        get_component_formatted_key( column ) {
            return `cell(${ column.key })`;
        },
        get_data( data, key ) {
            var tokens = key.split('.');
            var value = data.item;
            for ( var i = 0; i < tokens.length; i++ ) {
                if ( !value || value.hasOwnProperty( tokens[ i ] ) === false ) { console.error('key non valida per data', { key, data }); }
                value = value[ tokens[ i ] ];
            }
            return value;
        },
        get_html_string(field, data) {
            if ( typeof field.htmlformatter === 'string' ) {
                return filters[ field.htmlformatter ]( this.get_data( data, field.key ) );
            } else if ( typeof field.htmlformatter === 'function' ) {
                return field.htmlformatter( JSON.parse( JSON.stringify( data.item ) ) );
            }
        },
        emit_event( data ) {
            var { event_name, ...options_obj } = data;
            var options = Object.keys( options_obj ).map( x => options_obj[ x ] );
            this.$emit(event_name, ...options );
        },
        new_element() {
            this.$router.push( `/form/${ this.model }` );
        },
        toggle_details( row ) {
            let has_details = false;
            if ( row.item.hasOwnProperty('_showDetails') ) { has_details = true; }
            row.toggleDetails();
            if ( has_details ) { this.table_key += 1; }
        },
        open_all_details() {
            console.log( this.$refs.main_table );
        }
    },
    data() {
        return {
            table_key           : 0,
            detail_fields       : [],
            started             : false,
            filtered_fields     : [],
            html_formatted      : [],
            component_formatted : [],
            numClicks           : 0,
            cop                 : new Options( this.options, this.model, this.$listconfig ),
        }
    },
};

</script>

<template>

    <div>
        <!-- toolbar -->
        <b-row>
            <b-col cols="2">
                <!-- button filter -->
            </b-col>
            <b-col cols="8">
                <!-- paginator -->
                <b-pagination
                    v-if="cop.paginate === true"
                    v-model="page"
                    :total-rows="total_rows"
                    :per-page="rows_per_page"
                    :aria-controls="'bnvlist-' + model"
                    align="center"
                    size="sm"
                ></b-pagination>
            </b-col>
            <b-col cols="2" class="text-right">
                <!-- button add -->
                <b-button v-if="cop.add" @click="new_element()" size="sm" variant="outline-info"><icon name="plus"/></b-button>
            </b-col>
        </b-row>

        <!-- data table -->
        <b-table
            ref              = "main_table"
            :key             = "table_key"
            v-bind           = "cop.tableAttributes"
            :id              = "'bnvlist-' + model"
            :items           = "store.items"
            :fields          = "filtered_fields"
            :primary-key     = "cop.primaryKey"
            :busy            = "store.loading || started === false"
            @sort-changed    = "sort_changed"
            :sort-by         = "initial_sort.field"
            :sort-desc       = "initial_sort.desc"
            :tbody-tr-class  = "cop.rowClass"
            @row-contextmenu = "right_click"
            @row-clicked     = "left_click"
            @row-selected    = "row_selected"
            :class           = "table_class"
            >

            <template v-for="field in html_formatted" v-slot:[get_html_formatted_key(field)]="data">
                <span v-html="get_html_string( field, data )" :key="field.key"></span>
            </template>

            <template v-slot:cell(operative_column)="row">
                <div class="text-center">
                <b-button v-if="_edit_on_button" size="sm" @click="go_to_form(row)" variant="light">
                    <icon name="edit"/>
                </b-button>
                <b-button v-if="cop.link !== null" size="sm" @click="go_to_link(row)" variant="light">
                    <icon name="eye"/>
                </b-button>
                <b-button size="sm" @click="toggle_details(row)" variant="light" v-if="cop.detailsShow && cop.detailsAction === 'button'" :class="cop.detailsClass">
                    <icon :name="row.detailsShowing ? 'caret-up' : 'caret-down'"/> {{ cop.detailsClass }}
                </b-button>
                </div>
            </template>

            <template v-for="cmp in component_formatted" v-slot:[get_component_formatted_key(cmp)]="data">
                <component
                    v-bind:key       = "cmp.name"
                    :name            = "cmp.name"
                    :is              = "cmp.component"
                    :item            = "data.item"
                    @genericevent    = "emit_event"
                ></component>
            </template>

            <template v-slot:row-details="row">
                <detail-card :row="row.item" :display="detail_fields" v-if="!cop.component_details"></detail-card>
                <component
                    v-bind:key       = "'block_details_' + row.item.id"
                    v-else-if        = "cop.component_details"
                    :name            = "cop.component_details.name"
                    :is              = "cop.component_details"
                    :item            = "row.item"
                    @genericevent    = "emit_event"
                ></component>
            </template>

            <template v-slot:table-busy>
                <div class="text-center">
                    <div class="spinner-grow text-dark" role="status" style="width: 6rem; height: 6rem;">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </template>

        </b-table>

        <!-- paginator -->
        <div class="overflow-auto" v-if="cop.paginate === true && started === true">
            <b-pagination
                v-model="page"
                :total-rows="total_rows"
                :per-page="rows_per_page"
                :aria-controls="'bnvlist-' + model"
                align="center"
                size="sm"
            ></b-pagination>
        </div>
    
    </div>

</template>

<style>

    .read {
        font-style: italic;
        opacity: 0.5;
    }

</style>

