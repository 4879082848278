
<script>

import listpage from '../listpage';
import details_manager from '@/ddt/details_manager';

export default {
    name: 'orderddt_listpage',
    extends: listpage,
    components: {},
    props: [],
    computed: {
        override_options() {
            return {
                initialFilter : { ordine_id: this.$route.params.id },
                detailsShow   : details_manager,
            };
        }
    },
    methods: {
        back() { this.$router.push( '/list/order' ); },
    },
    created() {},
    data() {
        return {};
    }
}

</script>

<template>

    <div style="width: 80%; margin: auto">

        <b-row>
            <b-col self-align="start">
                <b-button variant="outline-primary" @click="back()" size="sm"><b-icon icon="arrow-left" aria-hidden="true"></b-icon> Indietro</b-button>
            </b-col>
            <b-col>
                <h2 style="text-align: center; margin-bottom: 30px">DDT</h2>
            </b-col>
            <b-col>
            </b-col>
        </b-row>

        <div>
            <vmm-list
                ref              = "listbnv"
                :model           = "model"
                :options         = "override_options"
    
                :skip            = "false"
                :destroy         = "destroy"
            />
        </div>

    </div>

</template>

