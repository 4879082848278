////////////////////////////////////////

import axios from "axios";

import base_module from '@/vmm-store/modules/base';
import vmm_store from '../vmm-store';

const base_service = vmm_store.service;
import Graphs from '../views/stats/graphs.js';
import GraphLimiter from '../views/stats/graph_limiter.js';

const options = {
    model_name    : 'stat_result',
    default_order : 'id ASC',
};

const state = {
    primary_goal       : null,
    correct_execution  : null,
    errors             : null,
    total              : 0,
    total_bots_managed : 0,
    trend              : null,
    trend_subtitle     : null,
    colors             : [ '#088F8F', '#880808', '#93B5C6', '#688B58', '#D7F75B', '#4E4C67', '#4E4C67', '#3BB273', '#20A4F3', '#16425B', '#F1AB86', ],
    filters            : {},
    params             : {},
    items              : null,
};

const actions = {

    get_all( { commit, state, getters }, data = {} ) { // TODO new
        commit('start_request');
        var params   = JSON.parse( JSON.stringify( state.params ) );
        params.order = getters.order;
        if ( data.params ) {
            Object.keys( data.params ).map( key => {
                if ( ![ '', null, undefined ].includes( data.params[ key ] ) ) {
                    params[ key ] = data.params[ key ];
                } else {
                    delete params[ key ];
                }
            });
        }
        var new_params_string = JSON.stringify( params, Object.keys(params).sort() );
        var old_params_string = JSON.stringify( state.params, Object.keys(state.params).sort() );
        var force_reload = new_params_string !== old_params_string;
        if ( force_reload ) { commit( 'set_params', { params } ); }
        const url = base_service.get_all( getters.backend_url, getters.model_name, params );
        console.log( 'graph force_reload', state.force_reload );
        if ( state.map === null || force_reload || state.force_reload === true || data.force_reload === true ) {
            commit( 'set_trend_subtitle', null );
            axios.get( url )
                .then( res => {
                    let response = res.data;
                    commit( 'set_primary_goal', response.primary_goal );
                    commit( 'set_correct_execution', response.correct_execution );
                    commit( 'set_errors', response.errors );
                    commit( 'set_total', response.total );
                    commit( 'set_total_bots_managed', response.total_bots_managed );
                    commit( 'set_not_managed_rows', response.not_managed_rows );
                    commit( 'set_trend', response.trend );
                    commit( 'set_trend_subtitle', response.trend_subtitle );
                    commit('stop_request');
                })
                .catch( error => {
                    commit('error', error)
                });
        } else {
            commit('stop_request');
        }
    },

};

const mutations = {

    set_primary_goal( state, data ) {
        let graphs = new Graphs( state.colors.slice( 0, 2 ) );
        let series = [{
            name: 'Obiettivo Primario',
            data: data.sort((a, b) => a.has_error - b.has_error)
                      .map( x => {
                return {
                    name : x.has_error === true ? 'Umano' : 'Macchina',
                    y    : parseInt( x.count ) === 0 ? null : parseInt( x.count ),
                }
            })
        }];

        state.primary_goal = graphs.donut( series );
    },

    set_correct_execution( state, data ) {
        let graphs = new Graphs( state.colors.slice( 0, 2 ) );
        let series = [{
            name: 'Gestione Errori',
            data: data.sort((a, b) => b.error_managed - a.error_managed)
                      .map( x => {
                return {
                    name : x.error_managed === true ? 'Gestito Correttamente' : 'Non Gestito',
                    y    : parseInt( x.count ) === 0 ? null : parseInt( x.count ),
                }
            })
        }];

        state.correct_execution = graphs.donut( series );
    },

    set_errors( state, data ) {
        let graphs = new Graphs( state.colors.slice(2, state.colors.length) );
        let limited_data = GraphLimiter.init( data );
        let series = [{
            name: 'Dettaglio Errori',
            data: limited_data.map( x => {
                return {
                    name : x.error,
                    y    : parseInt( x.count ),
                }
            })
        }];

        state.errors = graphs.donut( series );
    },

    set_total( state, total ) { state.total = total; },

    set_total_bots_managed( state, total_bots_managed ) { state.total_bots_managed = total_bots_managed; },

    set_not_managed_rows( state, rows ) {
        state.items = rows;
    },

    set_trend( state, data ) {
        let graphs = new Graphs( state.colors.slice(2, state.colors.length) );

        state.trend = graphs.line(data);
    },

    set_trend_subtitle( state, subtitle ) {
        state.trend_subtitle = subtitle;
    },

};

const getters = {};

class new_module extends base_module {

    constructor ( new_options = {} ) {
        Object.keys( new_options ).map( x => {
            options[ x ] = new_options[ x ];
        });
        super( options );
    }

    static get_module( new_options = {} ) {
        var obj = new new_module( new_options );
        return {
            namespaced: true,
            state: {
                ...obj.state,
                ...state,
            },
            getters: {
                ...obj.getters,
                ...getters,
            },
            actions: {
                ...obj.actions,
                ...actions,
            },
            mutations: {
                ...obj.mutations,
                ...mutations,
            },
        }
    }

}

export default new_module;

