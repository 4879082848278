<script>

///////////////////////////////////////////
// TODO: - sistemare le opzioni segnalate
//       - gestire lo sbvuotamento della form dopo creazione / il ritorno automatico alla lista dopo update
//       - documentazione eventi
//       - aggiungere component mancanti
//       - spostare la maggior parte delle relazioni con il module qui
//       - di default il campo primaryKey va skippato
//       - possibilità di passare una callback per manipolare i dati prima dell'ìnvio
//         es: nel caso dei campi virtual che finiscono in un campo json, per non dover metter mano al backend
//       - eseguire la validazione specificata nello schema
//       - il tasto reset non funziona

import model_manager  from './model_manager';
import element_config from './element_config';

export default {
    name: 'vmm-form',
    props: [
        'name',            // nome del model
        'options',         // TODO come override_extras
        'record',          // record in caso di modalità edit
        'submit',          // se true passa il payload al model per l'invio al backend ( default: true )
        'inline',          // TODO (?)
        'pk',              // il nome del campo chhiave primaria ( default: 'id' )
        'listpath',        // il link per tornare alla lista è /list/{model}; serve a cambiare 'list'
        'layout',          // numero di colonne: 'single' || 'multi' ( default: 'multi' )
        'focus',           // il nome del campo sul quale settare il focus
        'showexit',        // TODO indica se mostrare il tasto "Esci"
        'exit',            // se true: fa il redirect a /list/{model}; se false: emette l'evento @closed
        'override_extras'  // configuarazione della form da sovrascrivere a formconfig
    ],
    components: {},
    watch: {
        created(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch(`${this.name}/force_reload`, true);
                //this.$store.dispatch(`${this.name}/executed`, false);
                this.$emit('saved', this.store.obj );
            }
        },
        updated(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch(`${this.name}/force_reload`, true);
                //this.$store.dispatch(`${this.name}/executed`, false);
                //this.$emit('saved', this.store.obj );
            }
        },
    },
    computed: {
        model() {
            return this.$models[ this.name ];
        },
        store () {
            return this.$store.state[ this.name ];
        },
        qry_executed () {
            return this.$store.state[ this.name ].executed;
        },
        created () {
            return this.$store.state[ this.name ].created;
        },
        updated () {
            return this.$store.state[ this.name ].updated;
        },
        _submit () {
            if ( this.submit === false ) {
                return false;
            }
            return true;
        },
        model_label () {
            //return ( extras[ this.name ].hasOwnProperty('_conf') && extras[ this.name ]._conf.hasOwnProperty('label') ) ? extras[ this.name ]._conf.label : this.name;
            return undefined;
        },
        form_size () {
            return this.form_settings.hasOwnProperty('size') ? this.form_settings.size : undefined;
        },
        element_class () {
            if ( this.form_layout ) { return 'condensed'; }
            if ( typeof this.layout === 'undefined' || this.layout === 'auto' ) { return 2; }
            if ( this.layout === 'single' ) { return 1; }
            return 2;
        },
        setfocus() { return this.focus ? true : false; },
        form_layout() {
            if ( this.$formconfig.hasOwnProperty( this.name ) && this.$formconfig[ this.name ].hasOwnProperty('settings') && this.$formconfig[ this.name ].settings.hasOwnProperty('formlayout') ) {
                return this.$formconfig[ this.name ].settings.formlayout;
            }
            return null;
        },
    },
    created () {
        var frontend_conf  = JSON.parse( JSON.stringify( this.$formconfig ) )[ this.name ];
        if ( frontend_conf.hasOwnProperty('settings') && frontend_conf.settings.hasOwnProperty('size') ) { this.button_size = frontend_conf.settings.size; }
        var component_conf = ( this.override_extras && this.override_extras[ this.name ] ) ? this.override_extras[ this.name ] : null;
        var fields = model_manager( this.model, frontend_conf, component_conf, this.record );
        this.elements = fields;
        this.elements_column = { 'left': {}, 'right': {}, };
        // layout a due colonne
        var left_tot = Object.keys( this.elements ).length / 2;
        if ( Number.isInteger( left_tot ) === false ) { left_tot += 0.5; }
        for ( var i = 0; i < Object.keys( this.elements ).length; i++ ) {
            var x = Object.keys( this.elements )[ i ];
            //console.log( this.elements[ x ].component );
            this.elements_column[ i < left_tot  ? 'left' : 'right' ][ x ] = this.elements[ x ];
        }
        // settings generici della form
        //this.form_settings = this.extras.hasOwnProperty( this.name ) && this.extras[ this.name ].hasOwnProperty('settings') ? this.extras[ this.name ].settings : {};
        this.form_settings = {}; // TODO dimensioni dei tasti
    },
    mounted() {
        if ( Object.keys( this.elements ).length > 0 && this.focus ) {
            setTimeout( () => {
                this.$refs['bnvelement_' + Object.keys( this.elements )[0] ][0].$refs.input.focus();
            }, 500 );
        }
    },
    methods: {
        submit_form: function() {
            this.validate();
            if ( this.is_valid === false ) {
                return;
            } else {
                if ( this._submit === true ) {
                    var pk = this.pk ? this.pk : 'id';
                    var data = { schema: this.name, payload: this.form_data, include: true };
                    if ( this.has_file === true ) {
                        // form multi-type
                        if ( this.record && this.record[ pk ] ) {
                            data[ pk ] = this.record[ pk ];
                            this.$store.dispatch(`${this.name}/updatews`, data);
                        } else {
                            this.$store.dispatch(`${this.name}/createws`, data);
                        }
                    } else {
                        if ( this.record && this.record[ pk ] ) {
                            data[ pk ] = this.record[ pk ];
                            this.$store.dispatch(`${this.name}/update`, data);
                        } else {
                            this.$store.dispatch(`${this.name}/create`, data);
                        }
                    }
                } else {
                    // metto da parte eventuali obj file prima della conversione in json
                    var object_elements = {}
                    Object.keys( this.form_data ).map( x => {
                        if ( this.form_data !== null && typeof this.form_data[ x ] === 'object' ) {
                            object_elements[ x ] = this.form_data[ x ];
                        }
                    });
                    //try {
                        var res = JSON.parse( JSON.stringify( this.form_data ) );
                        Object.keys( object_elements ).map( x => { res[ x ] = object_elements[ x ] } );
                        this.$emit('submitted', res);
                        return res;
                    //} catch (error) {
                    //    throw error;
                    //}
                }
            }
        },
        get_form_data() { // public
            this.validate();
            return JSON.parse( JSON.stringify( this.form_data ) );
        },
        reset_form: function() {
            //this.$store.dispatch('bnvform/clear');
            this.$emit('reset', 1);
        },
        reseta: function() { // public - experimental 14/03/20
            Object.keys( this.elements ).map( element_name => {
                this.$refs[ `bnvelement_${ element_name }` ][0].reseta();
            });
        },
        set_payload(payload) {
            for ( var i = 0; i < Object.keys( payload ).length; i++ ) {
                var element_name = Object.keys( payload )[ i ];
                this.$refs[ `bnvelement_${ element_name }` ][0].set_value( payload[ element_name ] );
            }
        },
        get_element_value( element_name ) { // public
            var value = this.$refs[ `bnvelement_${ element_name }` ][0].get_value();
            return value ? value : null;
        },
        validate() {
            this.is_valid = true;
            Object.keys( this.elements ).map( element_name => {
                var value        =  this.$refs[ `bnvelement_${ element_name }` ][0].get_value();
                var element_type =  this.$refs[ `bnvelement_${ element_name }` ][0].elementtype;
                var is_valid     = !this.$refs[ `bnvelement_${ element_name }` ][0].error_component;
                if ( element_type === 'file' || element_type === 'file_progress' ) { this.has_file = true; }
                if ( is_valid === false ) { this.is_valid = false; }
                this.form_data[ element_name ] = value;
            });
        },
        error_message( name ) {
            if ( this.store.error === true ) {
                if ( this.store.obj && this.store.obj.hasOwnProperty('errors') && this.store.obj.errors.hasOwnProperty( name ) ) {
                    return this.store.obj.errors[ name ].message;
                }
            }
        },
        back_to_list() {
            if ( this.exit !== false ) {
                var list_path = this.listpath || 'list';
                this.$router.push( `/${ list_path }/${this.name}` );
            } else {
                this.$emit('closed', this.store.obj );
            }
        },
        value_changed( payload ) {
            this.$emit('change', payload );
        },
        get_element_config( element ) {
            var conf = new element_config( element );
            return conf.obj;
        },
        noop() {},
    },
    data () {
        return {
            is_valid    : true,
            form_data   : {},
            has_file    : false,
            button_size : 'sm',
            disabled    : false,
        }
    },
};

</script>

<template>

    <b-form :inline="false"  v-on:submit.prevent="noop" style="padding: 0"> <!-- form inline -->
        <b-container>

            <!-- layout condensed -->
            <b-row v-if="form_layout">
                <b-col v-for="( col, index ) in form_layout.cols" v-bind:key="'form-' + name + '-' + index">
                    <div v-for="(item, item_index) in Object.keys( col )" v-bind:key="'form-' + name + '-' + index + '-' + item_index">
                        <component
                            v-if             = "item.startsWith('field')"
                            v-bind:key       = "col[ item ]"
                            :name            = "col[ item ]"
                            :ref             = "'bnvelement_' + col[ item ]"
                            :disabled        = "store.loading || disabled"
                            :errormessage    = "error_message( col[ item ] )"
                            :config          = "elements[ col[ item ] ].schema"
                            :is              = "elements[ col[ item ] ].component"
                            :schema          = "name"
                            :layout          = "element_class"

                            :precision       = "elements[ col[ item ] ].precision"
                            :decimal         = "elements[ col[ item ] ].decimal"
                            :maxsize         = "elements[ col[ item ] ].max_size"
                            :mimetypes       = "elements[ col[ item ] ].mime_types"
                            :search          = "elements[ col[ item ] ].search"
                            :filter          = "elements[ col[ item ] ].filter"
                            :upload_progress = "store.queue_percentage"
                            @change          = "value_changed"
                            @enter_key       = "submit_form"
                        ></component>
                        <b-row class="form-group row" v-if="item.startsWith('row')" style="margin-bottom: 0" align-h="between">
                            <template v-for="field in Object.keys( col[ item ] )">
                                <b-col :cols="12 / Object.keys( col[ item ] ).length" v-bind:key="col[ item ][field] + '-' + field + '-container'">
                                    <component
                                        v-if             = "col[ item ][ field ] !== null"
                                        v-bind:key       = "col[ item ][ field ]"
                                        :name            = "col[ item ][ field ]"
                                        :ref             = "'bnvelement_' + col[ item ][ field ]"
                                        :disabled        = "store.loading || disabled"
                                        :errormessage    = "error_message( col[ item ][ field ] )"
                                        :config          = "elements[ col[ item ][ field ] ].schema"
                                        :is              = "elements[ col[ item ][ field ] ].component"
                                        :schema          = "name"
                                        :layout          = "element_class"
                                        fields_per_row   = "2"
            
                                        :precision       = "elements[ col[ item ][ field ] ].precision"
                                        :decimal         = "elements[ col[ item ][ field ] ].decimal"
                                        :maxsize         = "elements[ col[ item ][ field ] ].max_size"
                                        :mimetypes       = "elements[ col[ item ][ field ] ].mime_types"
                                        :search          = "elements[ col[ item ][ field ] ].search"
                                        :filter          = "elements[ col[ item ][ field ] ].filter"
                                        :upload_progress = "store.queue_percentage"
                                        @change          = "value_changed"
                                        @enter_key       = "submit_form"
                                    ></component>
                                </b-col>
                            </template>
                        </b-row>
                    </div>
                </b-col>
            </b-row>

            <!-- layout standard -->
            <b-row v-else-if="!form_layout" cols="1" :cols-md="element_class" style="">
                <template v-for="(element, element_name) in elements">
                    <b-col style="" class="bnvform-element" :key="'bnvelement_' + element_name">
                        <component
                            v-bind:key       = "element_name"
                            :name            = "element_name"
                            :ref             = "'bnvelement_' + element_name"
                            :disabled        = "store.loading || disabled"
                            :errormessage    = "error_message(element_name)"
                            :config          = "element.schema"
                            :is              = "element.component"
                            :schema          = "name"

                            :precision       = "element.precision"
                            :decimal         = "element.decimal"
                            :maxsize         = "element.max_size"
                            :mimetypes       = "element.mime_types"
                            :search          = "element.search"
                            :filter          = "element.filter"
                            :upload_progress = "store.queue_percentage"
                            @change          = "value_changed"
                            @enter_key       = "submit_form"
                        ></component>
                    </b-col>
                </template>
            </b-row>

            <div style="margin-bottom: 15px; margin-top: 10px;" class="text-center">
                <b-button v-if="!inline" :size="button_size" style="margin-right: 10px;" @click="submit_form();" variant="success">Salva</b-button>
                <b-button v-if="!inline" :size="button_size"  style="margin-right: 10px;" @click="reset_form();" variant="warning">Reset</b-button>
                <b-button v-if="showexit !== false && !inline" :size="button_size"  style="margin-right: 10px;" @click="back_to_list();" variant="outline-secondary">
                    Esci
                </b-button>
            </div>
        </b-container>
    </b-form>

</template>

<style>
</style>

