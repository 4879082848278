/////////////////////////////////////////////////

//const module = require('./path_to_module.js');

class graph_limiter {

    constructor( data ) {
        this.data = data;
    }

    // static public methods ////////////////////

    static init( data ) {
        var obj = new graph_limiter( data );
        let new_data = obj._limit();
        return new_data;
    }

    // static private methods ///////////////////

    // public methods ///////////////////////////

    // private methods //////////////////////////

    _limit() {
        let altro = { error: 'Altro', count: 0 };
        let approved_labels = [];
        for ( var i = 0; i < this.data.length; i++ ) {
            let item = this.data[ i ];
            let perc = this._get_perc( item.count );
            if ( perc < 5.1 ) {
                altro.count += parseInt( item.count );
                continue;
            }
            approved_labels.push( item );
        }
        if ( altro.count > 0 ) { approved_labels.push( altro ); }
        return approved_labels;
    }

    _get_perc( value ) {
        return ( parseFloat( value ) * 100 ) / this.tot;
    }

    // getters & setters ////////////////////////

    get tot() {
        let tot = 0;
        this.data.map( x => { tot += parseFloat( x.count ); } );
        return tot;
    }

}

// static getters ///////////////////////////

module.exports = graph_limiter;

