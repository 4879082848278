<script>

//import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    name: 'ddt-status',
    props: [ 'item' ],
    components : {},
    computed : {
        status() {
            if ( this.item.manual === true ) { return { label: 'manuale', variant: 'warning' }; }
            if ( this.item.completed === true ) { return { label: 'gestito', variant: 'success' }; }
            return { label: 'da gestire', variant: 'secondary' };
        }
    },
    watch: {},
    methods : {
    },
    created() {},
    data() {
        return {
        }
    },
}

</script>

<template>
    <b-badge :variant="status.variant">{{ status.label }}</b-badge>
</template>

<style>
</style>

