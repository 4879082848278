//////////////////////////////

module.exports = function( type ) {

    var schema = {
        invoice_approval_state_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'invoice_approval_states',
                key         : 'id',
                constraints : true,
                store_name  : 'invoice_approval_state',
            }
        },
        data: {
            type      : type.VIRTUAL,
            allowNull : true,
            range     : true,
        },
        data_scadenza_pagamento: {
            type      : type.VIRTUAL,
            allowNull : true,
            range     : true,
        },
        //cliente_piva: {
        //    type: type.INTEGER,
        //    allowNull: true,
        //    references: {
        //        model       : 'invoice_approval_piva',
        //        key         : 'id',
        //        constraints : true,
        //        store_name  : 'invoice_approval_piva_cliente_autocomplete',
        //        type        : 'autocomplete',
        //        auto_select : true,
        //        param       : 'cliente',
        //        field_value : 'label', // il campo della tabella che stabilisce il valore assunto dal campo autocomplete (def: id)
        //    },
        //},
        //fornitore_piva: {
        //    type: type.INTEGER,
        //    allowNull: true,
        //    references: {
        //        model       : 'invoice_approval_piva',
        //        key         : 'id',
        //        constraints : true,
        //        store_name  : 'invoice_approval_piva_emittente_autocomplete',
        //        type        : 'autocomplete',
        //        auto_select : true,
        //        param       : 'emittente',
        //        field_value : 'label', // il campo della tabella che stabilisce il valore assunto dal campo autocomplete (def: id)
        //    },
        //},
        //cliente_cf: {
        //    type: type.INTEGER,
        //    allowNull: true,
        //    references: {
        //        model       : 'invoice_approval_cf',
        //        key         : 'id',
        //        constraints : true,
        //        store_name  : 'invoice_approval_cf_cliente_autocomplete',
        //        type        : 'autocomplete',
        //        auto_select : true,
        //        param       : 'cliente',
        //        field_value : 'label', // il campo della tabella che stabilisce il valore assunto dal campo autocomplete (def: id)
        //    },
        //},
        fornitore_denominazione: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'invoice_approval_cf',
                key         : 'id',
                constraints : true,
                store_name  : 'invoice_approval_cf_emittente_label_autocomplete',
                type        : 'autocomplete',
                auto_select : true,
                param       : 'emittente',
                field_value : 'denominazione', // il campo della tabella che stabilisce il valore assunto dal campo autocomplete (def: id)
            },
        },
        fornitore_cf: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'invoice_approval_cf',
                key         : 'id',
                constraints : true,
                store_name  : 'invoice_approval_cf_emittente_autocomplete',
                type        : 'autocomplete',
                auto_select : true,
                param       : 'emittente',
                field_value : 'label', // il campo della tabella che stabilisce il valore assunto dal campo autocomplete (def: id)
            },
        },
    };

    return schema;
}

