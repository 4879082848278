///////////////////////////////////////

// GESTORE DEL MESSAGGIO DI ERRORE PER LE MASCHERE

export default {
    namespaced: true,
    state: {
        type    : null,
        message : null,
        timeout : null,
    },
    actions: {
        success({ commit }, message) {
            commit('success', message);
            var timeout = setTimeout( () => { commit('clear') }, 5000);
            commit('set_timeout', timeout);
        },
        error({ commit }, message) {
            commit('error', message);
            var timeout = setTimeout( () => { commit('clear') }, 5000);
            commit('set_timeout', timeout);
        },
        clear({ commit }) {
            commit('clear');
        },
        set_params( { commit }, obj ) { commit('set_params', obj); },
    },
    mutations: {
        success(state, message) {
            state.type = 'valid-feedback';
            state.message = message;
        },
        error(state, message) {
            state.type = 'invalid-feedback';
            state.message = message;
        },
        set_timeout( state, timeout ) {
            if ( state.timeout ) { clearTimeout( state.timeout ); }
            state.timeout = timeout;
        },
        clear(state) {
            state.type = null;
            state.message = null;
            if ( state.timeout ) { clearTimeout( state.timeout ); }
        },
        set_params( state, obj ) {
            if ( typeof obj === 'object' ) {
                var keys = Object.keys( obj );
                for ( var i = 0; i < keys.length; i++  ) {
                    if ( state.hasOwnProperty( keys[ i ] ) ) { state[ keys[ i ] ] = obj[ keys[ i ] ]; }
                }
            }
        },
    }
}
