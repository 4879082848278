<script>

import { mapActions, mapState } from 'vuex';
import config from '@/config.js';
const packagejson = require('@/../package.json');
const psw_manager = require('./password');

export default {
    name: 'reset-password',
    computed : {
        ...mapState('authentication', {
            saving        : state => state.saving_password,
            jwt_expired   : state => state.jwt_expired,
            generic_error : state => state.generic_error,
            psw_settings  : state => state.psw_settings,
            psw_changed   : state => state.psw_changed,
        }),
        jwt() {
            return this.$route.query.j || null;
        },
        action() {
            return this.$route.query.a || null;
        },
        alert () {
            return this.$store.state.alert;
        },
        icon_url () {
            if ( config.gui.hasOwnProperty('icon') && config.gui.icon ) { return config.backend_url + `/static/${ config.gui.icon }`; }
            return undefined;
        },
        color () {
            if ( config.gui.hasOwnProperty('color') && config.gui.color ) { return config.gui.color; }
            return undefined;
        },
        app_label () {
            if ( config.gui.hasOwnProperty('app_label') && config.gui.app_label ) { return config.gui.app_label; }
            return undefined;
        },
        error_new_password() {
            return this.psw_manager.new_password_error || null;
        },
        error_confirm_password() {
            return this.psw_manager.confirm_password_error || null;
        },
        jwt_expired_error() {
            if ( this.action === 'rp' ) {
                return 'un\'ora. Ripetere l\'operazione di recupero password.';
            } else if ( this.action === 'nu' ) {
                return 'una settimana. Contattare gli amministratori di sistema.';
            }
            return '';
        }
    },
    watch: {
        psw_settings( new_val, old_val ) {
            if ( new_val && !old_val ) {
                this.psw_manager.psw_settings = new_val;
            }
        },
        psw_changed() {
            setTimeout( () => {
                this.$router.push( '/login' );
            }, 15000);
        },
    },
    methods : {
        ...mapActions( 'authentication', { save_password: 'save_password', load_settings: 'load_settings' } ),
        manage_form (/*e*/) {
            this.submitted = false;
            this.submitted = true;
            this.psw_manager.validate( this.new_password, this.confirm_password );
            this.form_key += 1;
            if ( this.psw_manager.is_valid ) {
                this.save_password( { jwt: this.jwt, new_password: this.new_password, confirm_password: this.confirm_password } );
            }
        },
    },
    created() {
        if ( this.jwt ) {
            this.load_settings( { jwt: this.jwt } );
        }
    },
    data() {
        return {
            new_password     : '',
            confirm_password : '',
            submitted        : false,
            env              : config.env,
            version          : packagejson.version,
            psw_manager      : new psw_manager(),
            form_key         : 10000,
        }
    },
}

</script>

<template>
    <b-container style="margin-top: 50px;">
        <b-col sm="10" md="7" lg="4" style="margin: auto;">
            <b-card
                v-bind:style="color ? 'border: 2px solid ' + color : ''"
                style="padding-top: 20px; padding-left: 20px; padding-right: 20px; padding-bottom: 0px;"
                >
                <b-row>
                    <b-col>
                        <div v-if="icon_url" class="text-center">
                            <b-img :src="icon_url" fluid width="200" alt="customer logo"></b-img>
                        </div>
                        <h4
                            class="text-center"
                            style="font-weight: 700; margin-bottom: 10px; margin-top: 30px;"
                            v-bind:style="color ? 'color: ' + color : ''"
                            >
                            {{ app_label }}
                        </h4>
                        <h6 style="font-weight: 600; margin-bottom: 20px;" class="text-center">IMPOSTAZIONE<br/>DELLA PASSWORD</h6>

                        <!-- jwt assente -->
                        <div v-if="!jwt" style="margin-top: 40px; margin-bottom: 20px;">
                            <b-alert variant="warning" show><strong>Token assente</strong><br/>Per accedere a questa pagina è necessario cliccare il link contenuto nella email.</b-alert>
                        </div>

                        <!-- action assente -->
                        <div v-else-if="!action" style="margin-top: 40px; margin-bottom: 20px;">
                            <b-alert variant="warning" show><strong>Link incompleto</strong><br/>Il link copiato dalla email risulta incompleto.<br/></b-alert>
                        </div>

                        <!-- jwt scaduto -->
                        <div v-else-if="jwt_expired" style="margin-top: 40px; margin-bottom: 20px;">
                            <b-alert variant="warning" show><strong>Token scaduto</strong><br/>Per accedere a questa pagina è necessario cliccare il link contenuto nella email entro {{ jwt_expired_error }}</b-alert>
                        </div>

                        <!-- errore generico -->
                        <div v-else-if="generic_error" style="margin-top: 40px; margin-bottom: 20px;">
                            <b-alert variant="warning" show><strong>Errore generico</strong><br/>Si è verificato un errore di comunicazione con il backend.<br/>Ripetere l'operazione di recupero password.</b-alert>
                        </div>

                        <!-- caricamento delle impostazini della password -->
                        <div v-else-if="!psw_settings" class="text-center" style="margin-top: 30px; margin-bottom: 20px;">
                            <b-spinner variant="primary" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner><br/>
                            <em style=""><br/>caricamento delle impostazioni<br/>in corso...</em>
                        </div>

                        <!-- impostazione password -->
                        <div v-else-if="!psw_changed" style="margin-top: 40px; margin-bottom: 20px;">
                            <form class="form-signin" @submit.prevent="manage_form" :key="form_key">
                                <div class="login-wrap" style="margin-top: 15px">
                                    <b-form-input type="password" v-model="new_password" name="new_password" class="form-control" :class="{ 'is-invalid': submitted && error_new_password }" placeholder="nuova password" style="" @keydown.enter.native="manage_form" autofocus></b-form-input>
                                    <div v-show="submitted && error_new_password" class="invalid-feedback">{{ error_new_password }}</div>
                                    <div style="height: 12px">&nbsp;</div>
                    
                                    <b-form-input type="password" v-model="confirm_password" name="confirm_password" class="form-control" :class="{ 'is-invalid': submitted && error_confirm_password }" placeholder="conferma password" style="" @keydown.enter.native="manage_form"></b-form-input>
                                    <div v-show="submitted && error_confirm_password" class="invalid-feedback">{{ error_confirm_password }}</div>
                                    <div style="height: 12px">&nbsp;</div>

                                    <div style="text-align: left">
                                        <span>la password deve:</span>
                                        <ul id="password-ul">
                                            <li>{{ psw_manager.min_password_label }}</li>
                                            <li>{{ psw_manager.max_password_label }}</li>
                                            <li v-if="psw_manager.need_upper_case_char">{{ psw_manager.upper_case_message }}</li>
                                            <li v-if="psw_manager.need_lower_case_char">{{ psw_manager.lower_case_message }}</li>
                                            <li v-if="psw_manager.need_number_char">{{ psw_manager.number_message }}</li>
                                            <li v-if="psw_manager.need_special_chars">contere almeno un carattere speciale tra <span v-for="item in psw_manager.special_chars" :key="'sc_' + item"><kbd>{{ item }}</kbd>&nbsp;</span></li>
                                        </ul>
                                    </div>
                    
                                    <div class="text-center" style="margin-top: 15px; margin-bottom: 15px;">
                                        <b-button variant="outline-success" :disabled="saving" role="submit" @click="manage_form">Salva</b-button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <!-- password modificata -->
                        <div v-else class="text-center">
                            <p>A breve sarai indirizzato<br/>alla pagina di login</p>
                            <b-spinner variant="primary" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner><br/>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <div class="alert alert-info text-center" role="alert" v-if="alert.type === 'valid-feedback'">
                            {{ alert.message }}
                        </div>
                        <div class="alert alert-danger text-center" role="alert" v-if="alert.type === 'invalid-feedback'">
                            {{ alert.message }}
                        </div>
                    </b-col>
                </b-row>


            </b-card>
            <div class="text-right"><small><em>vers. {{version}}</em><em v-if="env !== 'production'"> - {{ env }}</em></small></div>
        </b-col>
    </b-container>
</template>

<style>
#password-ul {
    padding-left: 20px; /* To remove default left padding */
}
</style>

