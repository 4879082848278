////////////////////////////////////////

//import axios from "axios";

import base_module from '@/vmm-store/modules/base';
//import vmm_store from '../vmm-store';

//const base_service = vmm_store.service;

const options = {
    model_name    : 'ddt',
    default_order : 'id ASC',
};

const state = {
    expand_collapse : false,
};

const actions = {
    set_expand_collapse( { commit }, value ) {
        commit( 'set_expand_collapse', value );
    },
    expand_collapse( { commit } ) {
        commit( 'expand_collapse' )
    }
};

const mutations = {
    set_expand_collapse( state, value ) {
        state.expand_collapse = value;
    },
    expand_collapse( state ) {
        if ( state.items ) {
            state.items.map( x => {
                if ( state.expand_collapse === true ) {
                    x._showDetails = true;
                } else {
                    delete ( x._showDetails );
                }
            });
        }
    },
};

const getters = {};

class new_module extends base_module {

    constructor ( new_options = {} ) {
        Object.keys( new_options ).map( x => {
            options[ x ] = new_options[ x ];
        });
        super( options );
    }

    static get_module( new_options = {} ) {
        var obj = new new_module( new_options );
        return {
            namespaced: true,
            state: {
                ...obj.state,
                ...state,
            },
            getters: {
                ...obj.getters,
                ...getters,
            },
            actions: {
                ...obj.actions,
                ...actions,
            },
            mutations: {
                ...obj.mutations,
                ...mutations,
            },
        }
    }

}

export default new_module;

