//////////////////////////////

module.exports = (type) => {

    var schema = {
        created: {
            type      : type.VIRTUAL,
            allowNull : true,
            range     : true,
        },
        customer_id: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'customers',
                key         : 'id',
                store_name  : 'customer',
            }
        },
        'user_roles.id': {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'roles',
                key         : 'id',
                store_name  : 'role',
            }
        },
//      action: {
//          type: type.STRING(64),
//          allowNull: true,
//      },
//      controller: {
//          type: type.STRING(64),
//          allowNull: true,
//      },
//      user_id: {
//          type: type.INTEGER,
//          allowNull: true,
//          references: {
//              model       : 'users',
//              key         : 'id',
//              store_name  : 'user',
//          }
//      },
//      tags: {
//          type: type.INTEGER,
//          allowNull: true,
//          references: {
//              model       : 'logtags',
//              key         : 'label',
//              store_name  : 'logtag',
//              key_label   : 'label',
//          }
//      },
    };

    return schema;
}

