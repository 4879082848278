/////////////////////////////////////////////////

//const module = require('./path_to_module.js');

class graphs {

    constructor( colors ) {
        this.colors = colors
    }

    // static public methods ////////////////////

    // static private methods ///////////////////

    // public methods ///////////////////////////

    line( data ) {
        return {
            xAxis: { data: data.map( x => x.label ) },
            yAxis: {},
            color: [ 'grey', ],
            tooltip: {
              formatter: function(params) {
                return (
                  new Intl.NumberFormat('it-IT', {}).format( params.data ) +
                  '<br/><small>' +
                  params.name + '</small>'
                );
              }
            },
            legend: {
                data: [
                    { name: 'Cicli', icon: 'circle' },
                ]
            },
            series: [
                {
                    name: 'Cicli',
                    data: data.map( x => x.count ),
                    type: 'line',
                    smooth: true,
                    lineStyle: { color: 'grey' },
                },
            ],
        };
    }

    donut( data ) {
        return {
            chart: {
                type: 'pie',
                //height: 120
            },
            credits: {
                enabled: false
            },
            title: false,
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false
                    },
                    borderColor: null,
                    borderWidth: 0,
                    showInLegend: true,
                    innerSize: '70%',
                    //size: 100,
                    states: {
                        hover: {
                            halo: {
                                size: 1
                            }
                        }
                    }
                }
            },
            colors: this.colors,
            tooltip: {
                pointFormat: '<b>{point.percentage:.1f}%</b>',
                style: {
                    color: '#495057',
                    fontSize: '15pt',
                },
            },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
                //itemStyle: {
                //    color: '#585858',
                //    fontWeight: 100,
                //    fontFamily: 'Montserrat'
                //},
                itemMarginBottom: 5,
                symbolRadius: 0
            },
            exporting: {
                enabled: false
            },
            series: data
        };
    }

    // private methods //////////////////////////

    // getters & setters ////////////////////////

    // static getters ///////////////////////////

}

export default graphs;

