
<script>

import { mapGetters } from 'vuex';

export default {
    name: 'Home',
    computed: {
        ...mapGetters( 'authentication', [ 'can_do' ] ),
    },
    created() {
        var redirect = null;
        if ( this.can_do('GET:/model/user') ) {
            redirect = '/list/user';
        } else if ( this.can_do('GET:/model/ddt') ) {
            redirect = '/list/ddt';
        }
        if ( !redirect ) { return; }
        this.$router.push( redirect );
    }
}

</script>

<template>
    <div class="home">
    </div>
</template>
