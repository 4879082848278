/////////////////////////////////////////////////

// escludere il campo che si chiama come la primaryKey

import field_manager from './field_manager';
import components    from './components';

class model_manager {

    constructor() {
        this._schema = null;
        this._component = null;
    }

    // static public methods ////////////////////

    // static private methods ///////////////////

    static STRING( max_length ) {
        var obj = new model_manager();
        obj.max_length   = max_length;
        obj.element_type = 'text';
        return obj;
    }

    static DECIMAL( precision, decimals ) {
        var obj = new model_manager();
        obj.precision    = precision;
        obj.decimals     = decimals;
        obj.element_type = 'integer';
        return obj;
    }

    // public methods ///////////////////////////

    override_schema( new_schema ) {
        this.schema.override( new_schema );
    }

    set_element_type() {
        if ( [ 'integer', 'text', ].includes( this.element_type ) ) {
            if ( this.schema.has_references ) {
                if ( this.schema.type === 'autocomplete' ) {
                    this.element_type = 'autocomplete';
                } else {
                    this.element_type = 'select';
                }
           }
        } else if ( this.element_type === 'virtual' ) {
            if ( this.schema.has_references ) { this.element_type = 'select'; }
            else if ( this.schema.range === true ) { this.element_type = 'range'; }
        } else if ( this.element_type === 'boolean' ) {
            if ( this.schema.allowNull === true ) {
                this.element_type = 'nullboolean';
                this.schema.references = { store_name: 'nullboolean' };
            }
        }
 
        this.schema.max_length = this.max_length;
        this.component = components[ 'bnv' + this.element_type ];
    }

    // private methods //////////////////////////

    // getters & setters ////////////////////////

    get max_length() { return this._max_length || null; }

    set max_length( value ) { this._max_length = value; }

    get element_type() { return this._element_type || null; }

    set element_type( value ) { this._element_type = value; }

    set schema( value ) { this._schema = value; }

    get schema() { return this._schema; }

    set component( value ) { this._component = value; }

    get component() {
        if ( this.schema.component ) {
            return this.schema.component;
        } else {
            return this._component;
        }
    }

    set name( value ) { this.schema.name = value; }

    get name() { return this.schema.name; }

    get label() { return this.schema.label; }

    // static getters ///////////////////////////

    static get INTEGER()  {
        var obj = new model_manager();
        obj.element_type = 'integer';
        return obj;
    }

    static get JSONB()  {
        var obj = new model_manager();
        obj.element_type = 'textarea';
        return obj;
    }

    static get DATEONLY()  {
        var obj = new model_manager();
        obj.element_type = 'date';
        return obj;
    }

    static get DATE()  {
        var obj = new model_manager();
        obj.element_type = 'date';
        return obj;
    }

    static get BOOLEAN()  {
        var obj = new model_manager();
        obj.element_type = 'boolean';
        return obj;
    }

    static get VIRTUAL()  {
        var obj = new model_manager();
        obj.element_type = 'virtual';
        return obj;
    }

    static get TEXT()  {
        var obj = new model_manager();
        obj.element_type = 'textarea';
        return obj;
    }

}

export default function( model, fe_conf, cmp_conf, record = {} ) {


    var res = {};
    var ordered_res = {};
    var obj = model( model_manager );
    var fields = Object.keys( obj );
    for ( var i = 0; i < fields.length; i++ ) {
        var name = fields[ i ];
        if ( name === 'settings' ) { continue; }
        // configurazione del backend
        var { type, ...schema } = obj[ name ];
        type.schema = new field_manager( schema );
        type.name   = name;
        // configurazione generica della form (al momento non sovrascrivibile da component)
        if ( fe_conf.settings ) {
            type.override_schema( fe_conf.settings );
        }
        // configurazione del frontend
        if ( fe_conf ) {
            type.override_schema( fe_conf[ name ] );
        }
        // configurazione del component
        if ( cmp_conf ) {
            type.override_schema( cmp_conf[ name ] );
        }
        // impostazione del component
        type.set_element_type();
        // defaultValue in caso di edit
        if ( record.hasOwnProperty( name ) ) {
            var input = {
                defaultValue : record[ name ],
                edit         : true,
            };
            type.override_schema( input );
        }
        var skip = false;
        if ( typeof type.schema.skip === 'boolean' ) { skip = type.schema.skip; }
        else if ( [ 'add', 'edit', ].includes( type.schema.skip ) ) {
            if ( type.schema.skip === 'edit' && record.id ) { skip = true; }
            else if ( type.schema.skip === 'add' && !record.id ) { skip = true; }
        }
        if ( type.component && skip !== true ) { res[ name ] = type; }
    }
    if ( fe_conf ) { // TODO ordinamento del component?
        Object.keys( fe_conf ).map( x => {
            if ( res[ x ] ) {
                ordered_res[ x ] = res[ x ];
            }
        });
    } else {
        ordered_res = res;
    }
    return ordered_res;

}

