<script>

const moment = require('moment-timezone');

export default {
    name: 'ic-doc-type',
    props: [ 'item' ],
    computed : {
        time_rilevazione() {
            if ( !this.item.time_rilevazione ) { return ''; }
            let obj = moment( this.item.time_rilevazione, 'YYYY-MM-DD HH:mm:ssz' );
            return [ obj.tz('Europe/Rome').format('DD/MM/YYYY'), obj.tz('Europe/Rome').format('HH:mm:ss') ];
        }
    },
    data() {
        return {
        }
    },
}

</script>

<template>
    <span v-if="!time_rilevazione" style="color: lightgrey">dato mancante</span>
    <p v-else-if="time_rilevazione" style="line-height: 16px; margin-bottom: 2px;">
        <small style="font-weight: 600;">{{ time_rilevazione[0] }}</small><br/>
        <small>{{ time_rilevazione[1] }}</small>
    </p>
</template>

<style>
</style>

