<script>

export default {
    name: 'bot-status',
    props: [ 'status_payload' ],
    components : {},
    computed: {
        denominazione() {
            if ( !this.status_payload ) { return null; }
            return this.status_payload.denominazione;
        },
        status_label() {
            if ( !this.status_payload ) { return null; }
            let status_label = this.status_payload.update_status === 'CSV scaricati' ? 'OK' : this.status_payload.update_status;
            return status_label || 'stato non trovato';
        },
        variant() {
            if ( !this.status_payload ) { return null; }
            if ( this.status_label === 'OK' ) { return 'success'; }
            return 'danger';
        },
        last_update() {
            if ( !this.status_payload ) { return null; }
            return this.status_payload.last_bot_update || 'non ci sono aggiornamenti';
        },
    },
    watch: {},
    methods: {},
    created() { console.log( this.status_payload ); },
    data() {
        return {
// stato clinte
// denominazione
// esito importazione documenti
// ultimo aggiornamento
        }
    },
}

</script>

<template>
    <!-- <div v-if="status_payload" style="border: 1px solid lightgrey; height: 90%; margin: 10px; margin-top: 0px; border-radius: 25px; padding: 10px;" class="text-center"> -->
        <b-card v-if="status_payload" style="" title="STATO CLIENTE" :sub-title="denominazione" :border-variant="variant">
            <b-card-text>
                <br/>
                <span style="font-weight: 700; font-size: 0.70em;">ESITO IMPORTAZIONE DOCUMENTI</span><br/>
                <b-badge :variant="variant" style="font-size: 0.85em">{{ status_label }}</b-badge>
            </b-card-text>
            <b-card-text>
                <span style="font-weight: 700; font-size: 0.70em;">ULTIMO AGGIORNAMENTO</span><br/>
                <b-badge variant="secondary" style="font-size: 0.85em">{{ last_update }}</b-badge>
            </b-card-text>
        </b-card>
        <!--
        <br/>
        <span style="font-weight: 700; font-size: 0.75em;">STATO DEL BOT</span><br/>
        <b-badge :variant="variant" style="font-size: 0.9em">{{ status_label }}</b-badge><br/><br/><br/>
        <span style="font-weight: 700; font-size: 0.75em;">ULTIMO AGGIORNAMENTO</span><br/>
        <b-badge variant="secondary" style="font-size: 0.9em">{{ last_update }}</b-badge>
        -->
    <!-- </div> -->
    <div v-else-if="!status_payload"></div>
</template>

<style>
</style>
