
<script>

import listpage from '../listpage';
import { mapActions } from 'vuex';

export default {
    name: 'ddt_listpage',
    extends: listpage,
    components: {},
    props: [],
    computed: {
        override_options() {
            var options = {
                initialFilter : this.filters,
            };
            return options;
        },
        params() { return this.store.params; },
        expand_collapse() {
            return this.$store.state._ddt_manager.expand_collapse;
        },
    },
    watch: {
        search( new_value ) {
            //console.log(`valore cambiato: "${ new_value }"`);
            if ( new_value === '' ) {
                this.$store.dispatch( '_ddt_manager/remove_filter', 'numero' );
            } else if ( new_value.length < 3 ) {
                return;
            } else {
                this.$store.dispatch( '_ddt_manager/add_filter', { numero: `|${ new_value }|` } );
            }
            this.$store.dispatch( '_ddt_manager/force_reload' );
            this.list_key += 1;
        },
        expand_collapse() {
            this.$store.dispatch( '_ddt_manager/expand_collapse' );
            this.list_key += 1;
        },
    },
    methods: {
        ...mapActions( '_ddt_manager', { set_params_order: 'set_params', add_filter: 'add_filter', remove_filter: 'remove_filter', force_reload: 'force_reload' } ),
        reload( options ) {
            setTimeout( () => {
                this.$store.dispatch( '_ddt_manager/get_all', { force_reload: true } );
                if ( options === 'manuale' ) {
                    this.make_toast( 'Ordine bloccato', `L'ordine è stato contrassegnato come "bloccato"` )
                } else {
                    this.make_toast( 'DDT Gestito', `Il  DDT è stato contrassegnato come "${ options }"` )
                }
            }, 250 );
        },
        make_toast(title, body) {
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
        toggle_modal(modal_id = 'modal-filter') {
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        clear_search_form( modal_event ) {
            if ( modal_event.trigger === 'clear' ) {
                Object.keys( this.filters ).map( x => {
                    this.remove_filter( x );
                    delete this.filters[ x ];
                });
                this.filters['completed'] = true;
                this.add_filter( { completed: true } );
                this.force_reload();
                this.list_key += 1;
                this.toggle_modal();
            }
        },
        operation_success( data ) {
            Object.keys( data ).map( x => {
                if ( data[ x ] === null || data[ x ] === '' ) {
                    this.remove_filter( x );
                    delete this.filters[ x ];
                } else {
                    this.add_filter( { [ x ]: data[ x ] } );
                    this.filters[ x ] = data[ x ];
                }
            });
            this.force_reload();
            this.list_key += 1;
            this.toggle_modal();
        },
        submit_search_form() {
            this.$refs.search_form.submit_form();
        },
        clear_search() {
            this.search = '';
        },
        value_changed( payload ) {
            var key = Object.keys( payload )[0];
            if ( [ 'date_managed__lte', 'date_managed__gte' ].includes( key ) && payload[ key ] !== '' ) {
                this.filters.completed = true;
                this.filters[ key ] = payload[ key ];
                this.modal_filter_key += 1;
            }
        },
        expand_all() {
            this.expandall = !this.expandall;
            this.$store.dispatch( '_ddt_manager/set_expand_collapse', this.expandall );
        },
    },
    created() {
        if ( this.params.hasOwnProperty('numero') ) { this.search = this.params.numero.replace(/\|/g, ''); }
        if ( this.params.hasOwnProperty('date_managed__gte') ) { this.filters.date_managed__gte = this.params.date_managed__gte; }
        if ( this.params.hasOwnProperty('date_managed__lte') ) { this.filters.date_managed__lte = this.params.date_managed__lte; }
        if ( this.params.hasOwnProperty('completed') ) { this.filters.completed = this.params.completed; }
        if ( this.params.hasOwnProperty('ddt_company_id') ) { this.filters.ddt_company_id = this.params.ddt_company_id; }
    },
    data() {
        return {
            filters          : { completed: true },
            list_key         : 0,
            modal_filter_key : 0,
            search_schema    : '_search_order',
            search           : '',
            expandall       : false,
        };
    }
}

</script>

<template>

    <div style="width: 80%; margin: auto">

        <b-row class="text-left">
            <b-col>
                <b-button variant="outline-success" @click="toggle_modal()" size="sm"><icon name="filter" style="color: #DC3545;" color="#DC3545" id="filter-icon"/></b-button><br/>
                <b-button :pressed="expandall" size="sm" @click="expand_all()" variant="outline-info" style="margin-top: 10px;">
                    espandi tutti
                </b-button>
            </b-col>
            <b-col>
                <h2 style="text-align: center; margin-bottom: 30px">DDT</h2>
            </b-col>
            <b-col class="text-right">
                <!-- <b-form-checkbox v-model="check_locked" name="check-locked" switch>
                    mostra ordini bloccati
                </b-form-checkbox> -->
                <b-input-group class="" size="sm">
                    <b-form-input
                        v-model     = "search"
                        placeholder = "numero DDT, cod.prod., descrizione"
                        debounce    = "600"
                    />
                    <b-input-group-append>
                        <b-button variant="outline-secondary" @click="clear_search()" :disabled="search === ''"><icon name="times"/></b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>

        <div>
            <vmm-list
                ref              = "listbnv"
                :key             = "list_key"
                :model           = "model"
                :options         = "override_options"
    
                :skip            = "false"
                :destroy         = "destroy"
                @reload          = "reload"
            />
        </div>

        <!-- modal filtri -->
        <b-modal
            id="modal-filter"
            title="Filtri"
            @hide="clear_search_form"
            button-size="sm"
            >
            <vmm-form
                v-if       = "search_schema"
                :key       = "modal_filter_key"
                :name      = "search_schema"
                :schema    = "$models._search_schema"
                :options   = "{}"
                :record    = "filters"
                :submit    = "false"
                :inline    = "true"
                ref        = "search_form"
                @submitted = "operation_success"
                @change    = "value_changed"
            ></vmm-form>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_search_form">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="warning" @click="hide('clear')">
                      Reset
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

    </div>

</template>

