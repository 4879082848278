///////////////////////////////////

// vmm-store
import store from './vmm-store';

// vmm-list
import './vmm-list';

// vmm-form
import vmm_form from './vmm-form';

// vmm-auth
import vmm_auth from './vmm-auth';

export default function() {
    vmm_form( store );
    vmm_auth( store );
    //console.log( store );
    return store;
}

