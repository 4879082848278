//////////////////////////////

module.exports = (type) => {
    var schema = {
        range: {
            type      : type.VIRTUAL,
            allowNull : false,
            range     : true,
        },
        automation_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'automations',
                key         : 'id',
                constraints : true,
                store_name  : 'automation',
            }
        }
    };

    return schema;
}

