<script>

//import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    name: 'ddt-rowsnumber',
    props: [ 'item' ],
    components : {},
    computed : {
        order_ids() {
            return this.item.ddtorder.map( x => x.id );
        },
        rows_number() {
            var tot = 0;
            this.item.ddt_items.map( x => { if ( this.order_ids.includes( x.ordine_id ) ) { tot += 1; } } );
            return tot;
        },
    },
    watch: {},
    methods : {
    },
    created() {},
    data() {
        return {
        }
    },
}

</script>

<template>
    <div>{{ rows_number }}</div>
</template>

<style>
</style>

