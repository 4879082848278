//////////////////////////////

module.exports = (type) => {

    /* **
     * X Silvio:
     * ho mantenuto il nome italiano dei campi poiché l'ho ereditato dall'automazione originale di Tommaso e Marco
     * ed ho immaginato che nel tempo, ci avrebbe dato dei grattacapi il non avere corrispondenza immediata tra i campi passati e quelli ineriti
     */

    var schema = {
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        ddt_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'ddts',
                key         : 'id',
                constraints : true,
                store_name  : 'ddt',
            }
        },
        ordine_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'orders',
                key         : 'id',
                constraints : true,
                store_name  : 'order',
            }
        },
        codiceProdotto: {
            type: type.STRING(32),
            allowNull: true,
        },
        unita: {
            type: type.STRING(10),
            allowNull: true,
        },
        quantita: {
            type: type.DECIMAL(10, 2),
            allowNull: true,
        },
        descrizione: {
            type: type.TEXT,
            allowNull: false,
        },
        prezzo: {
            type: type.DECIMAL(10, 2),
            allowNull: true,
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue : type.NOW,
        },
    };

    return schema;
}

