/////////////////////////////////////////////////

export default {

    PRIMARY_KEY      : 'id',
    INITIAL_FILTER   : {},
    SIZE             : null,
    DETAILS_SHOW     : true,
    DETAILS_ACTION   : 'button',
    EDIT             : false,
    EDIT_ACTION      : 'button',
    ROW_CLASS        : '',
    LINK_FUNCTION    : '',
    FORM_PATH        : 'form',
    DESTROY          : false,
    TABLE_ATTRIBUTES : {
        'striped'          : true,
        'no-local-sorting' : true,
        'no-sort-reset'    : true,
        'responsive'       : true,
        'selectable'       : false,
        'select-mode'      : 'single',
    },

};

