
<script>

import formpage from '@/views/formpage.vue';

export default {
    name: 'user-formpage',
    extends: formpage,
    methods: {
        saved( data ) {
            this.new_user = data;
            if ( !data.send_email ) {
                this.toggle_modal();
            } else {
                this.reset_new_user();
            }
        },
        reset_new_user() {
            this.new_user  = null;
            this.record    = {};
            this.form_key += 1;
        },
        toggle_modal(modal_id = 'modal-new-user') {
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
    },
    data() {
        return {
            new_user: null,
            form_key: 10000,
        };
    },
}

</script>

<template>

    <div>

        <vmm-form
            :key       = "form_key"
            v-if       = "loaded"
            :name      = "model"
            :options   = "{}"
            :record    = "record"
            :submit    = "true"
            :inline    = "false"
            ref        = "form"
            @saved     = "saved"
        />

        <!-- modal nuova pratica -->
        <b-modal
            id          = "modal-new-user"
            title       = "Utente creato"
            button-size = "sm"
            @hide       = "reset_new_user"
            >
            <table style="width: 90%; margin: auto;" v-if="new_user">
                <tr><td>Nome</td><td>{{ new_user.first_name }}</td></tr>
                <tr><td>Cognome</td><td>{{ new_user.last_name }}</td></tr>
                <tr><td>Email</td><td>{{ new_user.email }}</td></tr>
                <tr><td>Password</td><td style="font-weight: 800;">{{ new_user.password }}</td></tr>
            </table>
            <template v-slot:modal-footer="{ cancel }">
                <b-row><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

    </div>

</template>

