////////////////////////////////////////

import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import axios from 'axios';

// bootstrap vue
import './plugins/bootstrap-vue';

// theme
//import './plugins/theme';

// datepicker
import 'vue2-datepicker/index.css';

// vue-awesome
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon.js'
Vue.component('icon', Icon)

// vmm
import store from './plugins/vmm';

// routes
import router from './router';

// app
import App from './App.vue';

// service workers
import './registerServiceWorker';

Vue.config.productionTip = false


//axios.defaults.baseURL = config.baseURLApi; // TODO
//axios.defaults.headers.common['Content-Type'] = "application/json";
const token = localStorage.getItem('jwt');
//const user  = localStorage.getItem('user');
if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}


//if ( user ) {
//    store.dispatch('authentication/set_user', JSON.parse( user ));
//}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

