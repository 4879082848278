<script>

export default {
    name: 'bnvbase',
    props: [
        /*'elementtype',*/ 'name', /*'value', 'label', 'allownull',*/ 'errormessage', 'recordid', 'disabled',
        /*'readonly', 'edit', 'size', 'helper',*/ 'search', /*'minlength', 'maxlength',*/ 'precision', 'decimal', 'filter', 'config',/* 'maxvalue',*/ 
        'upload_progress', /*'displayLabel',*/ 'schema', 'layout', 'fields_per_row'
    ],
    components: {},
    computed: {
        // new
        label()        { return this.config.label; },
        allownull()    { return this.config.allowNull },
        size()         { return this.config.size; },
        helper()       { return this.config.helper; },
        placeholder()  { return this.config.placeholder; },
        value()        { return this.config.defaultValue; },
        edit()         { return this.config.edit; },
        maxlength()    { return this.config.maxLength; },
        minlength()    { return this.config.minLength; },
        maxvalue()     { return this.config.maxValue; },
        minvalue()     { return this.config.minValue; },
        displayLabel() { return typeof this.config.displayLabel === 'boolean' ? this.config.displayLabel : true; },
        _readonly () {
            if ( this.config.readonly === true ) { return true; }
            if ( this.config.readonly === 'edit' && this.edit === true ) { return true; }
            if ( this.config.readonly === 'add' && this.edit === false ) { return true; }
            return false;
        },
        //
        input_class() {
            if ( this.size === 'sm' ) { return 'form-control-sm'; }
            if ( this.size === 'lg' ) { return 'form-control-lg'; }
            return '';
        },
        _helper() {
            if ( this.helper ) {
                return this.helper.replace(/\\n/g, '<br/>');
            }
            return undefined;
        },
        // layout condensed
        field_class() {
            return ( this.layout === 'condensed' ) ? 'row' : '';
        },
        _fields_per_row() {
            return this.fields_per_row ? parseInt( this.fields_per_row ) : 1;
        },
        field_style() {
            return ( this.layout === 'condensed' ) ? { 'margin-bottom': '0.1rem' } : { 'margin-bottom': '0.4rem' };
        },

        label_class() {
            var _class = 'bnv-label '
            if ( this.layout === 'condensed' ) { _class += this._fields_per_row === 1 ? "col-sm-1" : "col-sm-2"; _class += ' double-row' }
            _class += ' col-form-label';
            if ( this.size === 'sm' ) { _class += ' col-form-label-sm'; }
            if ( this.size === 'lg' ) { _class += ' col-form-label-lg'; }
            if ( this.elements_row ) { /*console.log( _class, this.elements_row );*/ } 
            return _class;
        },
        element_container_class() {
            if ( this.layout !== 'condensed' ) { return ''; }
            return this._fields_per_row === 1 ? 'col-sm-11' : 'col-sm-10';
        }
    },
    data () {
        return {
            model           : null,
            error_message   : null,
            error_component : false,
            confirm_message : null,
        }
    },
    created () {
        //console.log( this.max_length );
        this.model = typeof this.value === 'undefined' || this.value === null ? '' : this.value;
    },
    methods: {
        get_label: function() { // default su field_manager
            return typeof this.label === 'undefined' ? this.name : this.label;
        },
        get_placeholder: function() { // default su field_manager
            return typeof this.placeholder === 'undefined' ? undefined : this.placeholder;
        },
        set_value: function (value) { // public
            if ( !this.model ) {
                this.model = value;
            }
        },
        get_value: function() { // public
            this.error_component = false;
            this.error_message = null;
            var check = this._validate();
            if ( check === false ) { return undefined; }
            var value = this._return_model();
            if ( value === null ) { return null; }
            if ( this.search ) {
                var res = { value, search: null, };
                if ( this.search === 'contains_before_after' ) { res.search = value ? `|${ value }|` : null; }
                return res;
            }
            return value;
        },
        _return_model() {
            return this.model ? this.model : null;
        },
        reseta() { // public - experimental 14/03/20
            this.model = '';
        },
        _set_error( error ) { 
            this.error_component = true;
            this.error_message   = error;
            return false;
        },
        _validate: function() {
            try {
                this._validate_allow_null();
            } catch ( error ) {
                return this._set_error( error );
            }
            return true;
        },
        _validate_allow_null: function() {
            if ( this.allownull === false && ( this.model === '' || typeof this.model === 'undefined' || this.model === null ) ) {
                throw 'campo obbligatorio';
            }
        },
        get_error_message() {
            if ( typeof this.errormessage !== 'undefined' ) {
                this.error_message = this.errormessage;
                this.error_component = true;
            }
            return this.error_message;
        },
        set_confirm_message(text) {
            this.confirm_message = text;
            setTimeout( () => {
                this.confirm_message = null;
            }, 3000)
        },
        value_changed() {
            var payload = {};
            payload[ this.name ] = this.model;
            this.$emit('change', payload );
        },
        enter_key() {
            this.$emit('enter_key');
        },
    },
};

</script>

<template>
    <div/>
</template>

<style>
    .double-row {
        line-height: 10pt;
    }
</style>


