<script>

export default {
    name: 'ic-doc-type',
    props: [ 'item' ],
    computed : {
        doc_type() {
            let doc_type = this.item.invoice_consistency_doc_type.label;
            if ( this.item.config.doc_type && this.item.config.doc_type === 'doc_online' ) {
                doc_type += ' (Doc Online)';
            }
            return doc_type;
        }
    },
    data() {
        return {
        }
    },
}

</script>

<template>
    <span>{{ doc_type }}</span>
</template>

<style>
</style>

