//////////////////////////////

module.exports = (type) => {
    var schema = {
        label: {
            type: type.STRING(64),
            allowNull: false,
            unique: true,
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue: type.NOW,
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

