////////////////////

// TODO: praticamente da eliminare, al momento restituisce solo un url
//       potrebbe diventare un helper

import { auth_header } from './helpers';

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export default {
    get_by_id,
    get_all,
    create,
    createws,
    update,
    handleResponse,
    destroy,
};

function destroy( model, id ) {
    var url = `${config.backend_url}model/${ model }/${ id }`;
    var options = {
        method  : 'DELETE',
        headers : auth_header(),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then( obj => {
            return obj;
        });
}

function createws( schema, data, include = false) {
    var url = `${config.backend_url}model/${schema}/ws`;
    if ( include ) { url += `?include=true` }
    //var payload = {};
    const form = new FormData();
    Object.keys( data ).map( x => {
        form.append(x, data[ x ]);
    });
    var options = {
        method  : 'POST',
        //headers : { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json', },
        headers : auth_header( true ),
        body    : form,
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function get_by_id( schema, id/*, include = null*/ ) {
    //var url = `${config.backend_url}model/${schema}/${id}`;
    //if ( include ) { url += '?include=true'; }
    var options = {
        method  : 'GET',
        headers : auth_header(),
    };
    return fetch(`${config.backend_url}model/${schema}/${id}?include=true`, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function get_all( backend_url, schema, data = null ) {
    var url = `${ backend_url }/model/${schema}`;
    if ( data ) {
        var params = [];
        Object.keys( data ).map( x => {
            var data_encoded = encodeURIComponent( data[ x ] );
            params.push( `${x}=${ data_encoded }` );
        });
        if ( params.length > 0 ) {
            url += '?' + params.join('&');
        }
    }
    return url;
//  var options = {
//      method  : 'GET',
//      headers : auth_header(),
//  };
//  return fetch(url, options)
//      .then(handleResponse)
//      .then(msg => {
//          return msg;
//      });
}

function create( schema, data, include = false) {
    var url = `${config.backend_url}model/${schema}`;
    if ( include ) { url += `?include=true` }
    var payload = {};
    Object.keys( data ).map( x => {
        payload[ x ] = data[ x ];
    });
    var options = {
        method  : 'POST',
        headers : auth_header(),
        body    : JSON.stringify( payload ),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function update( schema, id, data, include ) {
    var url = `${config.backend_url}model/${schema}/${id}`;
    if ( include ) { url += `?include=true` }
    var payload = {};
    Object.keys( data ).map( x => {
        payload[ x ] = data[ x ];
    });
    var options = {
        method  : 'PATCH',
        headers : auth_header(),
        body    : JSON.stringify( payload ),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                location.reload(true);
            }

            const error = data || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}


