///////////////////////////////

import user_roles              from './user/roles.vue';
import attribute_value         from './attribute/value.vue';
import ddt_rowsnumber          from './ddt/rowsnumber.vue';
import ddt_status              from './ddt/status.vue';
import ddt_date_managed        from './ddt/date_managed';
import ddt_numero_manager      from './ddt/numero_manager.vue';
import ddt_numero_magazziniere from './ddt/numero_magazziniere.vue';
import log_type                from './log/type.vue'
import log_tags                from './log/tags.vue'
import inv_app_state           from './invoice_approval/state';
import inv_app_checkbox        from './invoice_approval/checkbox';
import inv_app_modpag          from './invoice_approval/modalita_pagamento';
import ic_doc_type             from './invoice_consistency/doc_type';
import ic_time_rilevazione     from './invoice_consistency/time_rilevazione';

export default {
    user_roles,
    attribute_value,
    ddt_rowsnumber,
    ddt_status,
    ddt_date_managed,
    ddt_numero_manager,
    ddt_numero_magazziniere,
    log_type,
    log_tags,
    inv_app_state,
    inv_app_checkbox,
    inv_app_modpag,
    ic_doc_type,
    ic_time_rilevazione,
};

