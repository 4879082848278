//////////////////////////////

module.exports = (type) => {

    var schema = {
        file_name: { type: type.STRING(255), allowNull: false },
        row_number: { type: type.INTEGER, allowNull: false },
        codice_azienda: { type: type.STRING(255), defaultValue: null },
        esercizio_iva: { type: type.STRING(255), defaultValue: null },
        numero_partita: { type: type.STRING(255), defaultValue: null },
        registro: { type: type.STRING(255), defaultValue: null },
        sezionale: { type: type.STRING(255), defaultValue: null },
        data_operazione: { type: type.STRING(255), defaultValue: null },
        protocollo: { type: type.STRING(255), defaultValue: null },
        data_documento: { type: type.DATEONLY, allowNull: false },
        numero_documento: { type: type.STRING(255), defaultValue: null },
        documento_esteso: { type: type.STRING(255), defaultValue: null },
        totale_documento: { type: type.STRING(255), defaultValue: null },
        competenza_iva: { type: type.STRING(255), defaultValue: null },
        pagamento_avvenuto: { type: type.STRING(255), defaultValue: null },
        rr: { type: type.STRING(255), defaultValue: null },
        codice_clifor: { type: type.STRING(255), defaultValue: null },
        ragione_sociale: { type: type.STRING(255), defaultValue: null },
        codice_fiscale: { type: type.STRING(255), defaultValue: null },
        partita_iva: { type: type.STRING(255), defaultValue: null },
        causale_testata: { type: type.STRING(255), defaultValue: null },
        descrizione_causale_testata: { type: type.STRING(255), defaultValue: null },
        annotazioni: { type: type.STRING(255), defaultValue: null },
        centro_di_costo: { type: type.STRING(255), defaultValue: null },
        codice_conto: { type: type.STRING(255), defaultValue: null },
        quarto_livello: { type: type.STRING(255), defaultValue: null },
        descrizione_conto: { type: type.STRING(255), defaultValue: null },
        dare: { type: type.STRING(255), defaultValue: null },
        avere: { type: type.STRING(255), defaultValue: null },
        imponibile: { type: type.STRING(255), defaultValue: null },
        codice_iva: { type: type.STRING(255), defaultValue: null },
        descr_codice_iva: { type: type.STRING(255), defaultValue: null },
        imposta: { type: type.STRING(255), defaultValue: null },
        merci: { type: type.STRING(255), defaultValue: null },
        bdr: { type: type.STRING(255), defaultValue: null },
        detrazione: { type: type.STRING(255), defaultValue: null },
        importo_detraibile: { type: type.STRING(255), defaultValue: null },
        importo_indetraibile: { type: type.STRING(255), defaultValue: null },
        causale_rigo: { type: type.STRING(255), defaultValue: null },
        descrizione_causale_rigo: { type: type.STRING(255), defaultValue: null },
        causale_analitica: { type: type.STRING(255), defaultValue: null },
        descrizione_causale_analitica: { type: type.STRING(255), defaultValue: null },
        data_oper_rr: { type: type.STRING(255), defaultValue: null },
        data_doc_rr: { type: type.STRING(255), defaultValue: null },
        numero_doc_rr: { type: type.STRING(255), defaultValue: null },
        inizio_esercizio_rr: { type: type.STRING(255), defaultValue: null },
        fine_esercizio_rr: { type: type.STRING(255), defaultValue: null },
        conto_costo_ricavo_rr: { type: type.STRING(255), defaultValue: null },
        descr_conto_rr: { type: type.STRING(255), defaultValue: null },
        importo_rr: { type: type.STRING(255), defaultValue: null },
        segno_rr: { type: type.STRING(255), defaultValue: null },
        conto_rr: { type: type.STRING(255), defaultValue: null },
        data_inizio_rr: { type: type.STRING(255), defaultValue: null },
        data_fine_rr: { type: type.STRING(255), defaultValue: null },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

