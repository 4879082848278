/////////////////////////////////////////////////

class filters {

    constructor() {
        this.schema_name = 'invoice_consistency';
        this.reload      = false;
        this.store       = null;
    }

    // public methods ///////////////////////////

    set_filters( payload ) {
        this._set_params( { original_filters: JSON.parse( JSON.stringify( payload ) ) } )
        let filters = {};
        // campi standard
        this.fields.map( x => {
            if ( payload[ x ] ) {
                filters[ x ] = payload[ x ];
            }
        });
        // tipo documento
        if ( payload.tipo_documento ) {
            //filters.tipo_documento = this.doc_types.get( payload.tipo_documento );
            filters.tipo_documento = payload.tipo_documento;
        }
        // data documento
        if ( payload.data_fattura_da || payload.data_fattura_a ) {
            if ( payload.data_fattura_da && payload.data_fattura_a ) {
                filters.data_fattura = `${ payload.data_fattura_da }|${ payload.data_fattura_a }`;
            } else if ( payload.data_fattura_da ) {
                filters.data_fattura__gte = payload.data_fattura_da;
            } else if ( payload.data_fattura_a ) {
                filters.data_fattura__lte = payload.data_fattura_a;
            }
        }
        // data consegna / ricezione
        if ( payload.data_consegna_da || payload.data_consegna_a ) {
            if ( payload.data_consegna_da && payload.data_consegna_a ) {
                filters.data_consegna = `${ payload.data_consegna_da }|${ payload.data_consegna_a }`;
            } else if ( payload.data_consegna_da ) {
                filters.data_consegna__gte = payload.data_consegna_da;
            } else if ( payload.data_consegna_a ) {
                filters.data_consegna__lte = payload.data_consegna_a;
            }
        }
        this._set_filters( filters );
        this._reload();
    }

    remove_filter( label ) {
        console.log( { label } );
        this.store.dispatch( `${ this.schema_name }/remove_param`, label );
    }

    // private methods //////////////////////////

    _set_params( params ) {
        this.store.dispatch( `${ this.schema_name }/set_params`, params );
    }

    _set_filters( filters ) {
        this.store.dispatch( `${ this.schema_name }/set_filters`, filters );
    }

    _reload() {
        this.store.dispatch( `${ this.schema_name }/force_reload` );
        this.reload = true;
        setTimeout( () => { this.reload = false; }, 200 );
    }

    // getters & setters ////////////////////////

    get doc_types() {
        let types = this.store.state._invoice_consistency_filter_tipo_documento_select.items;
        let res = new Map();
        types.map( x => res.set( x.id, x.label ) );
        return res;
    }

    get original_filters() {
        return this.store.state[ this.schema_name ].original_filters;
    }

    get fields() {
        return [
            'piva_cliente,cf_cliente', 'piva_emittente,cf_emittente', 'piva_cliente', 'piva_emittente', 'cf_cliente', 'cf_emittente',
            'id_paese_cedente','id_paese_cessionario', 'presente_in_TS', 'cont_corretta', 'nota_di_credito', 'idsdi_mancante',
        ];
    }

}

export default filters;

