/////////////////////////////////////////////////

const MIN_PASSWORD_LENGTH   = 8;
const MAX_PASSWORD_LENGTH   = 20;
const MIN_UPPER_CASE_CHAR   = 1;
const MIN_LOWER_CASE_CHAR   = 1;
const MIN_NUMBER_CHAR       = 1;
const ALLOWED_SPECIAL_CHARS = [ '!', '$', '*', '?', '%', '@', '£' ];

class password {

    constructor( settings = {} ) {
        this.settings = settings;
        this.reset();
    }

    // public methods ///////////////////////////

    validate( password, confirm_password ) {
        this.reset( password, confirm_password );
        try {
            this._validate();
        } catch ( error ) {
            this.is_valid = false;
            console.log( error.toString() );
            this.new_password_error = error.toString();
        }
    }

    reset( new_password, confirm_password ) {
        this.password               = new_password || '';
        this.confirm_password       = confirm_password || '';
        this.is_valid               = true;
        this.new_password_error     = null;
        this.confirm_password_error = null;
    }

    // private methods //////////////////////////

    _validate() {
        this._validate_empty();
        if ( !this.is_valid ) { return; }
        this._validate_num_char();
        this._validate_uppercase();
        this._validate_lowercase();
        this._validate_numbers();
        this._validate_special_chars();
        this._validate_match();
    }

    _validate_empty() {
        if ( !this.password ) {
            this.new_password_error = 'campo obbligatorio';
            this.is_valid = false;
        }
        if ( !this.confirm_password ) {
            this.confirm_password_error = 'campo obbligatorio';
            this.is_valid = false;
        }
    }

    _validate_num_char() {
        if ( this.password.length < this.min_password_length ) {
            throw 'la password deve ' + this.min_password_label;
        }
        if ( this.password.length > this.max_password_length ) {
            throw 'la password deve ' + this.max_password_label;
        }
    }

    _validate_uppercase() {
        if ( !this.need_upper_case_char ) { return; }
        let match = this.password.match(/[A-Z]/g);
        if ( !match || match.length < this.min_upper_case_char ) {
            throw 'la password deve ' + this.upper_case_message;
        }
    }

    _validate_lowercase() {
        if ( !this.need_lower_case_char ) { return; }
        let match = this.password.match(/[a-z]/g);
        if ( !match || match.length < this.min_lower_case_char ) {
            throw 'la password deve ' + this.lower_case_message;
        }
    }

    _validate_numbers() {
        if ( !this.need_number_char ) { return; }
        let match = this.password.match(/[0-9]/g);
        if ( !match || match.length < this.min_number_char ) {
            throw 'la password deve ' + this.number_message;
        }
    }

    _validate_special_chars() {
        if ( !this.need_special_chars ) { return; }
        if ( !this.special_chars.some( sc => this.password.includes( sc ) ) ) {
            throw 'la password deve ' + this.special_chars_message;
        }
    }

    _validate_match() {
        if ( this.password !== this.confirm_password ) {
            throw 'le password non coincidono';
        }
    }

    // getters & setters ////////////////////////

    // password length

    get min_password_length() {
        return this.settings.min_password_length || MIN_PASSWORD_LENGTH;
    }

    get min_password_label() {
        return `essere lunga almeno ${ this.min_password_length } caratteri`;
    }

    get max_password_length() {
        return this.settings.max_password_length || MAX_PASSWORD_LENGTH;
    }

    get max_password_label() {
        return `essere lunga al massimo ${ this.max_password_length } caratteri`;
    }

    // uppercase chars

    get min_upper_case_char() {
        return this.settings.hasOwnProperty('min_upper_case_char') ? this.settings.min_upper_case_char : MIN_UPPER_CASE_CHAR;
    }

    get need_upper_case_char() {
        return this.min_upper_case_char > 0;
    }

    get upper_case_message() {
        return `contenere almeno ${ this.min_upper_case_char === 1 ? 'un' : this.min_upper_case_char } caratter${ this.min_upper_case_char === 1 ? 'e' : 'i' } maiuscol${ this.min_upper_case_char === 1 ? 'o' : 'i' }`;
    }

    // lowercase chars

    get min_lower_case_char() {
        return this.settings.hasOwnProperty('min_lower_case_char') ? this.settings.min_lower_case_char : MIN_LOWER_CASE_CHAR;
    }

    get need_lower_case_char() {
        return this.min_lower_case_char > 0;
    }

    get lower_case_message() {
        return `contenere almeno ${ this.min_lower_case_char === 1 ? 'un' : this.min_lower_case_char } caratter${ this.min_lower_case_char === 1 ? 'e' : 'i' } minuscol${ this.min_lower_case_char === 1 ? 'o' : 'i' }`;
    }

    // number chars

    get min_number_char() {
        return this.settings.hasOwnProperty('min_number_char') ? this.settings.min_number_char : MIN_NUMBER_CHAR;
    }

    get need_number_char() {
        return this.min_number_char > 0;
    }

    get number_message() {
        return `contenere almeno ${ this.min_number_char === 1 ? 'un' : this.min_number_char } numer${ this.min_number_char === 1 ? 'o' : 'i' }`;
    }

    // special chars

    get special_chars() {
        return this.settings.allowed_special_chars || ALLOWED_SPECIAL_CHARS;
    }

    get need_special_chars() {
        return this.special_chars.length > 0;
    }

    get special_chars_message() {
        return 'contenere almeno un carattere speciale';
    }

    // settings

    get psw_settings() {
        return {
            min_password_length   : this.min_password_length,
            max_password_length   : this.max_password_length,
            min_upper_case_char   : this.min_upper_case_char,
            min_lower_case_char   : this.min_lower_case_char,
            min_number_char       : this.min_number_char,
            allowed_special_chars : this.special_chars,
        };
    }

    set psw_settings( payload ) {
        this.settings = payload;
    }

}

module.exports = password;

