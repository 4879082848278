//////////////////////////////

module.exports = function( type ) {

    var schema = {
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        invoice_approval_state_id: {
            type: type.INTEGER,
            defaultValue : 1,
            references: {
                model       : 'invoice_approval_states',
                key         : 'id',
                constraints : true,
                store_name  : 'invoice_approval_state',
            }
        },
        fornitore_piva: {
            type: type.STRING(64),
            allowNull : false,
        },
        fornitore_cf: {
            type: type.STRING(64),
        },
        fornitore_denominazione: {
            type: type.STRING(128),
            allowNull : false,
        },
        cliente_piva: {
            type: type.STRING(64),
            allowNull : false,
        },
        cliente_cf: {
            type: type.STRING(64),
        },
        cliente_denominazione: {
            type: type.STRING(128),
            allowNull : false,
        },
        numero: {
            type: type.STRING(64),
            allowNull : false,
        },
        data: {
            type: type.DATEONLY,
            allowNull : false,
        },
        divisa: {
            type: type.STRING(64),
            allowNull : false,
        },
        importo: {
            type: type.DECIMAL(10,2),
            allowNull : false,
        },
        imponibile: {
            type: type.DECIMAL(10,2),
            allowNull : false,
        },
        imposta: {
            type: type.DECIMAL(10,2),
            allowNull : false,
        },
        iva: {
            type: type.DECIMAL(10,2),
            allowNull : false,
        },
        modalita_pagamento: {
            type: type.STRING(64),
            allowNull : true,
        },
        data_riferimento_termini_pagamento: {
            type: type.DATEONLY,
            allowNull : true,
        },
        data_scadenza_pagamento: {
            type: type.DATEONLY,
            allowNull : true,
        },
        nota_di_credito: {
            type: type.BOOLEAN,
            allowNull : false,
        },
        original_obj: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue : type.NOW,
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

