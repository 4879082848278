
<script>

import fabButton  from '@/components/Layout/fab_button';
import listpage   from '../listpage';
import Filters    from './filters';
import bot_status from './bot_status';
import { mapState } from 'vuex';

export default {
    name: 'invoice-consistency-listpage',
    extends: listpage,
    components: { fabButton, 'bot-status': bot_status },
    computed: {
        filters() {
            return this.$store.state.invoice_consistency.filters;
        },
        reload() {
            return this.filters_manager.reload;
        },
        tot_rows() { return this.number( this.$store.state.invoice_consistency.tot ); },
        tot_imponibile() { return this.currency( this.$store.state.invoice_consistency.tot_imponibile ); },
        tot_imposta() { return this.currency( this.$store.state.invoice_consistency.tot_imposta ); },
        tot_importo() { return this.currency( this.$store.state.invoice_consistency.tot_importo ); },
        cont_corretta() { return this.number(  this.$store.state.invoice_consistency.cont_corretta ); },
        bot_status() { return this.$store.state.invoice_consistency.bot_status; },
        ...mapState('invoice_consistency', {
            report_loader  : state => state.report_loader,
            report_message : state => state.report_message,
        }),
    },
    watch: {
        reload( new_value ) {
            if ( new_value ) { this.main_key += 1; }
        },
    },
    methods: {
        export_search() {
            // apro il toast
            this.$bvToast.show('report-toast');
            // chiamata allo store
            // lo store si chiude solo manualmente
        },
        start_make_report() {
            this.$store.dispatch( 'invoice_consistency/export_csv' );
        },
        reset_report() {
            this.$store.dispatch( 'invoice_consistency/export_reset' );
        },
        make_toast(title, body) {
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 5000,
                appendToast   : true,
            });
        },
        filter() {
            var data = this.$refs.search_form.get_form_data();
            //console.log( { data } );
            this.filters_manager.set_filters( data );
        },
        currency( value ) {
            return new Intl.NumberFormat('it-IT', {
                style: 'currency',
                currency: 'EUR',
            }).format( value );
        },
        number( value ) {
            return new Intl.NumberFormat( 'it-IT' ).format( value );
        },
        reset_search() {
            this.filters_manager.set_filters( {} );
            this.form_key += 1;
        },
        filter_modified( value ) {
            //console.log( value );
            let keys = Object.keys( value );
            if ( !value[ keys[0] ] ) {
                this.filters_manager.remove_filter( keys[0] );
            }
        },
    },
    created() {
        this.filters_manager.store = this.$store;
    },
    data() {
        return {
            main_key        : 0,
            filters_manager : new Filters(),
            form_key        : 100,
            report_title    : 'EXPORT CSV',
        };
    }
}

</script>

<template>

    <div style="width: 80%; margin: auto">
        <h2 v-if="options.title_list" style="text-align: center; margin-bottom: 30px">{{ options.title_list.toUpperCase() }}</h2>

        <b-row>
            <b-col cols="9">
                <vmm-form
                    :key       = "form_key"
                    name       = "_invoice_consistency_filter"
                    :options   = "{}"
                    :record    = "filters_manager.original_filters"
                    :submit    = "false"
                    :inline    = "true"
                    ref        = "search_form"
                    @change    = "filter_modified"
                />
            </b-col>
            <b-col cols="3">
                <bot-status :status_payload="bot_status" />
            </b-col>
        </b-row>

        <b-row style="margin-bottom: 20px">
            <b-col style="text-align: center; line-height: 1.1; font-size: 1.2em"><span class="span-label">TOT RECORD</span><br/><b-badge>{{ tot_rows }}</b-badge></b-col>
            <b-col style="text-align: center; line-height: 1.1; font-size: 1.2em"><span class="span-label">CONT. CORRETTA</span><br/><b-badge>{{ cont_corretta }}</b-badge></b-col>
            <b-col style="text-align: center; line-height: 1.1; font-size: 1.2em"><span class="span-label">IMPONIBILE</span><br/><b-badge>{{ tot_imponibile }}</b-badge></b-col>
            <b-col style="text-align: center; line-height: 1.1; font-size: 1.2em"><span class="span-label">IMPOSTA</span><br/><b-badge>{{ tot_imposta }}</b-badge></b-col>
            <b-col style="text-align: center; line-height: 1.1; font-size: 1.2em"><span class="span-label">IMPORTO</span><br/><b-badge>{{ tot_importo }}</b-badge></b-col>
        </b-row>

        <vmm-list
            :key             = "main_key"
            ref              = "listbnv"
            :model           = "model"
            :options         = "override_options"

            :skip            = "false"
            :destroy         = "destroy"

            @row_selected    = "row_selected"
            @right-click     = "right_click"
        />

        <b-toast
            no-auto-hide
            id       = "report-toast"
            :title   = "report_title"
            :variant = "report_message.variant"
            :solid   = "true"
            toaster  = "b-toaster-bottom-right"
            @shown   = "start_make_report"
            @hidden  = "reset_report"
            >
			<span v-if="report_message.hasOwnProperty('url') === false">{{ report_message.action }}</span><a v-if="report_message.hasOwnProperty('url')" :href="report_message.url" target="_blank">{{ report_message.file_name }}</a>
<b-spinner v-if="report_loader" variant="primary" label="Spinning" style="float: right;" small></b-spinner>
        </b-toast>

        <fab-button
            main_icon = "filter_list"
            bg_color  = "#218838"
            @save     = "filter"
            @reset    = "reset_search"
            @export   = "export_search"
        />
    </div>

</template>

<style>
    .span-label {
        font-weight: 700;
        font-size: 0.845rem;
    }
</style>

