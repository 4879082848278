//////////////////////////////

module.exports = (type) => {

    var schema = {
        hash: {
            type: type.STRING(32),
            allowNull: false,
        },
        fields: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: [],
        },
        type: {
            type: type.ENUM('fattura','corrispettivo'),
            allowNull: false,
        },
        enabled: {
            type: type.BOOLEAN,
            defaultValue: true
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue : type.NOW,
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

