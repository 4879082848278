<script>

import bnvselect from './bnvselect.vue';

export default {
    extends: bnvselect,
    computed: {
        store_name() { return `nullboolean` }
    },
};

</script>

<template>
    <!-- il template di bnvselect va ripetuto -->
    <div>
        <div class="form-group" :class="field_class" :style="field_style" v-if="options.loading === false">
            <label :for="'bnv-form-' + name" class="col-form-label" :class="label_class">{{ get_label() }}</label>
            <!-- <div class="input-group mb-3"> -->
            <div :class="element_container_class">
                <b-select
                    ref="input"
                    class="form-control"
                    :size="input_class"
                    v-bind:class="{ 'is-invalid': error_component }"
                    :aria-label="name"
                    :aria-describedby="name"
                    :id="'bnv-form-' + name"
                    v-model="model"
                    :disabled="disabled || options.loading"
                    :readonly="_readonly"
                    @change="value_changed"
                    >
                    <option value="">- - - - - - -</option>
                    <option v-for="option in options.items" v-bind:key="'bnv-form-' + name + '-' + option.id" :value="option.id">{{ option.label }}</option>
                </b-select>
                <div class="invalid-feedback">
                    {{ get_error_message() }}
                </div>
            </div>
        </div>
        <div class="form-group" :class="field_class" :style="field_style" v-if="options.loading === true">
            <label :for="'bnv-form-loader-' + name">{{ get_label() }}</label>
            <div :class="element_container_class">
                <input
                    type="text"
                    class="form-control spinner"
                    placeholder="caricamento opzioni..."
                    :id="'bnv-form-loader-' + name"
                    :disabled="true"
                />
            </div>
        </div>
    </div>

</template>



