//////////////////////////////

module.exports = function( type ) {

    var schema = {
        invoice_approval_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'invoice_approval',
                key         : 'id',
                constraints : true,
                store_name  : 'invoice_approval',
            }
        },
        order : {
            type: type.INTEGER,
            allowNull: false
        },
        descrizione : {
            type: type.STRING(1024),
            allowNull: false
        },
        qta : {
            type: type.DECIMAL(10,2),
            allowNull: false,
            defaultValue: 1
        },
        prezzo_unitario : {
            type: type.DECIMAL(10,2),
            allowNull: false
        },
        prezzo_totale : {
            type: type.DECIMAL(10,2),
            allowNull: false
        },
        iva : {
            type: type.DECIMAL(10,2),
            allowNull: false
        },
        udm : {
            type: type.STRING(64),
            allowNull: true
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue : type.NOW,
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

