<script>

import menuBar from '@/components/Layout/MenuBar';
import { /*mapActions,*/ mapState, /*mapGetters*/ } from 'vuex';

export default {
    name: 'app',
    computed: {
        ...mapState('authentication', {
            is_authenticated : state => state.is_authenticated,
        }),
    },
    watch: {
        is_authenticated() { this.menubar_key += 1; },
    },
    created() {},
    components: { menuBar },
    filters: {
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    },
    data() {
        return {
            menubar_key : 0,
        };
    },
}

</script>

<template>

    <div id="cnt2">
        <menu-bar :key="menubar_key" v-if="is_authenticated"></menu-bar>
        <b-container style="margin-top: 70px" fluid>
            <router-view :key="$route.fullPath" />
        </b-container>
    </div>

</template>

<style>
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
</style>

