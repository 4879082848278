<script>

import filters from '../filters';

export default {
    name: 'detail-card',
    props: [ 'row', 'display', ],
    components: {},
    computed: {},
    created () {},
    methods: {
        get_name( obj ) {
            if ( typeof obj === 'string' ) { return obj; }
            if ( obj.hasOwnProperty('label') ) {
                return obj.label;
            }
            return obj.key;
        },
        _get_value_from_string( str, obj ) {
            if ( obj === null ) { return null; }
            if ( str.includes('.') ) {
                var tokens = str.split('.');
                var value = obj;
                for ( var i = 0; i < tokens.length; i++ ) {
                    if ( !value || value.hasOwnProperty( tokens[ i ] ) === false ) { return null; }
                    value = value[ tokens[ i ] ];
                }
                return value;
            }
            return obj[ str ];
        },
        filter( obj ) {
            if ( typeof obj === 'string' ) { return this.set_mancante( this.row[ obj ] ); }
            //var res = null;
            if ( obj.hasOwnProperty('formatter') ) {
                return this.set_mancante( filters[ obj.formatter ]( this._get_value_from_string( obj.key, this.row ) ) );
            }
            if ( obj.hasOwnProperty('htmlformatter') ) {
                if ( typeof obj.htmlformatter === 'string' ) {
                    return this.set_mancante( filters[ obj.htmlformatter ]( this._get_value_from_string( obj.key, this.row ) ) );
                } else if ( typeof obj.htmlformatter === 'function' ) {
                    return this.set_mancante( obj.htmlformatter( this.row ) );
                }
            }
            return this.set_mancante( this._get_value_from_string( obj.key, this.row ) );
        },
        set_mancante(res) {
            return res ? res : '<span style="color: lightgrey">dato mancante</span>';
        },
        get_class( field ) {
            if ( field.hasOwnProperty( 'class' )  ) {
                if ( Array.isArray(field.class) ) {
                    return [ field.class[0], field.class[2] ].join(' ');
                }
            } else {
                return field.class;
            }
            return '';
        },
        check_display() {
            //if ( obj.hasOwnProperty('display') === true && obj.display === false ) { return false; }
            return true;
        },
        check_component( obj ) {
            return obj.hasOwnProperty('component') ? true : false;
        }
    },
    data () {
        return {
        }
    },
};

</script>

<template>

    <div>
        <b-card>
            <b-row class="">
                <template v-for="obj in display">
                <b-col cols="12" md="6" lg="3" v-if="check_display(obj) && check_component(obj) === false" v-bind:key="'detail-card-' + get_name( obj ) + '-filter'" :class="get_class(obj)" style="border: 0px solid lightgrey; margin-bottom: 8px;">
                    <h6 class="text-center" style="padding-top: 3px; padding-bottom: 3px; margin-top: 8px; background-color: lightgrey;"><b>{{ get_name(obj) ? get_name(obj) : '&nbsp;' }}</b></h6>
                    <p v-html="filter(obj)"></p>
                </b-col>
                <b-col cols="12" md="6" lg="3" v-if="check_component(obj) === true" v-bind:key="'detail-card-' + get_name( obj ) + '-cmp'" :class="get_class(obj)" style="border: 0px solid lightgrey; margin-bottom: 8px;">
                    <h6 class="text-center" style="padding-top: 3px;; padding-bottom: 3px; margin-top: 8px; background-color: lightgrey;"><b>{{ get_name(obj) ? get_name(obj) : '' }}</b></h6>
                    <component
                        v-bind:key = "obj.key"
                        :name      = "obj.key"
                        :is        = "obj.component"
                        :item      = "row"
                    ></component>
                </b-col>
                </template>
            </b-row>
            <!-- <template v-for="obj in display">
                <b-row v-if="check_display(obj) && check_component(obj) === false" class="mb-2" v-bind:key="'detail-card-' + get_name( obj )" :class="get_class(obj)">
                    <b-col sm="3" class="text-sm-right"><b>{{ get_name(obj) ? get_name(obj) + ':' : '' }}</b></b-col>
                    <b-col v-html="filter(obj)"></b-col>
                </b-row>
                <b-row v-if="check_component(obj) === true" class="mb-2" v-bind:key="'detail-card-' + get_name( obj )" :class="get_class(obj)">
                    <b-col sm="3" class="text-sm-right"><b>{{ get_name(obj) ? get_name(obj) + ':' : '' }}</b></b-col>
                    <b-col>
                        <component
                            v-bind:key = "obj.key"
                            :name      = "obj.key"
                            :is        = "obj.component"
                            :item      = "row"
                        ></component>
                    </b-col>
                </b-row>
            </template> -->
        </b-card>
    </div>

</template>



