/////////////////////////////////////////////////

import Vue  from 'vue';
import Vuex from 'vuex';

import vmm_store from '@/vmm-store/main';
import config    from "@/config";
import models    from './models'

var options = {
    modelsobj    : require.context( '@/../../../SviluppoSync/botsupport-backend/models/schemas', true, /^.*\.schema.js$/ ),
    storesobj    : require.context( '@/stores', true, /^.*\.module\.js$/ ),
    stores_path  : __dirname + '/../store',
    include      : Object.keys( models ),
    settings     : {
        _user_create  : { model_name: 'user', default_order: 'label ASC' },
        _search_order : { model_name: 'order', default_order: 'label ASC' },
        _ddt_manager  : { model_name: 'ddt', default_order: 'created ASC' },
        automation    : { model_name: 'automation', default_order: 'automation_name ASC' },
    },
    config,
};

Vue.prototype.$models = models;

var vuex_config = vmm_store.get_store(
    options,
    // module1
    // module2
);

Vue.use(Vuex);
export default new Vuex.Store( vuex_config );

