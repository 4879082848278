<script>

import { mapState } from 'vuex';

export default {
    name: 'inv-app-ceo-details',
    props: [ 'item' ],
    components : {},
    computed : {
        ...mapState('invoice_approval_ceo', {
            updated      : state => state.updated,
            selected_ids : state => state.selected_ids,
        }),
        has_selected_ids() {
            return this.selected_ids.length > 0;
        },
        items() {
            let rows = this.item.invoice_approval_lines;
            return rows.sort( function(a, b) {
                return a.order - b.order;
            });
        },
    },
    watch: {},
    methods : {
        format_number( input ) {
            let float_input = parseFloat( input );
            if ( isNaN( float_input ) ) { return input; }
            if ( Number.isInteger( input ) ) { return parseInt( input ); }
            return parseFloat( input );
        },
        refuse() {
            let action = 'refuse';
            this.$emit( 'genericevent', { event_name: 'details', action, id: this.item.id } );
        },
        approve() {
            let action = 'confirm';
            this.$emit( 'genericevent', { event_name: 'details', action, id: this.item.id } );
        },
        note() {
            let action = 'note';
            this.$emit( 'genericevent', { event_name: 'details', action, id: this.item.id } );
        },
    },
    created() {},
    data() {
        return {
            save_in_progress : false,
            states           : {},
            updates          : [],
            euro             : new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }),
        }
    },
}

</script>

<template>
    <b-card no-body style="margin-bottom: 10px">
        <b-card-body>
            <table class="table-manager" style="width: 100%; border: 1px solid lightgrey">
                <tr>
                    <th>#</th><th>Descrizione</th><th class="text-right">Qta</th><th class="text-right">Udm</th><th class="text-right">Prezzo</th><th class="text-right">Prezzo Totale</th><th class="text-right">Iva</th>
                </tr>
                <tbody v-if="items.length > 0">
                    <tr v-for="row in items" :key="'row_detail_' + row.id" style="background-color: white">
                        <td>{{ row.order }}</td>
                        <td>{{ row.descrizione }}</td>
                        <td class="text-right">{{ format_number( row.qta ) }}</td>
                        <td class="text-right">{{ row.udm }}</td>
                        <td class="text-right">{{ euro.format( row.prezzo_unitario ) }}</td>
                        <td class="text-right">{{ euro.format( row.prezzo_totale ) }}</td>
                        <td class="text-right">{{ format_number( row.iva ) + '%' }}</td>
                    </tr>
                </tbody>
                <tbody v-else-if="items.length === 0">
                    <tr style="background-color: white"><td colspan="3" class="text-center" style="font-weight: 700">Non ci sono righe associate alla fattura</td></tr>
                </tbody>
                <tfoot v-if="items.length > 0 && !has_selected_ids" class="text-right">
                    <td colspan="4">
                        <div v-if="item.config.log && item.config.log.length > 0">
                            <table style="margin-top: 22px; width: 80%;">
                                <thead>
                                    <th style="padding: 2px; text-align: left;">Data</th>
                                    <th style="padding: 2px; text-align: left;">Utente</th>
                                    <th style="padding: 2px; text-align: left;">Azione</th>
                                    <th style="padding: 2px; text-align: left;">Commento</th>
                                </thead>
                                <tr v-for="log in item.config.log" :key="log.order">
                                    <td style="padding: 2px; text-align: left;">{{ log.datetime }}</td>
                                    <td style="padding: 2px; text-align: left;">{{ log.user }}</td>
                                    <td style="padding: 2px; text-align: left;">{{ log.action }}</td>
                                    <td style="padding: 2px; text-align: left;">{{ log.comment }}</td>
                                </tr>
                            </table>
                        </div>
                        <!-- <p v-if="item.config.comment" class="text-left" style="font-size: 0.9rem"><strong>Commento:</strong> <i>{{ item.config.comment }}</i></p> -->
                    </td>
                    <td colspan="3">
                        <b-row style="">
                            <b-col class="text-right">
                                <b-button variant="primary" size="sm" style="margin-right: 10px" @click="note">
                                    Nota
                                </b-button>
                                <b-button variant="danger" size="sm" style="margin-right: 10px" @click="refuse" :disabled="item.invoice_approval_state_id === 4">
                                    Rifiuta
                                </b-button>
                                <b-button variant="success" size="sm" @click="approve" :disabled="item.invoice_approval_state_id === 3">
                                    Approva
                                </b-button>
                            </b-col>
                        </b-row>
                    </td>
                </tfoot>
            </table>
        </b-card-body>
    </b-card>
</template>

<style>

    .table-manager td,th {
        font-size: 0.8rem;
        padding: 4px 0.75rem;
    }

    .button-manual {
        margin-right: 10px
    }

</style>

