
<script>

export default {
    name: 'Listpage',
    components: {},
    props: [ 'model_from_route' ],
    computed: {
        override_options() {
            return {
                primaryKey     : null,     // sovrascrive primaryKey proveniente da $listconfig (default: id)
                qryOptions     : null,     // le opzioni di filtro per la chiamata al backend
                paginate       : null,     // indica se paginare le risposte e le chiamate
                storeName      : null,     // forza l'utilizzo di uno store alternativo
                size           : null,     // classe 'sm' per la tabella
                detailsShow    : null,     // mostra il tasto per visualizzare i dettagli
                detailsClass   : null,     // classe della cella: usato per farla sparire quando tutti i campi sono visibili
                detailsAction  : null,     // serve a specificare quale azione apre i dettagli ('button' || 'click')
                edit           : null,     // icona per l'edit nella cella delle opzioni
                editAction     : null,     // azione che avvia l'edit della riga ('button' || 'double-click')
                rowClass       : null,     // classe delle righe della tabella
                link           : null,     // serve a far comparire un occhio nella casella delle operazioni per con il quale visualizzare il record nel dettaglio
                linkFunction   : null,     // funzione da eseguire quando viene cliccato l'occhio
                formPath       : null,     // il link per passare alla form è /form/{model}; serve a cambiare 'form'
            };
        },
        destroy()  { return null; },
        options()  { return this.$listconfig[ this.model ]; },
        store()    { return this.$store.state[ this.model ]; },
        loading()  { return this.store.loading; },
        items()    { return this.store.items; },
    },
    methods: {
        row_selected: function(/*row*/) {
        },
        right_click( /*obj*/ ) {
        },
    },
    created() {
        this.model = this.$route.params.model || this.model_from_route;
        if ( this.options.hasOwnProperty('order') ) { this.$store.dispatch(`${ this.model }/set_order`, this.options.order); }
    },
    data() {
        return {
            model: null,
        };
    }
}

</script>

<template>
    <div style="width: 80%; margin: auto">
        <h2 v-if="options.title_list" style="text-align: center; margin-bottom: 30px">{{ options.title_list.toUpperCase() }}</h2>
        <vmm-list
            ref              = "listbnv"
            :model           = "model"
            :options         = "override_options"

            :skip            = "false"
            :destroy         = "destroy"

            @row_selected    = "row_selected"
            @right-click     = "right_click"
        />
    </div>
</template>

