//////////////////////////////

module.exports = function( type ) {

    var schema = {
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        denominazione_emittente: {
            type: type.STRING(128),
            allowNull: true,
        },
        denominazione_cliente: {
            type: type.STRING(128),
            allowNull: true,
        },
        piva_cliente: {
            type: type.STRING(64),
            allowNull: true,
        },
        cf_cliente: {
            type: type.STRING(64),
            allowNull: true,
        },
        tipo_invio: {
            type: type.STRING(64),
            allowNull: true,
        },
        stato_file: {
            type: type.STRING(64),
            allowNull: true,
        },
        id_invio: {
            type: type.STRING(64),
            allowNull: true,
        },
        cliente_fornitore: {
            type: type.STRING(64),
            allowNull: true,
        },
        numero_fattura: {
            type: type.STRING(64),
            allowNull: true,
        },
        data_fattura: {
            type: type.DATEONLY,
            allowNull: true,
        },
        imponibile: {
            type: type.DECIMAL(10,2),
            allowNull: true,
            defaultValue: null,
        },
        imposta: {
            type: type.DECIMAL(10,2),
            allowNull: true,
            defaultValue: null,
        },
        importo: {
            type: type.DECIMAL(10,2),
            allowNull: true,
            defaultValue: null,
        },
        data_consegna: {
            type: type.DATEONLY,
            allowNull: true,
        },
        tipo_documento: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'invoice_consistency_doc_types',
                key         : 'id',
                constraints : true,
                store_name  : 'invoice_consistency_doc_type',
            }
        },
        piva_emittente: {
            type: type.STRING(64),
            allowNull: true,
        },
        cf_emittente: {
            type: type.STRING(64),
            allowNull: true,
        },
        id_paese_cedente: {
            type: type.STRING(64),
            allowNull: true,
        },
        id_paese_cessionario: {
            type: type.STRING(64),
            allowNull: true,
        },
        presente_in_TS: {
            type: type.BOOLEAN,
            defaultValue: false,
        },
        cont_corretta: {
            type: type.BOOLEAN,
            defaultValue: false,
        },
        nota_di_credito: {
            type: type.BOOLEAN,
            defaultValue: false,
        },
        idsdi_mancante: {
            type: type.BOOLEAN,
            defaultValue: false,
        },
        matricola_dispositivo: {
            type: type.STRING(128),
            allowNull: true,
        },
        time_rilevazione: {
            type: type.DATE,
            allowNull: true,
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue : type.NOW,
        },
        id_sqlite: {
            type: type.INTEGER,
            allowNull: true,
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

