<script>

export default {
    name: 'log-tags',
    props: [ 'item' ],
    components : {},
    computed : {
    },
    watch: {},
    methods : {
    },
    created() {},
    data() {
        return {
        }
    },
}

</script>

<template>
    <span>
        <b-badge v-for="tag in item.tags" variant="dark" :key="'tag_' + item.id + '_' + tag">{{ tag }}</b-badge>
    </span>
</template>

<style>
</style>

