///////////////////////////////////77

export default {
    namespaced: true,
    state: {
        items : [ { label: 'Sì', id: 'true' }, { label: 'No', id: 'false' } ],
    },
    actions: {
        get_all({commit}) {
            commit('start_request');
            commit('success_list');
        },
        set_params() {
        },
        clear() {}
    },
    mutations: {
        start_request( state ) {
            state.loading = true;
        },
        success_list( state ) {
            state.loading = false;
        },
    },
}

