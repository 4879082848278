/////////////////////////////////////////////////

import Vue from 'vue';
import list from './list';

class vmm_list {

    constructor() {}

    // static public methods ////////////////////

    static async load( listconfig ) {
        Vue.prototype.$listconfig = listconfig
        Vue.component('vmm-list', list);
    }

}

export default vmm_list;

